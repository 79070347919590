import { Button, Grid, Text } from "@mantine/core";
import Typography from "../../components/common/Typography";
import { TypographyTypes } from "../../utils/enums";
import Notifications from "../../features/Notifications";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getItemDetails } from "../../services/itemViewService/itemView";
import Modal from "../../components/common/Modal";
import ItemViewDrawer from "../../components/common/ItemViewDrawer";

const MyNotifications = () => {

  const [opened, setOpened] = useState(false);
  const [result, setResult] = useState(null);
  const { instance, accounts } = useMsal();
  const [loading, setLoading] = useState(false);
  const [onClickShowMore, setOnClickShowMore] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [closeType, setCloseType] = useState(0);
  const [selectedItemKey, setSelectedItemKey] = useState('');

  useEffect(() => {
    // Defining the event handler
    const handleBeforeUnload = (e:any) => {
      if (isDirty && !modalOpen) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    // Adding the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleaning up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  async function onClickItem(key: string, flag?: boolean) {
    if (!opened || !isDirty || flag) {
      setLoading(true);
      setOpened(true);
      await getItemDetails(key, instance, accounts)
        .then((data) => {
          setResult(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      setSelectedItemKey("key");
      setCloseType(1);
      setModalOpen(true);
    }
  }

  const setClose = () => {
    if (isDirty) {
      setCloseType(2);
      setModalOpen(true);
    } else {
      setOpened(false);
    }
  };


  return (
    <>
    <div style={{ padding: 15, marginTop: 15 }}>
      <Grid grow columns={12}>
        <Grid.Col span={4}>
          <Typography
            type={TypographyTypes.mainHeading}
            text={"My Notifications"}
          ></Typography>
        </Grid.Col>
      </Grid>
        <Notifications onClickItem={onClickItem} isItemDrawerOpened={opened} />
      </div>
      <ItemViewDrawer
        isFromNotification
        opened={opened}
        setClose={setClose}
        onClickItem={onClickItem}
        result={result}
        loading={loading}
        setIsDirty={setIsDirty}
        isDirty={isDirty}
        setOpened={setOpened}
      />
      {modalOpen &&
        <Modal
          opened={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          title="Discard changes?"
          size={428}
        >
          <Text>You have unsaved changes. Are you sure you want to discard them?</Text>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
            <Button variant="outline"
              color="gray"
              compact
              onClick={() => setModalOpen(false)}>
              Keep Editing
            </Button>
            <Button compact
              style={{ marginLeft: 5 }}
              color="red"
              onClick={() => {
                setModalOpen(false);
                if (closeType === 2) {
                  setModalOpen(false);
                  setOpened(false);
                  // setRowSelection({});
                } else if (closeType === 1) {
                  onClickItem(selectedItemKey, true);
                }
                setIsDirty(false);
                setResult(null);
                setCloseType(0);
              }}>
              Close and Discard
            </Button>
          </div>
        </Modal>
      }
    </>
  );
};

export default MyNotifications;
