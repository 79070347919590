import { createStyles } from "@mantine/core";

const raidItemTableStyle = createStyles((theme) => ({
  statusLabel: {
    padding: "1px 8px",
    fontWeight: 400,
    width: 99,
    border: "1px solid",
  },
  cellLabelText: {
    paddingLeft: 8,
  },
  ownerLabel: {
    padding: "1px 8px",
    fontWeight: 400,
    border: "1px solid",
  },
  linkTextLabel: {
    color: "#0068A3",
  },
  seeMoreDiv: {
    paddingTop: 18,
    fontSize: 14,
  },
  seeMoreText: {
    color: "#0068A3",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: 13,
  },
  seeMoreTextDisabled: {
    color: "#969696",
  },
  tableDiv: {
    td: {
      fontSize: "13px !important",
      padding: "0.25rem 1rem !important",
    },
    th: {
      padding: "0.75rem 1rem !important",
    },
    "& .mantine-Divider-root": {
      borderLeftColor: "#96969642",
      "&:hover": {
        borderLeftColor: "#969696",
      },
    },
    "& .mantine-Paper-root": {
      border: "none !important",
    },
    "& .mantine-TableHeadCell-Content-Wrapper": {
      color: "#969696",
    },
    "& .mantine-ibc8e0": {
      scrollbarColor: "#878787 transparent",
      scrollbarWidth: "thin",

      "::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "20px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#878787",
        borderRadius: "20px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#6e6e6e",
      },
    },
  },
  drawer: {
    flexShrink: 0
  },
  lockIcon: {
    marginLeft: -15,
    marginRight: 2,
    marginTop: 2,
    color: "gray"
  },
  lockIconDiv: {
    display: "inline-flex"
  }
}));

export default raidItemTableStyle;
