import { Tabs } from "@mantine/core"
import { TypographyTypes } from "../../../utils/enums"
import Tab from "../Tab"
import Typography from "../Typography"
import { useState } from "react"
import { TabGroupProps } from "./types"
import useTabGroupStyles from "./styles"
import RichTextEditor from "../RichTextEditor"

const TabGroup = ({ data, activeTab, setActiveTab }: TabGroupProps) => {
    const { classes } = useTabGroupStyles()

    return <>
        <Tab value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List>
                {data.map((value) =>
                    <Tabs.Tab value={value} >
                        {value}
                    </Tabs.Tab>
                )}
            </Tabs.List>
        </Tab>
    </>
}

export default TabGroup