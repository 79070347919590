import { createStyles } from "@mantine/core";

const useCommentStyles = createStyles((theme) => ({
  heading: {
    fontSize: 20,
    fontWeight: 600,
    color: "#000000",
    fontFamily: "Roboto, sans-serif",
    marginBottom: 10,
  },
  commentName: {
    fontSize: 15,
    fontWeight: 700,
    color: "#1C1C1C",
  },
  commentNameContainer: {
    whiteSpace: "nowrap",
    maxWidth: "50%",
    overflow: "hidden",
  },
  commentTime: {
    marginLeft: 5,
    fontSize: 14,
    fontWeight: 400,
    color: "#969696",
  },
  commentTimeDot: {
    fontSize: 16,
    fontWeight: 400,
    color: "#969696",
  },
  comment: {
    fontSize: 14,
    fontWeight: 400,
    color: "#3C3C3C",
  },
}));

export default useCommentStyles;
