import React, { useReducer, useEffect, useContext } from "react";

export interface IMultiSelectFilter {
  dashboard: string;
  selectedValues: string[];
}

export interface IPeriodFilter {
  type: string;
  dashboard: string;
  selectedValues: string;
}

interface IClearSingleFilter {
  type: string;
  dashboard: string;
  index: number;
}

export interface FilterState {
  ownerFilter: IMultiSelectFilter[];
  statusFilter: IMultiSelectFilter[];
  tagFilter: IMultiSelectFilter[];
  periodFilter: IPeriodFilter[];
  projectFilter: IMultiSelectFilter[];
  projectRefactoredFilter: IMultiSelectFilter[];
  assignedByFilter: IMultiSelectFilter[];
}

export interface ViewFilterObject {
  ownerFilter: {
    selectedValues: string[];
  };
  statusFilter: {
    selectedValues: string[];
  };
  tagFilter: {
    selectedValues: string[];
  };
  projectFilter: {
    selectedValues: string[];
  };
  assignedByFilter: {
    selectedValues: string[];
  };
  periodFilter: {
    type: string;
    selectedValues: string[];
  };
  readOnlyFilter?: ReadOnlyFilter;
}

export interface ReadOnlyFilter {
  ownerFilter?: string[];
  statusFilter?: string[];
  tagFilter?: string[];
  projectFilter?: string[];
  periodFilter?: string[];
  assignedByFilter?: Array<string>;
}

type Action =
  | { type: "ownerFilter"; payload: IMultiSelectFilter }
  | { type: "statusFilter"; payload: IMultiSelectFilter }
  | { type: "tagFilter"; payload: IMultiSelectFilter }
  | { type: "projectFilter"; payload: IMultiSelectFilter }
  | { type: "projectRefactoredFilter"; payload: IMultiSelectFilter }
  | { type: "periodFilter"; payload: IPeriodFilter }
  | { type: "setFilters"; payload: ViewFilterObject; dashboard: string }
  | { type: "saveToLocalStorage" }
  | { type: "loadFromLocalStorage" }
  | { type: "clearAtomicFilter"; payload: IClearSingleFilter }
  | { type: "clearFilter"; payload: { type: string; dashboard: string } }
  | { type: "clearAllFilters" }
  | { type: "clearFilterByDashbordName"; state: FilterState }
  | {
      type: "selectAllInProjectFilter";
      payload: { dashboard: string; selectedValues: string[] };
    }
  | { type: "assignedByFilter"; payload: IMultiSelectFilter };

type Dispatch = (action: Action) => void;

type FilterProviderProps = { children: React.ReactNode };

const FilterStateContext = React.createContext<
  { state: FilterState; dispatch: Dispatch } | undefined
>(undefined);

export const LOCAL_STORAGE_KEY = "filterState";

function updateFilters(
  state: FilterState,
  filterType: keyof FilterState,
  payload: IMultiSelectFilter | IPeriodFilter
): FilterState {
  const { dashboard, ...restPayload } = payload;

  const filterIndex = state[filterType]?.findIndex(
    (filter) => filter.dashboard === dashboard
  );

  if (filterIndex !== -1) {
    const updatedFilters = [...state[filterType]];
    updatedFilters[filterIndex] = {
      ...updatedFilters[filterIndex],
      ...restPayload,
    };
    return { ...state, [filterType]: updatedFilters };
  } else {
    const newFilter: IMultiSelectFilter | IPeriodFilter = {
      dashboard,
      ...restPayload,
    };
    const updatedFilters = [...state[filterType], newFilter];
    return { ...state, [filterType]: updatedFilters };
  }
}

function updateViewFilter(
  prevState: FilterState,
  dashboardName: string,
  filterObject: ViewFilterObject
): FilterState {
  const state = JSON.parse(JSON.stringify(prevState));
  const filterKeys = [
    "ownerFilter",
    "statusFilter",
    "tagFilter",
    "projectFilter",
    "periodFilter",
    "assignedByFilter",
  ];

  filterKeys.forEach((key) => {
    const filter = filterObject[key as keyof ViewFilterObject];
    if (filter) {
      state[key as keyof FilterState] = addOrEditIfDashboardElementExists(
        dashboardName,
        {
          ...filter,
          dashboard: dashboardName,
        },
        state[key as keyof FilterState]
      );
    }
  });
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  return state;
}

function addOrEditIfDashboardElementExists(
  dashboard: string,
  element: any,
  stateArray: any
) {
  const existingIndex = stateArray?.findIndex(
    (item: any) => item.dashboard === dashboard
  );
  if (existingIndex !== -1) {
    stateArray[existingIndex] = [];
  }

  stateArray.push(element);

  return stateArray;
}

function updateFilterArray(
  filters: IMultiSelectFilter[],
  payload: IClearSingleFilter
) {
  const updatedFilter = filters.map((filter) => {
    if (filter.dashboard === payload.dashboard) {
      const updatedSelectedValues = [
        ...filter.selectedValues.slice(0, payload.index),
        ...filter.selectedValues.slice(payload.index + 1),
      ];
      return { ...filter, selectedValues: updatedSelectedValues as [string] };
    }
    return filter;
  });
  if (updatedFilter.every((e) => e.selectedValues.length == 0)) {
    return [];
  } else {
    return updatedFilter;
  }
}

async function clearFilterByDashbordNameAsync(
  dispatch: Dispatch,
  prevState: FilterState,
  dashboard: string
) {
  const newState = await clearFilterByDashbordName(prevState, dashboard);
  dispatch({ type: "clearFilterByDashbordName", state: newState });
}

function clearFilterByDashbordName(
  prevState: FilterState,
  dashboard: string
): Promise<FilterState> {
  const state = JSON.parse(JSON.stringify(prevState));
  const filterKeys = [
    "ownerFilter",
    "statusFilter",
    "tagFilter",
    "projectFilter",
    "periodFilter",
    "assignedByFilter",
  ];

  filterKeys.forEach((key) => {
    state[key as keyof FilterState] = [];
  });
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
  return new Promise((resolve, reject) => {
    resolve(state);
  });
}

function filterReducer(state: FilterState, action: Action) {
  switch (action.type) {
    case "ownerFilter": {
      return updateFilters(state, "ownerFilter", action.payload);
    }
    case "assignedByFilter": {
      return updateFilters(state, "assignedByFilter", action.payload);
    }
    case "statusFilter": {
      return updateFilters(state, "statusFilter", action.payload);
    }
    case "tagFilter": {
      return updateFilters(state, "tagFilter", action.payload);
    }
    case "projectFilter": {
      return updateFilters(state, "projectFilter", action.payload);
    }
    case "projectRefactoredFilter": {
      return updateFilters(state, "projectRefactoredFilter", action.payload);
    }
    case "periodFilter": {
      return updateFilters(state, "periodFilter", action.payload);
    }
    case "setFilters": {
      // localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
      return updateViewFilter(state, action.dashboard, action.payload);
    }
    case "saveToLocalStorage": {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
      return state;
    }
    case "loadFromLocalStorage": {
      const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);

      if (storedState) {
        const parsedState = JSON.parse(storedState) as FilterState;
        return {
          ...state,
          ownerFilter: parsedState.ownerFilter,
          periodFilter: parsedState.periodFilter,
          projectFilter: parsedState.projectFilter,
          statusFilter: parsedState.statusFilter,
          tagFilter: parsedState.tagFilter,
          projectRefactoredFilter: parsedState.projectRefactoredFilter,
          assignedByFilter: parsedState.assignedByFilter || [],
        };
      } else {
        return state;
      }
    }
    case "clearAtomicFilter": {
      const { payload } = action;
      switch (payload.type) {
        case "owner": {
          const updatedFilters = updateFilterArray(state.ownerFilter, payload);
          return { ...state, ownerFilter: updatedFilters };
        }
        case "assignedBy": {
          const updatedFilters = updateFilterArray(
            state.assignedByFilter,
            payload
          );
          return { ...state, assignedByFilter: updatedFilters };
        }
        case "tag": {
          const updatedFilters = updateFilterArray(state.tagFilter, payload);
          return { ...state, tagFilter: updatedFilters };
        }
        case "status": {
          const updatedFilters = updateFilterArray(state.statusFilter, payload);
          return { ...state, statusFilter: updatedFilters };
        }
        case "project": {
          const updatedFilters = updateFilterArray(
            state.projectFilter,
            payload
          );
          return { ...state, projectFilter: updatedFilters };
        }
        case "projectRefactored": {
          const updatedFilters = updateFilterArray(
            state.projectRefactoredFilter,
            payload
          );
          return { ...state, projectFilter: updatedFilters };
        }
        case "period": {
          const updatedFilters = state.periodFilter.map((filter) => {
            if (filter.dashboard === payload.dashboard) {
              return { ...filter, type: "", selectedValues: "" };
            }
            return filter;
          });
          if (updatedFilters.every((e) => e.selectedValues.length == 0)) {
            return { ...state, periodFilter: [] };
          } else {
            return { ...state, periodFilter: updatedFilters };
          }
        }
        default: {
          throw new Error(`Unhandled type`);
        }
      }
    }
    case "clearFilter": {
      const { payload } = action;
      switch (payload.type) {
        case "owner": {
          const updatedFilters = state.ownerFilter.map((filter) => {
            if (filter.dashboard === payload.dashboard) {
              return { ...filter, selectedValues: [] };
            }
            return filter;
          });
          return { ...state, ownerFilter: updatedFilters };
        }
        case "assignedBy": {
          const updatedFilters = state.assignedByFilter.map((filter) => {
            if (filter.dashboard === payload.dashboard) {
              return { ...filter, selectedValues: [] };
            }
            return filter;
          });
          return { ...state, assignedByFilter: updatedFilters };
        }
        case "project": {
          const updatedFilters = state.projectFilter.map((filter) => {
            if (filter.dashboard === payload.dashboard) {
              return { ...filter, selectedValues: [] };
            }
            return filter;
          });
          return { ...state, projectFilter: updatedFilters };
        }
        case "projectRefactored": {
          const updatedFilters = state.projectRefactoredFilter.map((filter) => {
            if (filter.dashboard === payload.dashboard) {
              return { ...filter, selectedValues: [] };
            }
            return filter;
          });
          return { ...state, projectRefactoredFilter: updatedFilters };
        }
        case "tag": {
          const updatedFilters = state.tagFilter.map((filter) => {
            if (filter.dashboard === payload.dashboard) {
              return { ...filter, selectedValues: [] };
            }
            return filter;
          });
          return { ...state, tagFilter: updatedFilters };
        }
        case "status": {
          const updatedFilters = state.statusFilter.map((filter) => {
            if (filter.dashboard === payload.dashboard) {
              return { ...filter, selectedValues: [] };
            }
            return filter;
          });
          return { ...state, statusFilter: updatedFilters };
        }
        default: {
          throw new Error(`Unhandled type`);
        }
      }
    }
    case "clearAllFilters": {
      return {
        ...state,
        ownerFilter: [],
        periodFilter: [],
        projectFilter: [],
        statusFilter: [],
        tagFilter: [],
        projectRefactoredFilter: [],
        assignedByFilter: [],
      };
    }
    case "clearFilterByDashbordName": {
      return action.state;
    }
    case "selectAllInProjectFilter": {
      const { payload } = action;
      const updatedFilters = state.projectFilter.map((filter) => {
        if (filter.dashboard === payload.dashboard) {
          return { ...filter, selectedValues: payload.selectedValues };
        }
        return filter;
      });
      return { ...state, projectFilter: updatedFilters };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

const initialState: FilterState = {
  ownerFilter: [],
  statusFilter: [],
  tagFilter: [],
  periodFilter: [],
  projectFilter: [],
  projectRefactoredFilter: [],
  assignedByFilter: [],
};

function FilterProvider({ children }: FilterProviderProps) {
  const [state, dispatch] = useReducer(filterReducer, initialState);
  const value = { state, dispatch };
  return (
    <FilterStateContext.Provider value={value}>
      {children}
    </FilterStateContext.Provider>
  );
}

const useFilterContext = () => {
  const context = useContext(FilterStateContext);
  if (!context) {
    throw new Error("useFilterContext must be used within a DataProvider");
  }
  return context;
};

export { useFilterContext, FilterProvider, clearFilterByDashbordNameAsync };
