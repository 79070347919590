import { Flex, Grid, Menu, TextInput, ActionIcon } from "@mantine/core";
import useStyles from "./style";
import Typography from "../../components/common/Typography";
import { TypographyTypes } from "../../utils/enums";

import { Dispatch, ReactElement, SetStateAction, useState } from "react";

interface HeaderFeatureProps {
  dashboardName: string;
  displayName: string;
  childComponent: ReactElement<any, any>
}

function HeaderFeature({
  displayName,
  childComponent
}: HeaderFeatureProps) {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.headerWrapper}>
        <Grid grow columns={12}>
          <Grid.Col span={7}>
            <Typography
              type={TypographyTypes.mainHeading}
              text={displayName}
            ></Typography>
          </Grid.Col>
          {childComponent}
        </Grid>
      </div>
    </>
  );
}

export default HeaderFeature;
