import React, { useContext } from "react";
import { useReducer } from "react";

export interface IRaidListRefresh {
  dashboard: string;
  refresh: boolean;
}

interface RefreshRaidListState {
  raidItemRefreshList: IRaidListRefresh[];
  isTagsRefreshing: boolean;
}

// type RefreshRaidListAction = {
//   type: "setRaidListRefresh";
//   payload: IRaidListRefresh;
// };

type RefreshRaidListAction =
  | {
      type: "setRaidListRefresh";
      payload: IRaidListRefresh;
    }
  | { type: "FETCH_TAGS"; payload: { isTagsRefreshing: boolean } };

type RefreshRaidListDispatch = (action: RefreshRaidListAction) => void;

const RefreshRaidListStateContext = React.createContext<
  { state: RefreshRaidListState; dispatch: RefreshRaidListDispatch } | undefined
>(undefined);

function refreshRaidListReducer(
  state: RefreshRaidListState,
  action: RefreshRaidListAction
) {
  switch (action.type) {
    case "setRaidListRefresh": {
      var preState = { ...state };
      const index = preState.raidItemRefreshList.findIndex(
        (dashboardItem) => dashboardItem.dashboard === action.payload.dashboard
      );
      if (index !== -1) {
        preState.raidItemRefreshList[index].refresh = action.payload.refresh;
      } else {
        preState.raidItemRefreshList.push(action.payload);
      }
      return preState;
    }
    case "FETCH_TAGS":
      return { ...state, isTagsRefreshing: action.payload.isTagsRefreshing };
  }
}

const initialState: RefreshRaidListState = {
  raidItemRefreshList: [],
  isTagsRefreshing: false,
};

function RefreshRaidListProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(refreshRaidListReducer, initialState);
  const value = { state, dispatch };
  return (
    <RefreshRaidListStateContext.Provider value={value}>
      {children}
    </RefreshRaidListStateContext.Provider>
  );
}

const useRefreshRaidListContext = () => {
  const context = useContext(RefreshRaidListStateContext);
  if (!context) {
    throw new Error(
      "useRefreshRaidListContext must be used within a DataProvider"
    );
  }
  return context;
};

export { useRefreshRaidListContext, RefreshRaidListProvider };
