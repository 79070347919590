import { IAttachemntProps, IUploadedFile } from "./type";
import { useEffect, useState } from "react";
import { ATTACHMENTS } from "./tempData";
import useCommentFeatureStyles from "./styles";
import { Attachment } from "../../components/Attachments/Attachment";
import { EditItemAttachmentsProp } from "../../components/AddItem/ItemDetails/type";
import { useAttachmentContext } from "../../contexts/attachments.context";

const AttachmentSection = ({ form, itemId, removedFileList, setRemovedFileList, isUpdatePending, isDisabled }: EditItemAttachmentsProp) => {
  const { classes } = useCommentFeatureStyles();
  const [fileList, setFileList] = useState<IAttachemntProps[]>([]);

  const { fetchAttachments, status, attachments } = useAttachmentContext();

  useEffect(() => {
    if (itemId) fetchAttachments(itemId?.toString());
  }, [itemId]);

  useEffect(() => {
    if (itemId) fetchAttachments(itemId?.toString());
  }, [isUpdatePending]);

  useEffect(() => {
    setFileList(attachments);
  }, [attachments]);

  const handleAttachmentRemove = (index: number) => {
    if (removedFileList && setRemovedFileList) {
      const deletedFiles = [...removedFileList];

      const fileUrl = fileList[index].url;
      const fileName = fileList[index].title;

      if (fileUrl !== undefined && fileName !== undefined) {
        const file = fileUrl.split("/")[fileUrl.split("/").length - 1];

        const deletedFile: { fileUrl: string, fileName: string } = {
          fileUrl: file,
          fileName: file,
        };
        deletedFiles.push(deletedFile);
        setRemovedFileList(deletedFiles);
      }
    }
  };

  const checkIsAttachmentRemoved = (url: string | undefined) => {
    if (url != undefined) {
      const fileURL = url.split("/")[url.split("/").length - 1];
      if (removedFileList && removedFileList.length > 0) {
        const filteredList = removedFileList.filter(
          (files) => files.fileUrl === fileURL
        );
        return filteredList.length > 0;
      }
    }
  };

  return (
    <div style={!isDisabled ? { marginTop: "5rem" } : {}}>
      {fileList.length > 0
        ? fileList.map((attachment, i) =>
          !attachment.isDeleted ? (
            checkIsAttachmentRemoved(attachment.url) ? null : (
              <Attachment
                key={i}
                index={i}
                title={attachment.title}
                type={attachment.type}
                size={attachment.size}
                url={attachment.url}
                uploadedOn={attachment.uploadedOn}
                handleRemove={handleAttachmentRemove}
                isDisabled={isDisabled}
              />
            )
          ) : null
        )
        : null}
    </div>
  );
};

export default AttachmentSection;
