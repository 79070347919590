import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: 10,
    marginBottom: 20
  },
  showLabel: {
    fontFamily: theme.fontFamily,
    lineHeight: "24px",
    fontWeight: "bold",
    marginRight: 10
  },
  markAllButton: {
    fontFamily: theme.fontFamily,
    lineHeight: "24px",
    fontWeight: "bold",
    marginLeft: 30,
    marginRight: 3,
    "&:hover": {
      cursor: "pointer",
    }
  },
  seeMoreDiv: {
    paddingTop: 20,
    fontSize: 14,
  },
  seeMoreText: {
    color: "#0068A3",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: 13,
  },
  seeMoreTextDisabled: {
    color: "#969696",
  },
  noNotifications:{
    color: "#969696",
    height: 200
  }
}));

export default useStyles;
