import { Mention, MentionItem, MentionsInput } from "react-mentions";
import { mentionInputStyle, mentionStyle } from "./styles";
import { ReactNode } from "react";
import { Avatar, Box } from "@mantine/core";
import { AppMentionInputProps } from "./types";

const AppMentionInput = ({
  value,
  onChange,
  inputRef,
  dataFunc,
}: AppMentionInputProps) => {
  const renderSuggestion = (
    suggestion: any,
    search: any,
    highlightedDisplay: any,
    index: any,
    focused: any
  ) => {
    const className = `mention-suggestion ${focused ? "focused" : ""}`;

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
        }}
        className={className}
      >
        <Avatar
          radius="lg"
          src={suggestion.image}
          data-testid="test-avatar-comp"
        >
          {suggestion.display.length > 0 ? suggestion.display.slice(0, 1) : ""}
        </Avatar>
        <span>{highlightedDisplay}</span>
      </Box>
    );
  };

  return (
    <MentionsInput
      style={mentionInputStyle}
      value={value}
      onSelect={(e) => {}}
      onChange={onChange}
      inputRef={inputRef}
    >
      <Mention
        data={dataFunc}
        trigger={"@"}
        style={mentionStyle}
        renderSuggestion={renderSuggestion}
      />
    </MentionsInput>
  );
};

export default AppMentionInput;