import { Dispatch, SetStateAction } from "react";

export interface PeriodFilterProps {
  title: string;
  StateHasValue: boolean;
  periodList: PeriodListProps[] | [];
  period: string | null;
  setPeriod: Dispatch<SetStateAction<string | null>>;
  handlePeriodSelection: (period: string, selectedValues: string) => void;
  disabled?: boolean
}

export interface PeriodListProps {
  value: string;
  key: string;
  isSelected: boolean;
}

export enum PeriodEnum {
  Today,
  This_Week,
  This_Month,
  All_Time,
  Custom,
}
