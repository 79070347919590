/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Flex,
  Grid,
  Group,
  Loader,
  SimpleGrid,
  Text,
  Tooltip,
} from "@mantine/core";
import Button from "../../components/common/Button";
import ProjectFilter from "../../components/Filters/ProjectFilter";
import PeriodFilter, {
  PeriodEnum,
  PeriodFilterProps,
  PeriodListProps,
} from "../../components/Filters/PeriodFilter";
import { useEffect, useState } from "react";
import OwnerFilter from "../../components/Filters/OwnerFilter";
import { OwnerFilterProps } from "../../components/Filters/OwnerFilter/types";
import {
  IOwnerFilterAPIParams,
  getOwnerFilterList,
} from "../../services/filterService/ownerFilter";
import {
  ILabelFilterAPIParams,
  getLabelFilterList,
} from "../../services/filterService/tagFilter";
import StatusFilter from "../../components/Filters/StatusFilter";
import {
  IStatusFilterAPIParams,
  getStatusFilterList,
} from "../../services/filterService/statusFilter";
import { StatusFilterProp } from "../../components/Filters/StatusFilter/type";
import { useTranslation } from "react-i18next";
import useFilterPanelStyles from "./styles";
import FilterTag from "../../components/common/FilterTag";
import { useMsal } from "@azure/msal-react";
import {
  IconFolder,
  IconUser,
  IconTag,
  IconStar,
  IconClock,
  IconChevronsDown,
  IconChevronsUp,
  IconTrash,
} from "@tabler/icons-react";
import TagFilter from "../../components/Filters/TagsFilter";
import { TagFilterProps } from "../../components/Filters/TagsFilter/types";
import {
  FilterState,
  IMultiSelectFilter,
  IPeriodFilter,
  LOCAL_STORAGE_KEY,
  ReadOnlyFilter,
  useFilterContext,
} from "../../contexts/filter.context";
import { useProjectContext } from "../../contexts/project.context";
import { getAccessToken } from "../../services/authService";
import { DataProps } from "../../components/common/CheckboxHierarchy";
import { updateCheckedStatusWithParent } from "../../components/common/CheckboxHierarchy/functions";
import SaveFilterPopup from "../SaveFilterPopup";
import Modal from "../../components/common/Modal";
import { deletePrivateView } from "../../services/PrivateViewService/PrivateView";
import {
  updateEditMode,
  updateViewsAsync,
  useViews,
} from "../../contexts/view.context";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router";
import IconButton from "../../components/common/IconButton";
import ReadOnlyFilterPanel from "../../components/common/ReadOnlyFilterPanel";
import getViewFromContext from "../../utils/functions/getViewFromContext";
import AssignByFilter from "../../components/Filters/assignByFilter";
import { AssignByFilterProps } from "../../components/Filters/assignByFilter/types";
import { getAssignByUsersList } from "../../services/filterService/assignByFilter";
import { useRefreshRaidListContext } from "../../contexts/listViewRefresh.context";
interface FilterPanelProps {
  dashboardName: string;
  editMode?: boolean;
  setToInitFilter?: () => void;
  readOnlyFilter?: ReadOnlyFilter;
}

const FilterPanel = ({
  dashboardName,
  editMode = false,
  setToInitFilter,
  readOnlyFilter,
}: FilterPanelProps) => {
  const { t: translate } = useTranslation<"translation">();
  const [disableAll, setDisableAll] = useState(editMode);
  const { classes, cx } = useFilterPanelStyles();
  const { dispatch, state } = useFilterContext();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();

  const { dispatch: refreshTagsDispatch, state: refreshState } =
    useRefreshRaidListContext();
  const {
    status: projectStatus,
    Projects,
    fetchProjectList,
  } = useProjectContext();

  //lists
  const [ownerFullList, setOwnerFullList] = useState<
    OwnerFilterProps["ownerList"]
  >([]);
  const [assignedByUserList, setAssignedByUserList] = useState<
    AssignByFilterProps["assignByUsers"]
  >([]);
  const [assignedByUserFullList, setAssignedByUserFullList] = useState<
    AssignByFilterProps["assignByUsers"]
  >([]);
  const [tagsFullList, setTagsFullList] = useState<TagFilterProps["tagList"]>(
    []
  );
  const [ownerList, setOwnerList] = useState<OwnerFilterProps["ownerList"]>([]);
  const [statusList, setStatusList] = useState<StatusFilterProp["statusList"]>(
    []
  );

  const [periodList, setPeriodList] = useState<PeriodFilterProps["periodList"]>(
    []
  );
  const [tagList, setTagList] = useState<TagFilterProps["tagList"]>([]);

  useEffect(() => {
    dispatch({ type: "loadFromLocalStorage" });
    editMode && setDisableAll(true);
  }, [location.pathname]);

  //selected values
  const [ownerChecked, setOwnerChecked] = useState<string[]>([]);
  const [assignByChecked, setAssignByChecked] = useState<string[]>([]);
  const [statusChecked, setStatusChecked] = useState<[string] | []>([]);
  const [tagsChecked, setTagsChecked] = useState<[string] | []>([]);
  const [period, setPeriod] = useState<string | null>(null);
  const [isStatusesLoading, setStatusesLoading] = useState<boolean>(false);
  const [isOwnersLoading, setIsOwnersLoading] = useState<boolean>(false);
  const [isAssignByUsersLoading, setAssignByUsersLoading] =
    useState<boolean>(false);
  const [isTagsLoading, setIsTagsLoading] = useState<boolean>(false);
  const [allOwnerKeys, setAllOwnerKeys] = useState<string[]>([]);
  const [allAssignUserKeys, setAllAssignUsersKeys] = useState<string[]>([]);
  const [selectAllOwners, setSelectAllOwners] = useState<boolean>(false);
  const [selectAllAssignByUsers, setSelectAllAssignByUsers] =
    useState<boolean>(false);
  const [allStatusKeys, setAllStatusKeys] = useState<string[]>([]);
  const [selectAllStatus, setSelectAllStatus] = useState<boolean>(false);
  const [allTagsKeys, setAllTagsKeys] = useState<string[]>([]);
  const [selectAllTags, setSelectAllTags] = useState<boolean>(false);
  //project filter new
  const [data, setData] = useState<DataProps[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectAllProjects, setSelectAllProjects] = useState(false);
  const [allContainerKeys, setAllContainerKeys] = useState<string[]>([]);
  const [isFilterTagsMinimized, setFilterTagsMinimized] =
    useState<boolean>(false);
  const [opened, setOpened] = useState(false);
  const [saveFilter, setSaveFilter] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const { dispatch: dispatchViews, state: viewState } = useViews();
  const [filterName, setFilterName] = useState<string>("");
  const [labelsArray, setLabelsArray] = useState<ReadOnlyFilter>({
    projectFilter: [],
    periodFilter: [],
    ownerFilter: [],
    statusFilter: [],
    tagFilter: [],
    assignedByFilter: [],
  });

  useEffect(() => {
    if (
      viewState.publicViewState.length > 0 ||
      viewState.publicViewState.length > 0
    ) {
      const _view = getViewFromContext(viewState, location.pathname);
      if (_view) {
        setFilterName(_view.name);
      } else {
        setFilterName("");
      }
    }
  }, [modalOpen, location.pathname]);

  useEffect(() => {
    const projectFilter = state?.projectFilter?.find(
      (e) => e.dashboard === dashboardName
    );

    const periodFilter = state?.periodFilter?.find(
      (e) => e.dashboard === dashboardName
    );

    if (projectFilter && projectFilter?.selectedValues) {
      setSelected(projectFilter.selectedValues as [string]);
    }

    if (periodFilter && periodFilter?.type) {
      setPeriod(periodFilter.type);
    } else {
      setPeriod(null);
    }
  }, [state.projectFilter, state.periodFilter]);

  useEffect(() => {
    if (tagList.length <= 0) return;
    let allTagKeys: string[] = tagsFullList.map((e) => e.key);
    setAllTagsKeys(tagList.map((e) => e.key));
    const tagFilter = state?.tagFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    if (tagFilter && tagFilter?.selectedValues.length > 0) {
      const updatedSelectedTags = tagFilter.selectedValues.filter((e) =>
        allTagKeys.some((key) => key == e)
      );
      setTagsChecked(updatedSelectedTags as [string]);

      const tagFilterObj = {
        dashboard: dashboardName,
        selectedValues: updatedSelectedTags as [string],
      };
      dispatch({ type: "tagFilter", payload: tagFilterObj });
      dispatch({ type: "saveToLocalStorage" });
    } else {
      setTagsChecked([]);
    }
  }, [tagList]);

  useEffect(() => {
    if (ownerList.length <= 0) return;
    let allOwnerKeys: string[] = ownerFullList.map((e) => e.key);
    setAllOwnerKeys(ownerList.map((e) => e.key));
    const ownerFilter = state?.ownerFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    if (ownerFilter && ownerFilter?.selectedValues.length > 0) {
      const updatedSelectedOwners = ownerFilter.selectedValues.filter((e) =>
        allOwnerKeys.some((key) => key == e)
      );
      setOwnerChecked(updatedSelectedOwners as [string]);

      const ownerFilterObj = {
        dashboard: dashboardName,
        selectedValues: updatedSelectedOwners as [string],
      };

      dispatch({ type: "ownerFilter", payload: ownerFilterObj });
      dispatch({ type: "saveToLocalStorage" });
    } else {
      setOwnerChecked([]);
    }
  }, [ownerList]);

  useEffect(() => {
    if (assignedByUserList.length <= 0) return;

    let allAssignByKeys: string[] = assignedByUserFullList.map((e) => e.key);

    setAllAssignUsersKeys(assignedByUserList.map((e) => e.key));
    const assignedByFilter = state?.assignedByFilter?.find(
      (e) => e.dashboard === dashboardName
    );

    if (assignedByFilter && assignedByFilter?.selectedValues.length > 0) {
      const updatedSelectedAssignBy = assignedByFilter.selectedValues.filter(
        (e) => allAssignByKeys.some((key) => key == e)
      );
      setAssignByChecked(updatedSelectedAssignBy as [string]);

      const assignedByFilterObj = {
        dashboard: dashboardName,
        selectedValues: updatedSelectedAssignBy as [string],
      };

      dispatch({ type: "assignedByFilter", payload: assignedByFilterObj });
      dispatch({ type: "saveToLocalStorage" });
    } else {
      setAssignByChecked([]);
    }
  }, [assignedByUserList]);

  useEffect(() => {
    if (statusList.length <= 0) return;
    let allStatusKeys: string[] = statusList.map((e) => e.key);
    setAllStatusKeys(allStatusKeys);
    const statusFilter = state?.statusFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    if (statusFilter && statusFilter?.selectedValues.length > 0) {
      const updatedSelectedStatuses = statusFilter.selectedValues.filter((e) =>
        allStatusKeys.some((key) => key == e)
      );
      setStatusChecked(updatedSelectedStatuses as [string]);

      const statusFilterObj = {
        dashboard: dashboardName,
        selectedValues: updatedSelectedStatuses as [string],
      };

      dispatch({ type: "statusFilter", payload: statusFilterObj });
      dispatch({ type: "saveToLocalStorage" });
    }
  }, [statusList]);

  const handleOwnerSelection = () => {
    const ownerFilter = {
      dashboard: dashboardName,
      selectedValues: ownerChecked as [string],
    };
    dispatch({ type: "ownerFilter", payload: ownerFilter });
    dispatch({ type: "saveToLocalStorage" });
  };

  const handleOwnerClear = () => {
    const ownerFilter = {
      type: "owner",
      dashboard: dashboardName,
    };
    dispatch({ type: "clearFilter", payload: ownerFilter });
    setOwnerChecked([]);
    dispatch({ type: "saveToLocalStorage" });
  };
  const handleAssignedBySelection = () => {
    try {
      const assignFilter = {
        dashboard: dashboardName,
        selectedValues: assignByChecked as [string],
      };
      dispatch({ type: "assignedByFilter", payload: assignFilter });
      dispatch({ type: "saveToLocalStorage" });
    } catch (error) {}
  };

  const handleAssignedByClear = () => {
    try {
      const assignedByFilter = {
        type: "assignedBy",
        dashboard: dashboardName,
      };
      dispatch({ type: "clearFilter", payload: assignedByFilter });
      setAssignByChecked([]);
      dispatch({ type: "saveToLocalStorage" });
    } catch (error) {}
  };

  const handleProjectSelection = () => {
    const projectFilter = {
      dashboard: dashboardName,
      selectedValues: selected as [string],
    };
    dispatch({ type: "projectFilter", payload: projectFilter });
    dispatch({ type: "saveToLocalStorage" });
  };

  const handleProjectClear = () => {
    const projectFilter = {
      type: "project",
      dashboard: dashboardName,
    };
    dispatch({ type: "clearFilter", payload: projectFilter });
    dispatch({ type: "saveToLocalStorage" });
  };

  useEffect(() => {
    let allKeys: string[] = [];
    function extractKeys(item: any) {
      allKeys.push(item.key);
      if (item.children) {
        item.children.forEach(extractKeys);
      }
    }
    data.forEach(extractKeys);
    setAllContainerKeys(allKeys);
  }, [data]);

  const handleProjectSelectAll = () => {
    let selectedValues: string[] = [];
    function extractKeys(item: any) {
      selectedValues.push(item.key);
      if (item.children) {
        item.children.forEach(extractKeys);
      }
    }

    data.forEach(extractKeys);
    const projectFilter = {
      dashboard: dashboardName,
      selectedValues: selectedValues,
    };
    dispatch({ type: "selectAllInProjectFilter", payload: projectFilter });
    dispatch({ type: "saveToLocalStorage" });
  };

  const handleOwnerSelectAll = () => {
    let allKeys: string[] = ownerList.map((e) => e.key);
    const ownerFilterObj = {
      dashboard: dashboardName,
      selectedValues: allKeys as [string],
    };
    if (ownerFullList.length == ownerList.length) {
      dispatch({ type: "ownerFilter", payload: ownerFilterObj });
      dispatch({ type: "saveToLocalStorage" });
    }
  };
  const handleAssignBySelectAll = () => {
    let allKeys: string[] = assignedByUserList.map((e) => e.key);
    const assignedByUserObj = {
      dashboard: dashboardName,
      selectedValues: allKeys as [string],
    };
    if (assignedByUserFullList.length === assignedByUserList.length) {
      dispatch({ type: "assignedByFilter", payload: assignedByUserObj });
      dispatch({ type: "saveToLocalStorage" });
    }
  };

  const handleStatusSelectAll = () => {
    let allKeys: string[] = statusList.map((e) => e.key);
    const statusFilterObj = {
      dashboard: dashboardName,
      selectedValues: allKeys as [string],
    };
    dispatch({ type: "statusFilter", payload: statusFilterObj });
    dispatch({ type: "saveToLocalStorage" });
  };

  const handleTagsSelectAll = () => {
    let allKeys: string[] = tagList.map((e) => e.key);
    const tagsFilterObj = {
      dashboard: dashboardName,
      selectedValues: allKeys as [string],
    };
    if (tagsFullList.length == tagList.length) {
      dispatch({ type: "tagFilter", payload: tagsFilterObj });
      dispatch({ type: "saveToLocalStorage" });
    }
  };

  useEffect(() => {
    const allKeysStatus: string[] = allStatusKeys.map(String);
    const checked = statusChecked.map(String);
    const isAllChecked = allKeysStatus.every((key) => checked.includes(key));
    setSelectAllStatus(isAllChecked);
  }, [statusChecked]);

  useEffect(() => {
    const allKeysOwners: string[] = allOwnerKeys.map(String);
    if (ownerChecked.length > 0) {
      const checked = ownerChecked.map(String);
      const isAllChecked = allKeysOwners.every((key) => checked.includes(key));
      setSelectAllOwners(isAllChecked);
    } else {
      setSelectAllOwners(false);
    }
  }, [allOwnerKeys, ownerChecked]);

  useEffect(() => {
    const allKeysAssignedByUsers: string[] = allAssignUserKeys.map(String);
    if (assignByChecked.length > 0) {
      const checked = assignByChecked.map(String);
      const isAllChecked = allKeysAssignedByUsers.every((key) =>
        checked.includes(key)
      );
      setSelectAllAssignByUsers(isAllChecked);
    } else {
      setSelectAllAssignByUsers(false);
    }
  }, [allAssignUserKeys, assignByChecked]);

  useEffect(() => {
    const allKeysTags: string[] = allTagsKeys.map(String);
    if (tagsChecked.length > 0) {
      const checked = tagsChecked.map(String);
      const isAllChecked = allKeysTags.every((key) => checked.includes(key));
      setSelectAllTags(isAllChecked);
    } else {
      setSelectAllTags(false);
    }
  }, [allTagsKeys, tagsChecked]);

  useEffect(() => {
    let allKeys: string[] = [];
    function extractKeys(item: any) {
      allKeys.push(item.key);
      if (item.children) {
        item.children.forEach(extractKeys);
      }
    }
    data.forEach(extractKeys);
    const isAllChecked = allKeys.every((key) => selected.includes(key));
    setSelectAllProjects(isAllChecked);
  }, [data, selected]);

  const handleTagSelection = () => {
    const tagFilter = {
      dashboard: dashboardName,
      selectedValues: tagsChecked as [string],
    };
    dispatch({ type: "tagFilter", payload: tagFilter });
    dispatch({ type: "saveToLocalStorage" });
  };

  const handleTagClear = () => {
    const ownerFilter = {
      type: "tag",
      dashboard: dashboardName,
    };
    dispatch({ type: "clearFilter", payload: ownerFilter });
    setTagsChecked([]);
    dispatch({ type: "saveToLocalStorage" });
  };

  const handleStatusSelection = () => {
    const statusFilter = {
      dashboard: dashboardName,
      selectedValues: statusChecked as [string],
    };
    dispatch({ type: "statusFilter", payload: statusFilter });
    dispatch({ type: "saveToLocalStorage" });
  };

  const handleStatusClear = () => {
    const ownerFilter = {
      type: "status",
      dashboard: dashboardName,
    };
    dispatch({ type: "clearFilter", payload: ownerFilter });
    setStatusChecked([]);
    dispatch({ type: "saveToLocalStorage" });
  };

  const handlePeriodSelection = (period: string, selectedValues: string) => {
    const periodFilter = {
      type: period ? period : "",
      dashboard: dashboardName,
      selectedValues: selectedValues,
    };
    dispatch({ type: "periodFilter", payload: periodFilter });
    dispatch({ type: "saveToLocalStorage" });
  };

  async function getOwnerFilters(req: IOwnerFilterAPIParams, type?: "initial") {
    setIsOwnersLoading(true);
    const accessToken = await getAccessToken(instance, accounts[0]);
    if (req?.containerIds?.length > 0)
      await getOwnerFilterList(req, accessToken)
        .then(({ data }) => {
          setOwnerList(data);
          if (type && type === "initial") {
            setOwnerFullList(data);
          }
          setIsOwnersLoading(false);
        })
        .catch((error) => {
          setIsOwnersLoading(false);
        });
  }

  const getAssignByUsers = async (
    req: IOwnerFilterAPIParams,
    type?: "initial"
  ) => {
    setAssignByUsersLoading(true);
    const accessToken = await getAccessToken(instance, accounts[0]);
    if (req?.containerIds?.length > 0)
      await getAssignByUsersList(req, accessToken)
        .then(({ data }) => {
          setAssignedByUserList(data);
          if (type && type === "initial") {
            setAssignedByUserFullList(data);
          }
          setAssignByUsersLoading(false);
        })
        .catch((error) => {
          setAssignByUsersLoading(false);
        });
  };

  async function getStatusFilters(req: IStatusFilterAPIParams) {
    setStatusesLoading(true);
    const accessToken = await getAccessToken(instance, accounts[0]);

    if (req?.containerIds?.length > 0)
      await getStatusFilterList(req, accessToken)
        .then(({ data }) => {
          setStatusList(data);
          setStatusesLoading(false);
        })
        .catch((error) => {
          setStatusesLoading(false);
        });
  }

  async function getProjectFilters() {
    fetchProjectList();
  }

  const getChildrenKeys = (project: DataProps): Array<string> => {
    const { children, key } = project;
    if (!children) {
      return [key];
    }
    return [key, ...children.flatMap((item) => getChildrenKeys(item))];
  };
  const getAllKeys = (projects: Array<DataProps>): Array<string> => {
    const keys = projects.flatMap((project) => getChildrenKeys(project));
    return keys || [];
  };
  useEffect(() => {
    const stateFromLS = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (stateFromLS) {
      const parsedState = JSON.parse(stateFromLS) as FilterState;
      const selectedProjList = parsedState.projectFilter.filter(
        (e) => e.dashboard == dashboardName
      )[0];
      if (!selectedProjList || selectedProjList?.selectedValues.length == 0) {
        let projList: DataProps[] = Projects;
        let selected: string[] = getAllKeys(projList);
        setSelected(selected);
        setData(Projects);
        const projectFilter = {
          dashboard: dashboardName,
          selectedValues: selected as [string],
        };
        dispatch({ type: "projectFilter", payload: projectFilter });
      } else {
        setData(Projects);
      }
    } else {
      let projList: DataProps[] = Projects;
      let selected: string[] = getAllKeys(projList);
      setSelected(selected);
      setData(Projects);
      const projectFilter = {
        dashboard: dashboardName,
        selectedValues: selected as [string],
      };
      dispatch({ type: "projectFilter", payload: projectFilter });
    }
  }, [Projects]);

  function getPeriodFilters() {
    const periodList: PeriodListProps[] = [];

    Object.keys(PeriodEnum).forEach((key, index) => {
      let value = key.replace("_", " ");

      if (isNaN(Number(key))) {
        if (key == "This_Week") {
          value = value + " [Monday to Sunday]";
        }

        periodList.push({
          key: key,
          value: value,
          isSelected: false,
        });
      }
    });

    setPeriodList(periodList);
  }

  async function getTagFilters(
    requestBody: ILabelFilterAPIParams,
    type?: "initial"
  ) {
    setIsTagsLoading(true);
    const accessToken = await getAccessToken(instance, accounts[0]);

    if (requestBody?.containerIds?.length > 0)
      await getLabelFilterList(requestBody, accessToken)
        .then(({ data }) => {
          setTagList(data);
          if (type && type === "initial") {
            setTagsFullList(data);
          }
          setIsTagsLoading(false);

          refreshTagsDispatch({
            type: "FETCH_TAGS",
            payload: { isTagsRefreshing: false },
          });
        })
        .catch((error) => {
          refreshTagsDispatch({
            type: "FETCH_TAGS",
            payload: { isTagsRefreshing: false },
          });
          setIsTagsLoading(false);
        });
  }

  function getSelectedProjects() {
    const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedState) {
      const parsedState = JSON.parse(storedState) as FilterState;
      return parsedState.projectFilter.filter(
        (e) => e.dashboard == dashboardName
      )[0]?.selectedValues;
    }
    return [];
  }

  function getValueByKey(
    arr: { key: string; value: string; isSelected: boolean }[],
    key: string
  ): string | null {
    for (const obj of arr) {
      if (obj.key == key) {
        return obj.value;
      }
    }

    return null;
  }

  const isFiltersLoading = (): boolean => {
    return (
      projectStatus == "loading" &&
      isTagsLoading &&
      isStatusesLoading &&
      isOwnersLoading
    );
  };

  useEffect(() => {
    getProjectFilters();
    getPeriodFilters();
    getTagFilters(
      { containerIds: getSelectedProjects(), search: "" },
      "initial"
    );
    getOwnerFilters(
      {
        containerIds: getSelectedProjects(),
        search: "",
      },
      "initial"
    );
    getAssignByUsers(
      {
        containerIds: getSelectedProjects(),
        search: "",
      },
      "initial"
    );
    getStatusFilters({
      containerIds: getSelectedProjects(),
    });
  }, []);

  useEffect(() => {
    //get owner list based on selected projects
    if (selected.length > 0) {
      const projectIds: string[] = selected.map((project) => project);
      getOwnerFilters(
        {
          containerIds: projectIds,
          search: "",
        },
        "initial"
      );
      getAssignByUsers(
        {
          containerIds: projectIds,
          search: "",
        },
        "initial"
      );
      getStatusFilters({
        containerIds: projectIds,
      });
      getTagFilters(
        {
          containerIds: projectIds,
          search: "",
        },
        "initial"
      );
    }
  }, [selected]);

  //for project Filter
  const [projectSearch, setProjectSearch] = useState<string>("");

  const onChangeProjectSearchValue = (value: string) => {
    setProjectSearch(value);
  };

  const onClearProjectSearchValue = () => {
    setProjectSearch("");
  };

  const onAcceptProjectSelectValues = () => {};

  const onClearProjectSelectValues = () => {};

  //For owner filter

  const getTypeSpecificFilters = (type: string) => {
    switch (type) {
      case "owner": {
        return state.ownerFilter?.filter(
          (f) => f.dashboard == dashboardName
        )[0];
      }
      case "assignedBy": {
        return state.assignedByFilter?.filter(
          (f) => f.dashboard == dashboardName
        )[0];
      }
      case "period": {
        return state.periodFilter?.filter(
          (f) => f.dashboard == dashboardName
        )[0];
      }
      case "project": {
        return state.projectFilter?.filter(
          (f) => f.dashboard == dashboardName
        )[0];
      }
      case "tag": {
        return state.tagFilter?.filter((f) => f.dashboard == dashboardName)[0];
      }
      case "status": {
        return state.statusFilter?.filter(
          (f) => f.dashboard == dashboardName
        )[0];
      }
    }
  };

  const checkIfFilterIsEmpty = (type: string): boolean => {
    switch (type) {
      case "owner": {
        const selectedValues = state.ownerFilter?.length
          ? state.ownerFilter?.filter((f) => f.dashboard == dashboardName)[0]
              ?.selectedValues
          : [];
        return selectedValues?.length != 0 ? false : true;
      }
      case "assignedBy": {
        const selectedValues = state.assignedByFilter?.length
          ? state.assignedByFilter?.filter(
              (f) => f.dashboard == dashboardName
            )[0]?.selectedValues
          : [];
        return selectedValues?.length != 0 ? false : true;
      }
      case "period": {
        const selectedValues = state.periodFilter?.filter(
          (f) => f.dashboard == dashboardName
        )[0]?.type;
        return selectedValues ? false : true;
      }
      case "project": {
        const selectedValues = state.projectFilter?.length
          ? state.projectFilter?.filter((f) => f.dashboard == dashboardName)[0]
              ?.selectedValues
          : [];
        return selectedValues?.length != 0 ? false : true;
      }
      case "tag": {
        const selectedValues = state.tagFilter?.length
          ? state.tagFilter?.filter((f) => f.dashboard == dashboardName)[0]
              ?.selectedValues
          : [];
        return selectedValues?.length != 0 ? false : true;
      }
      case "status": {
        const selectedValues = state.statusFilter?.length
          ? state.statusFilter?.filter((f) => f.dashboard == dashboardName)[0]
              ?.selectedValues
          : [];
        return selectedValues?.length != 0 ? false : true;
      }
      default:
        return false;
    }
  };

  function checkIfStateIsEmpty(): boolean {
    return (
      checkIfFilterIsEmpty("owner") &&
      checkIfFilterIsEmpty("period") &&
      checkIfFilterIsEmpty("project") &&
      checkIfFilterIsEmpty("tag") &&
      checkIfFilterIsEmpty("status") &&
      checkIfFilterIsEmpty("assignedBy")
    );
  }

  const findLabelByValue = (
    arr: DataProps[],
    targetValue: string
  ): string | null => {
    for (const item of arr) {
      if (item.key === targetValue) {
        return item.label;
      }
      if (item.children) {
        const result = findLabelByValue(item.children, targetValue);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    const storedState = localStorage.getItem("FilterTagsMinimizedState");
    if (storedState) {
      setFilterTagsMinimized(JSON.parse(storedState));
    }
  }, []);

  async function DeleteFilter() {
    const accessToken = await getAccessToken(instance, accounts[0]);
    const itemReq = {
      id: parseInt(dashboardName.split("/")[2]),
    };
    deletePrivateView(itemReq, accessToken)
      .then(({ data }) => {
        updateViewsAsync(dispatchViews, null, instance, accounts);
        setModalOpen(false);
        navigate("/control-center");
      })
      .catch((error) => {
        console.log(error?.message);
      });
  }

  useEffect(() => {
    let projectLabelsArray: string[] = [];
    let ownerLabelsArray: string[] = [];
    let statusLabelsArray: string[] = [];
    let tagLabelsArray: string[] = [];
    let assignByLabelsArray: string[] = [];

    (
      getTypeSpecificFilters("project") as IMultiSelectFilter
    )?.selectedValues.forEach((value: string) => {
      let projectLabel = findLabelByValue(data, value) || "";
      projectLabelsArray.push(projectLabel);
    });

    let periodLabel =
      (getTypeSpecificFilters("period") as IPeriodFilter)?.type != "Custom"
        ? (getTypeSpecificFilters("period") as IPeriodFilter)?.type.replace(
            "_",
            " "
          )
        : (getTypeSpecificFilters("period") as IPeriodFilter)?.selectedValues;

    (
      getTypeSpecificFilters("owner") as IMultiSelectFilter
    )?.selectedValues.forEach((value: string) => {
      let ownerLabel =
        getValueByKey(
          ownerFullList.length > 0 ? ownerFullList : ownerList,
          value
        ) || "";
      ownerLabelsArray.push(ownerLabel);
    });

    (
      getTypeSpecificFilters("status") as IMultiSelectFilter
    )?.selectedValues.forEach((value: string) => {
      let statusLabel = getValueByKey(statusList, value) || "";
      statusLabelsArray.push(statusLabel);
    });

    (
      getTypeSpecificFilters("tag") as IMultiSelectFilter
    )?.selectedValues.forEach((value: string) => {
      let tagLabel = getValueByKey(tagList, value) || "";
      tagLabelsArray.push(tagLabel);
    });
    (
      getTypeSpecificFilters("assignedBy") as IMultiSelectFilter
    )?.selectedValues.forEach((value: string) => {
      let assignByLabel =
        getValueByKey(
          assignedByUserFullList.length > 0
            ? assignedByUserFullList
            : assignedByUserList,
          value
        ) || "";
      assignByLabelsArray.push(assignByLabel);
    });

    setLabelsArray({
      projectFilter: projectLabelsArray,
      periodFilter: periodLabel ? [periodLabel] : [],
      ownerFilter: ownerLabelsArray,
      statusFilter: statusLabelsArray,
      tagFilter: tagLabelsArray,
      assignedByFilter: assignByLabelsArray,
    });
  }, [state]);

  return (
    <div className={classes.filterPanelWrapper}>
      <Grid>
        <Grid.Col span={!readOnlyFilter && editMode ? 10 : 11}>
          <Grid>
            <Grid.Col span={2}>
              <ProjectFilter
                title="Project"
                StateHasValue={!checkIfFilterIsEmpty("project")}
                searchValue={projectSearch}
                onChangeSearchValue={onChangeProjectSearchValue}
                onClearSearchValue={onClearProjectSearchValue}
                onAcceptSelectValues={onAcceptProjectSelectValues}
                onClearSelectValues={onClearProjectSelectValues}
                handleSubmit={handleProjectSelection}
                handleClear={handleProjectClear}
                isSingle={false}
                selected={selected}
                values={data}
                setSelected={setSelected}
                setValues={setData}
                handleProjectSelectAll={handleProjectSelectAll}
                checkedSelectAll={selectAllProjects}
                setCheckedSelectAll={setSelectAllProjects}
                allKeys={allContainerKeys}
                disabled={disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <PeriodFilter
                StateHasValue={!checkIfFilterIsEmpty("period")}
                title="Target Date"
                periodList={periodList}
                period={period}
                setPeriod={setPeriod}
                handlePeriodSelection={handlePeriodSelection}
                disabled={selected.length == 0 || disableAll}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <OwnerFilter
                StateHasValue={!checkIfFilterIsEmpty("owner")}
                ownerList={ownerList}
                selectedProjectIds={selected}
                getOwnerFilters={getOwnerFilters}
                checked={ownerChecked}
                setChecked={setOwnerChecked}
                handleSubmit={handleOwnerSelection}
                handleClear={handleOwnerClear}
                isLoading={isOwnersLoading}
                disabled={selected.length == 0 || disableAll}
                handleSelectAll={handleOwnerSelectAll}
                checkedSelectAll={selectAllOwners}
                setCheckedSelectAll={setSelectAllOwners}
                allKeys={allOwnerKeys}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <AssignByFilter
                StateHasValue={!checkIfFilterIsEmpty("assignedBy")}
                assignByUsers={assignedByUserList}
                selectedProjectIds={selected}
                getAssignByFilters={getAssignByUsers}
                checked={assignByChecked}
                setChecked={setAssignByChecked}
                handleSubmit={handleAssignedBySelection}
                handleClear={handleAssignedByClear}
                isLoading={isAssignByUsersLoading}
                disabled={selected.length == 0 || disableAll}
                handleSelectAll={handleAssignBySelectAll}
                checkedSelectAll={selectAllAssignByUsers}
                setCheckedSelectAll={setSelectAllAssignByUsers}
                allKeys={allAssignUserKeys}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <StatusFilter
                StateHasValue={!checkIfFilterIsEmpty("status")}
                statusList={statusList}
                selectedContainerIds={selected}
                getStatusFilters={getStatusFilters}
                checked={statusChecked}
                setChecked={setStatusChecked}
                handleSubmit={handleStatusSelection}
                handleClear={handleStatusClear}
                isLoading={isStatusesLoading}
                disabled={selected.length == 0 || disableAll}
                handleSelectAll={handleStatusSelectAll}
                checkedSelectAll={selectAllStatus}
                setCheckedSelectAll={setSelectAllStatus}
                allKeys={allStatusKeys}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <TagFilter
                StateHasValue={!checkIfFilterIsEmpty("tag")}
                tagList={tagList}
                getTagFilters={getTagFilters}
                checked={tagsChecked}
                setChecked={setTagsChecked}
                handleSubmit={handleTagSelection}
                handleClear={handleTagClear}
                isLoading={isTagsLoading}
                disabled={selected.length == 0 || disableAll}
                selectedContainerIds={selected}
                handleSelectAll={handleTagsSelectAll}
                checkedSelectAll={selectAllTags}
                setCheckedSelectAll={setSelectAllTags}
                allKeys={allTagsKeys}
                isRefetching={refreshState.isTagsRefreshing}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        {!readOnlyFilter && (
          <>
            {editMode ? (
              <>
                <Grid.Col span={2}>
                  {!disableAll ? (
                    <SimpleGrid cols={2} spacing={2}>
                      <Button
                        variant="outline"
                        disabled={false} //should check if filter is changed
                        className={classes.saveFilterButton}
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          setSaveFilter(false); //update private view
                          setOpened(true);
                        }}
                      >
                        {translate("update")}
                      </Button>
                      <Button
                        variant="outline"
                        // disabled={checkIfStateIsEmpty()}
                        className={classes.saveFilterButton}
                        onClick={() => {
                          setToInitFilter && setToInitFilter();
                          setDisableAll(true);
                          updateEditMode(dispatchViews, {
                            type: "editMode",
                            payload: false,
                          });
                        }}
                      >
                        {translate("Cancel")}
                      </Button>
                    </SimpleGrid>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="outline"
                        disabled={false}
                        className={classes.saveFilterButton}
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          setDisableAll(!disableAll);
                          updateEditMode(dispatchViews, {
                            type: "editMode",
                            payload: true,
                          });
                        }}
                      >
                        {"Edit Filter"}
                      </Button>
                      <Tooltip label="Delete View" position="bottom" withArrow>
                        <IconTrash
                          size={28}
                          style={{ marginLeft: 8, color: "red" }}
                          onClick={() => setModalOpen(true)}
                        />
                      </Tooltip>
                    </div>
                  )}
                </Grid.Col>
              </>
            ) : (
              <Grid.Col span={1}>
                <Button
                  variant="outline"
                  disabled={checkIfStateIsEmpty()}
                  className={classes.saveFilterButton}
                  onClick={() => {
                    setSaveFilter(true);
                    setOpened(true);
                  }}
                >
                  {translate("filter_panel.save_filter_button_title")}
                </Button>
              </Grid.Col>
            )}
          </>
        )}
      </Grid>

      <Grid columns={25}>
        <Grid.Col span={24}>
          <Group>
            <Button
              variant="outline"
              className={classes.clearFilterButton}
              disabled={checkIfStateIsEmpty() || disableAll}
              onClick={() => {
                dispatch({ type: "clearAllFilters" });
                dispatch({ type: "saveToLocalStorage" });
                setOwnerChecked([]);
                setPeriod(null);
                setTagsChecked([]);
                setStatusChecked([]);
                setSelected([]);
              }}
            >
              {translate("filter_panel.clear_filter_button_title")}
            </Button>
            {!readOnlyFilter ? (
              <>
                {isFiltersLoading() &&
                (getTypeSpecificFilters("project") as IMultiSelectFilter)
                  ?.selectedValues ? (
                  <>
                    <Flex
                      direction="column"
                      align="center"
                      style={{ padding: 10 }}
                    >
                      <Loader variant="dots" />
                    </Flex>
                  </>
                ) : isFilterTagsMinimized ? (
                  <></>
                ) : (
                  <>
                    {(
                      getTypeSpecificFilters("project") as IMultiSelectFilter
                    )?.selectedValues.map((value, index) => (
                      <FilterTag
                        filterType="Project"
                        hideClose={disableAll}
                        icon={
                          <Tooltip label="Project" position="bottom" withArrow>
                            <IconFolder size={15} />
                          </Tooltip>
                        }
                        name={findLabelByValue(data, value) || ""}
                        // name={value}
                        onClose={() => {
                          const tempValue = [...selected];
                          tempValue.splice(index, 1);
                          const projectFilter = {
                            dashboard: dashboardName,
                            selectedValues: tempValue as [string],
                          };

                          dispatch({
                            type: "projectFilter",
                            payload: projectFilter,
                          });
                          dispatch({ type: "saveToLocalStorage" });
                          const SelectedData = updateCheckedStatusWithParent(
                            data,
                            value,
                            false
                          );
                          setData(SelectedData);
                        }}
                      />
                    ))}
                    {(getTypeSpecificFilters("period") as IPeriodFilter)
                      ?.type !== "" ? (
                      (getTypeSpecificFilters("period") as IPeriodFilter)
                        ?.type && (
                        <FilterTag
                          filterType="Target Date"
                          hideClose={disableAll}
                          icon={
                            <Tooltip label="Period" position="bottom" withArrow>
                              <IconClock size={15} />
                            </Tooltip>
                          }
                          name={
                            (getTypeSpecificFilters("period") as IPeriodFilter)
                              ?.type != "Custom"
                              ? (
                                  getTypeSpecificFilters(
                                    "period"
                                  ) as IPeriodFilter
                                )?.type.replace("_", " ")
                              : (
                                  getTypeSpecificFilters(
                                    "period"
                                  ) as IPeriodFilter
                                )?.selectedValues
                          }
                          onClose={() => {
                            const ownerFilter = {
                              type: "period",
                              dashboard: dashboardName,
                              index: 0,
                            };
                            dispatch({
                              type: "clearAtomicFilter",
                              payload: ownerFilter,
                            });
                            setPeriod(null);
                            dispatch({ type: "saveToLocalStorage" });
                          }}
                        />
                      )
                    ) : (
                      <></>
                    )}
                    {(
                      getTypeSpecificFilters("owner") as IMultiSelectFilter
                    )?.selectedValues.map(
                      (value, index) =>
                        getValueByKey(
                          ownerFullList.length > 0 ? ownerFullList : ownerList,
                          value
                        ) && (
                          <FilterTag
                            filterType="Assigned To"
                            hideClose={disableAll}
                            icon={
                              <Tooltip
                                label="Owner"
                                position="bottom"
                                withArrow
                              >
                                <IconUser size={15} />
                              </Tooltip>
                            }
                            name={
                              getValueByKey(
                                ownerFullList.length > 0
                                  ? ownerFullList
                                  : ownerList,
                                value
                              ) || ""
                            }
                            onClose={() => {
                              const ownerFilter = {
                                type: "owner",
                                dashboard: dashboardName,
                                index: index,
                              };
                              dispatch({
                                type: "clearAtomicFilter",
                                payload: ownerFilter,
                              });
                              setOwnerChecked(
                                ownerChecked.filter((_, i) => i !== index) as [
                                  string
                                ]
                              );
                              dispatch({ type: "saveToLocalStorage" });
                            }}
                          />
                        )
                    )}
                    {(
                      getTypeSpecificFilters("assignedBy") as IMultiSelectFilter
                    )?.selectedValues.map(
                      (value, index) =>
                        getValueByKey(
                          assignedByUserFullList.length > 0
                            ? assignedByUserFullList
                            : assignedByUserList,
                          value
                        ) && (
                          <FilterTag
                            filterType="Assigned By"
                            hideClose={disableAll}
                            icon={
                              <Tooltip
                                label="Assign By"
                                position="bottom"
                                withArrow
                              >
                                <IconUser size={15} />
                              </Tooltip>
                            }
                            name={
                              getValueByKey(
                                assignedByUserFullList.length > 0
                                  ? assignedByUserFullList
                                  : assignedByUserList,
                                value
                              ) || ""
                            }
                            onClose={() => {
                              const assignedBy = {
                                type: "assignedBy",
                                dashboard: dashboardName,
                                index: index,
                              };
                              dispatch({
                                type: "clearAtomicFilter",
                                payload: assignedBy,
                              });
                              setAssignByChecked(
                                assignByChecked.filter(
                                  (_, i) => i !== index
                                ) as [string]
                              );
                              dispatch({ type: "saveToLocalStorage" });
                            }}
                          />
                        )
                    )}
                    {(
                      getTypeSpecificFilters("status") as IMultiSelectFilter
                    )?.selectedValues.map((value, index) => (
                      <FilterTag
                        hideClose={disableAll}
                        filterType="Status"
                        icon={
                          <Tooltip label="Status" position="bottom" withArrow>
                            <IconStar size={15} />
                          </Tooltip>
                        }
                        name={getValueByKey(statusList, value) || ""}
                        onClose={() => {
                          const filter = {
                            type: "status",
                            dashboard: dashboardName,
                            index: index,
                          };
                          dispatch({
                            type: "clearAtomicFilter",
                            payload: filter,
                          });
                          setStatusChecked(
                            statusChecked.filter((_, i) => i !== index) as [
                              string
                            ]
                          );
                          dispatch({ type: "saveToLocalStorage" });
                        }}
                      />
                    ))}
                    {(
                      getTypeSpecificFilters("tag") as IMultiSelectFilter
                    )?.selectedValues.map((value, index) => (
                      <FilterTag
                        hideClose={disableAll}
                        filterType="Tags"
                        icon={
                          <Tooltip label="Tag" position="bottom" withArrow>
                            <IconTag size={15} />
                          </Tooltip>
                        }
                        name={
                          getValueByKey(
                            tagsFullList.length > 0 ? tagsFullList : tagList,
                            value
                          ) || ""
                        }
                        onClose={() => {
                          const filter = {
                            type: "tag",
                            dashboard: dashboardName,
                            index: index,
                          };
                          dispatch({
                            type: "clearAtomicFilter",
                            payload: filter,
                          });
                          setTagsChecked(
                            tagsChecked.filter((_, i) => i !== index) as [
                              string
                            ]
                          );
                          dispatch({ type: "saveToLocalStorage" });
                        }}
                      />
                    ))}
                  </>
                )}
              </>
            ) : isFilterTagsMinimized ? (
              <></>
            ) : (
              <ReadOnlyFilterPanel readonlyFilters={readOnlyFilter} />
            )}
          </Group>
        </Grid.Col>
        <Grid.Col
          span={1}
          className={
            isFilterTagsMinimized
              ? classes.expandButtonWrapper
              : classes.minimizeButtonWrapper
          }
        >
          <IconButton
            variant="outline"
            className={classes.minimizeFilterButton}
            display={
              isFiltersLoading() || checkIfStateIsEmpty() ? "none" : "block"
            }
            onClick={() => {
              setFilterTagsMinimized(!isFilterTagsMinimized);
              localStorage.setItem(
                "FilterTagsMinimizedState",
                JSON.stringify(!isFilterTagsMinimized)
              );
            }}
            disabled={checkIfStateIsEmpty()}
          >
            {isFilterTagsMinimized ? (
              <IconChevronsDown size={20} />
            ) : (
              <IconChevronsUp size={20} />
            )}
          </IconButton>
        </Grid.Col>
      </Grid>
      <div>
        <SaveFilterPopup
          labelsArray={labelsArray}
          opened={opened}
          setOpened={setOpened}
          dashboardName={dashboardName}
          save={saveFilter} // true-Save filter | false-Update filter
          disableAll={disableAll}
          setDisableAll={setDisableAll}
        />
      </div>
      {modalOpen && (
        <Modal
          opened={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          title="Delete View"
          size={428}
        >
          <Text>
            Are you sure you want to delete this <b>"{filterName}"</b> view?
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button
              variant="outline"
              color="gray"
              compact
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              compact
              style={{ marginLeft: 5 }}
              color="red"
              onClick={() => {
                DeleteFilter();
              }}
            >
              Delete
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FilterPanel;
