import {
  IconCheckbox,
  IconChevronDown,
  IconChevronUp,
  IconSquare,
  IconSquareMinus,
} from "@tabler/icons-react";
import CheckboxTree from "react-checkbox-tree";
import { CheckboxListProps } from "./type";
import useStyles from "./style";

const CheckboxList = ({
  nodes,
  checked,
  expanded,
  onCheck,
  onExpand,
  isMultilevel,
  isAddItem
}: CheckboxListProps) => {
  const { classes , cx } = useStyles();

  return (
    <div
      className={cx(classes.checkboxBody, {
        [classes.checkboxBodySingle]: !isMultilevel,
        [classes.addItemCheckBox]: isAddItem,
      })}
    >
      <CheckboxTree
        data-testid = "checkboxtree"
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onCheck={onCheck}
        onExpand={onExpand}
        icons={{
          check: <IconCheckbox size={15} color="blue" />,
          uncheck: <IconSquare size={15} />,
          halfCheck: <IconSquareMinus size={15} color="blue" />,
          expandClose: <IconChevronDown size={15} />,
          expandOpen: <IconChevronUp size={15} />,
          expandAll: null,
          collapseAll: null,
          parentClose: null,
          parentOpen: null,
          leaf: null,
        }}
      />
    </div>
  );
};

export default CheckboxList;
