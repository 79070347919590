import { createContext, useContext, useReducer } from "react";
import {
  getNotifications,
  nofiticationProps,
  markAllNotificationsAsRead,
  getNotificationCount
} from "../services/notificationService/notificationService";
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../services/authService";

interface NotificationsState {
  status: "idle" | "loading" | "failed" | "authLoading";
  markNotificationAsReadStatus: "idle" | "loading" | "failed" | "success";
  notifications: nofiticationProps;
  count: number;
}

type Action =
  | { type: "FETCH_NOTIFICATIONS_PENDING" }
  | { type: "FETCH_NOTIFICATIONS_FULFILLED"; payload: nofiticationProps }
  | { type: "FETCH_NOTIFICATIONS_REJECTED" } 
  | { type: "MARK_ALL_NOTIFICATIONS_AS_READ_PENDING" } 
  | { type: "MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED"; payload: nofiticationProps } 
  | { type: "MARK_ALL_NOTIFICATIONS_AS_READ_REJECTED" }
  | { type: "FETCH_NOTIFICATIONS_SEE_MORE_FULFILLED"; payload: nofiticationProps; }
  | { type: "FETCH_NOTIFICATIONS_REJECTED" }
  | { type: "FETCH_NOTIFICATIONS_COUNT_PENDING" }
  | { type: "FETCH_NOTIFICATIONS_COUNT_FULFILLED"; payload: number }
  | { type: "FETCH_NOTIFICATIONS_COUNT_REJECTED" };

interface ContextValue extends NotificationsState {
  fetchNotifications: (
    type: number,
    pageNumber: number,
    pageSize: number,
    onSeeMore?: boolean
  ) => Promise<void>;
  markAllNotificationsAsReadDispatch: (
    type: number,
    pageNumber: number,
    pageSize: number,
    onSeeMore?: boolean) => Promise<void>;
  notificationCount: () => Promise<void>;
}

const NotificationsContext = createContext<ContextValue | undefined>(undefined);

type NotificationProviderProps = { children: React.ReactNode };

const initialState: NotificationsState = {
  notifications: {
    currentPage: 0,
    totalPages: 0,
    pageSize: 0,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
    items: [],
  },
  status: "idle",
  markNotificationAsReadStatus: "idle",
  count: 0
};

const reducer = (
  state: NotificationsState,
  action: Action
): NotificationsState => {
  switch (action.type) {
    case "FETCH_NOTIFICATIONS_PENDING":
      return { ...state, status: "loading" };
    case "FETCH_NOTIFICATIONS_FULFILLED":
      return { ...state, status: "idle", notifications: action.payload };
    case "FETCH_NOTIFICATIONS_SEE_MORE_FULFILLED":
      return {
        ...state,
        status: "idle",
        notifications: {
          ...action.payload,
          items: state.notifications.items.concat(action.payload.items),
        },
      };
    case "FETCH_NOTIFICATIONS_REJECTED":
      return { ...state, status: "failed" };
    case "MARK_ALL_NOTIFICATIONS_AS_READ_PENDING":
      return { ...state, markNotificationAsReadStatus: "loading" };
    case "MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED":
      return { ...state, markNotificationAsReadStatus: "success", count: 0, notifications: action.payload };
    case "MARK_ALL_NOTIFICATIONS_AS_READ_REJECTED":
      return { ...state, markNotificationAsReadStatus: "failed" };
    case "FETCH_NOTIFICATIONS_COUNT_PENDING":
      return { ...state, status: "loading" };
    case "FETCH_NOTIFICATIONS_COUNT_FULFILLED":
      return { ...state, status: "idle", count: action.payload };
    case "FETCH_NOTIFICATIONS_COUNT_REJECTED":
      return { ...state, status: "failed" };
    default:
      return state;
  }
};

function NotificationProvider({ children }: NotificationProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { instance, accounts } = useMsal();

  const fetchNotifications = async (
    type: number,
    pageNumber: number,
    pageSize: number,
    onSeeMore?: boolean
  ) => {
    dispatch({ type: "FETCH_NOTIFICATIONS_PENDING" });
    try {
      const accessToken = await getAccessToken(instance, accounts[0]);
      const response = await getNotifications(
        type,
        pageNumber,
        pageSize,
        accessToken
      );
      const data = response?.data;
      if (onSeeMore) {
        dispatch({
          type: "FETCH_NOTIFICATIONS_SEE_MORE_FULFILLED",
          payload: data,
        });
      } else {
        dispatch({ type: "FETCH_NOTIFICATIONS_FULFILLED", payload: data });
      }
    } catch (error) {
      dispatch({ type: "FETCH_NOTIFICATIONS_REJECTED" });
    }
  };

  const notificationCount = async () => {
    dispatch({ type: "FETCH_NOTIFICATIONS_COUNT_PENDING" });
    try {
      const accessToken = await getAccessToken(instance, accounts[0]);
      const response = await getNotificationCount(accessToken);
      const data = response?.data;
      dispatch({
        type: "FETCH_NOTIFICATIONS_COUNT_FULFILLED",
        payload: data,
      });
    } catch (error) {
      dispatch({ type: "FETCH_NOTIFICATIONS_COUNT_REJECTED" });
    }
  };

  const markAllNotificationsAsReadDispatch = async (
    type: number,
    pageNumber: number,
    pageSize: number,
    onSeeMore?: boolean) => {
    dispatch({ type: "MARK_ALL_NOTIFICATIONS_AS_READ_PENDING" });
    try {
      const accessToken = await getAccessToken(instance, accounts[0]);
      await markAllNotificationsAsRead(accessToken);
      const response = await getNotifications(
        type,
        pageNumber,
        pageSize,
        accessToken
      );
      const data = response?.data;
      dispatch({ type: "MARK_ALL_NOTIFICATIONS_AS_READ_FULFILLED", payload: data });
    } catch (error) {
      dispatch({ type: "MARK_ALL_NOTIFICATIONS_AS_READ_REJECTED" });
    }
  };
  
  return (
    <NotificationsContext.Provider value={{ ...state, fetchNotifications, notificationCount, markAllNotificationsAsReadDispatch }}>
      {children}
    </NotificationsContext.Provider>
  );
}

const useNotificationsContext = (): ContextValue => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};

export { useNotificationsContext, NotificationProvider };
