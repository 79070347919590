import { Dispatch, SetStateAction } from "react";
import { DataProps } from "./type";

export const setCheckedVlues = (
  selected: string[],
  values: DataProps[],
  setValues: Dispatch<SetStateAction<DataProps[]>>
) => {
  setAllCheckedFalse(values)
  if (selected.length > 0) {
    selected.forEach((key) => {
      const SelectedData = updateCheckedStatusWithParent(values, key, true);
      setValues(SelectedData);
    });
  } else {
    setValues(setAllCheckedFalse(values));
  }
};

export function getCheckedKeys(data: DataProps[]): string[] {
  const checkedKeys: string[] = [];

  function traverseData(data: DataProps[]): void {
    for (const item of data) {
      if (item.checked) {
        checkedKeys.push(item.key);
      }

      if (item.children && item.children.length > 0) {
        traverseData(item.children);
      }
    }
  }

  traverseData(data);

  return checkedKeys;
}

export const generateNewSelectedProject = (
  dataList: DataProps[],
  selectedArray: string[]
): string[] => {
  const newSelected: string[] = [];

  // Helper function to check if all child values are in selectedArray
  function areAllChildrenSelected(parent: DataProps): boolean {
    if (!parent.children) {
      return selectedArray.includes(parent.key);
    }

    for (const child of parent.children) {
      if (
        !selectedArray.includes(child.key) &&
        !areAllChildrenSelected(child)
      ) {
        return false;
      }
    }

    return true;
  }

  // Recursive function to traverse the data structure
  function traverseData(data: DataProps[]) {
    for (const item of data) {
      if (areAllChildrenSelected(item) && !newSelected.includes(item.key)) {
        newSelected.push(item.key);
      } else if (item.children) {
        traverseData(item.children);
        for (const child of item.children) {
          if (
            selectedArray.includes(child.key) &&
            !newSelected.includes(child.key)
          ) {
            newSelected.push(child.key);
          }
        }
      }
    }
  }

  traverseData(dataList);

  return newSelected;
};
export function setAllCheckedFalse(data: DataProps[]): DataProps[] {
  return data.map((item) => {
    item.checked = false;
    if (item.children) {
      item.children = setAllCheckedFalse(item.children);
    }
    return item;
  });
}

export function setAllCheckedTrue(data: DataProps[]): DataProps[] {
  return data.map((item) => {
    item.checked = true;
    if (item.children) {
      item.children = setAllCheckedTrue(item.children);
    }
    return item;
  });
}

export function updateCheckedStatus(
  data: DataProps[],
  key: string,
  checked: boolean
): DataProps[] {
  return data.map((item) => {
    if (item.key === key) {
      item.checked = checked;
    } else if (item.children) {
      item.children = updateCheckedStatus(item.children, key, checked);
    }
    return item;
  });
}

export function updateCheckedStatusRecursive(
  data: DataProps[],
  checked: boolean
): DataProps[] {
  return data.map((item) => {
    item.checked = checked;
    if (item.children) {
      item.children = updateCheckedStatusRecursive(item.children, checked);
    }
    return item;
  });
}

export function updateParentCheckedStatus(data: DataProps[]): DataProps[] {
  return data.map((item) => {
    if (item.children) {
      const allChildrenChecked = item.children.every((child) => child.checked);
      item.checked = allChildrenChecked;
      item.children = updateParentCheckedStatus(item.children);
    }
    return item;
  });
}

export function updateCheckedStatusWithParent(
  data: DataProps[],
  key: string,
  checked: boolean
): DataProps[] {
  const updatedData = updateCheckedStatus(data, key, checked);
  return updatedData;
}

export function setVisibilityByLabel(
  arr: DataProps[],
  targetLabel: string
): DataProps[] {
  return arr;
}

export function setVisibilityToFalse(arr: DataProps[]): DataProps[] {
  const traverse = (data: DataProps[]): void => {
    for (const item of data) {
      item.visible = false;
      if (item.children) {
        traverse(item.children);
      }
    }
  };

  traverse(arr);
  return arr;
}

export function setVisibilityToTrue(arr: DataProps[]): DataProps[] {
  const traverse = (data: DataProps[]): void => {
    for (const item of data) {
      item.visible = true;
      if (item.children) {
        traverse(item.children);
      }
    }
  };

  traverse(arr);
  return arr;
}

export function setVisibilityAndParents(
  data: DataProps[],
  searchString: string
): DataProps[] {
  const findAndSetVisible = (
    items: DataProps[],
    searchStr: string,
  ): void => {
    for (const item of items) {
      if (item.label.toLowerCase().includes(searchStr.toLowerCase())) {
        item.visible = true;
      }

      if (item.children) {
        findAndSetVisible(item.children, searchStr)
        if(item.children.some((e) => e.visible)){
          item.visible = true
        }
      }
    }
  };

  findAndSetVisible(data, searchString);

  return data;
}
