import { MentionItem, SuggestionDataItem } from "react-mentions";
import AppMentionInput from "../../common/AppMentionInput";
import { useEffect, useRef, useState } from "react";
import { Button, Flex } from "@mantine/core";
import { CommentEditorProps } from "./type";
import { getAccessToken } from "../../../services/authService";
import { getAllOwnersOptions, getOwnersOptions } from "../../../services/AddItemService/addItems";
import { useMsal } from "@azure/msal-react";
import { useQuickItemViewContext } from "../../../contexts/quickItemView.context";

const CommentEditor = ({
  onSubmit,
  onClear,
  comment,
  setUserList,
  commentList,
  plainText,
  mentions,
  onChangeHandler: propOnChangeHandler,
}: CommentEditorProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setIsCommentBoxUpdated } = useQuickItemViewContext();

  const { instance, accounts } = useMsal();
  const [commentValue, setCommentValue] = useState({
    plainText:  plainText || '',
    mentions: mentions || [] as MentionItem[],
    originalText: comment || ''
  });

  useEffect(()=> {
    setCommentValue({
    plainText:  plainText || '',
    mentions: mentions || [] as MentionItem[],
    originalText: comment || ''
  })
  },[plainText, comment, mentions ])

  async function fetchUsers(
    query: string,
    callback: (data: SuggestionDataItem[]) => void
  ) {
    const accessToken = await getAccessToken(instance, accounts[0]);
    getAllOwnersOptions(query, accessToken)
      .then(({ data: userData }) => {
        const users = userData.map((lbl) => {
          return {
            id: `${lbl.id}`,
            display: lbl.display,
            image: "",
          };
        });
        callback(users);
      })
      .catch();
  }

  const onChangeHandler = (
    e: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]
  ) => {
    setUserList && setUserList(mentions);
    const newComment = {
      plainText: newPlainTextValue,
      mentions,
      originalText: e.target.value
    };
    setCommentValue(newComment)
  
    setIsCommentBoxUpdated(comment !== commentValue.originalText)

    propOnChangeHandler && propOnChangeHandler(newComment)
  }

  const onClickHandler = () => {
    onSubmit(commentValue.originalText, commentValue.plainText, commentValue.mentions);
    setCommentValue({
      plainText: "",
      mentions: [],
      originalText: "",
    });
  }

  return (
    <div style={{ width: "100%" }}>
      <AppMentionInput
        value={commentValue.originalText}
        inputRef={inputRef}
        onChange={onChangeHandler}

        dataFunc={fetchUsers}
      />
      <Flex
        justify="flex-end"
        align="center"
        direction="row"
        wrap="wrap"
        style={{ marginTop: 5 }}
        gap={5}
      >
        {onClear && (
          <Button variant="outline" color="gray" compact onClick={onClear}>
            {" "}
            Cancel
          </Button>
        )}
        <Button
          variant="outline"
          compact
          onClick={onClickHandler}
        >
          {" "}
          {(onClear) ? "Update":"Add Comment"}
        </Button>
      </Flex>
    </div>
  );
};

export default CommentEditor;
