import { useCallback, useEffect, useState } from "react";
import { Box, Button, Center, Flex, Loader, Select } from "@mantine/core";
import {
  IconBellCancel,
  IconChevronDown,
  IconCircleCheck,
  IconNotificationOff,
} from "@tabler/icons-react";
import { Text } from "@mantine/core";
import useStyles from "./style";
import InboxMsg from "../../components/common/InboxMsg";
import { NotificationsProps } from "./type";
import { useNotificationsContext } from "../../contexts/notifications.context";
import Typography from "../../components/common/Typography";
import { TypographyTypes } from "../../utils/enums";

const Notifications = ({ onClickItem, isItemDrawerOpened }: NotificationsProps) => {
  const { classes, cx } = useStyles();
  const { fetchNotifications, notifications, status, markAllNotificationsAsReadDispatch, markNotificationAsReadStatus } =
    useNotificationsContext();

  const [notificationType, setNotificationType] = useState("2");
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 10;

  const fetchNotificationsCallBack = () => {
    fetchNotifications(JSON.parse(notificationType), pageNumber, pageSize);
  };

  const onClickSeeMore = () => {
    fetchNotifications(
      JSON.parse(notificationType),
      notifications.currentPage + 1,
      pageSize,
      true
    );
  };

  useEffect(() => {
        fetchNotificationsCallBack();
  }, [notificationType, pageNumber]);

  useEffect(() => {
    if(!isItemDrawerOpened) {
      fetchNotificationsCallBack();
    }
}, [isItemDrawerOpened]);

  const setSelectedNotificationType = (type:string) => {
    setNotificationType(type);
    localStorage.setItem("selectedNotificationType", type)
  };

  const NoNotificationDiv = () => {
    return (
      <>
        <IconBellCancel size={50}></IconBellCancel>
        <Typography
          type={TypographyTypes.title}
          text={"No Notifications Found"}
        />
      </>
    );
  };

  return (
    <>
      <div className={classes.filterContainer}>
        <Text
          data-testid="typography-component"
          className={classes.showLabel}
          onClick={() => {
            console.log("TESTING");
          }}
        >
          {" "}
          Show :
        </Text>
        <Select
          rightSection={<IconChevronDown size="1rem" />}
          rightSectionWidth={30}
          data={[
            { label: "All", value: "3" },
            { label: "Read", value: "1" },
            { label: "Unread", value: "2" },
          ]}
          value={notificationType}
          onChange={(val) => setSelectedNotificationType(val || "2")}
        />
        <Button
          variant="outline"
          color="dark"
          size="md"
          style={{ marginLeft: 30, height: 35 }}
          rightIcon={<IconCircleCheck size="1rem" />}
          loading={markNotificationAsReadStatus === "loading"}
          onClick={() => {
            markAllNotificationsAsReadDispatch(JSON.parse(notificationType), pageNumber, pageSize);
          }}
        >
          Mark all as read
        </Button>
      </div>
      {notifications?.items.length > 0 ? (
        <>
          <div style={{ margin: 10 }}>
            <InboxMsg data={notifications?.items} onClickItem={onClickItem} />
          </div>
          <div className={classes.seeMoreDiv}>
            <span
              onClick={(notifications.currentPage != notifications.totalPages) && status != "loading" ? onClickSeeMore : () => {}}
              className={cx(classes.seeMoreText, {
                [classes.seeMoreTextDisabled]: !notifications.hasNext,
              })}
            >
              {"Show More "}
            </span>
            <span>
              ({notifications.items.length} of {notifications.totalCount})
            </span>
          </div>
        </>
      ) : (
        <Flex
          direction={"column"}
          justify={"center"}
          align={"center"}
          className={classes.noNotifications}
        >
          {status === "loading" ? <Loader /> : <NoNotificationDiv />}
        </Flex>
      )}
    </>
  );
};

export default Notifications;
