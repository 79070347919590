import { Flex, Grid } from "@mantine/core";
import Widget from "../../components/widget";
import useStyles from "./styles";
import { WidgetProps } from "../../components/widget/type";
import { useEffect, useState } from "react";
import { WidgetPanelProps } from "./types";
import { RaidTypeEnum } from "../../utils/enums";
import { RaidType } from "../RaidView";

const WidgetPanel = ({ setTitle, countData }: WidgetPanelProps) => {
  const [widgetList, setWidgetList] = useState<WidgetProps[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    const persistedRaidType = localStorage.getItem("raidType")
    let wList = [
      {
        title: "All",
        count: countData[0],
        key: null,
        backgroundColor: "#99a6b3",
        activeBackgroundColor: "#003568",
        icon: "all",
        isActive: selectedIndex === 0,
      },
      {
        title: "Risks",
        count: countData[1],
        key: RaidTypeEnum.RISK,
        backgroundColor: "#c0d9f1",
        activeBackgroundColor: "#3C91E6",
        icon: "risk",
        isActive: selectedIndex === 1,
      },
      {
        title: "Action Items",
        count: countData[2],
        key: RaidTypeEnum.ACTION,
        backgroundColor: "#96e1f6",
        activeBackgroundColor: "#01BAEF",
        icon: "action",
        isActive: selectedIndex === 2,
      },
      {
        title: "Issues",
        count: countData[3],
        key: RaidTypeEnum.ISSUE,
        backgroundColor: "#aedfc2",
        activeBackgroundColor: "#0EC057",
        icon: "issue",
        isActive: selectedIndex === 3,
      },
      {
        title: "Dependencies",
        count: countData[4],
        key: RaidTypeEnum.DEPENDENCY,
        backgroundColor: "#cad7c1",
        activeBackgroundColor: "#70AD47",
        icon: "depend",
        isActive: selectedIndex === 4,
      },
    ]

    if (persistedRaidType) {
      let rt: RaidType = JSON.parse(persistedRaidType)
      setSelectedIndex(rt.key)
      wList.forEach((widget, i) => {
        if(i == rt.key) {
          widget.isActive = true;
          setTitle({ key: i, value: widget.title });
        } else {
          widget.isActive = false;
        }
      })
      setWidgetList(wList);
    } else {
      setWidgetList(wList);
    }
    
  }, []);

  useEffect(() => {
    setWidgetList([
      {
        title: "All",
        count: countData[0],
        key: null,
        backgroundColor: "#99a6b3",
        activeBackgroundColor: "#003568",
        icon: "all",
        isActive: selectedIndex === 0,
      },
      {
        title: "Risks",
        count: countData[1],
        key: RaidTypeEnum.RISK,
        backgroundColor: "#c0d9f1",
        activeBackgroundColor: "#3C91E6",
        icon: "risk",
        isActive: selectedIndex === 1,
      },
      {
        title: "Action Items",
        count: countData[2],
        key: RaidTypeEnum.ACTION,
        backgroundColor: "#96e1f6",
        activeBackgroundColor: "#01BAEF",
        icon: "action",
        isActive: selectedIndex === 2,
      },
      {
        title: "Issues",
        count: countData[3],
        key: RaidTypeEnum.ISSUE,
        backgroundColor: "#aedfc2",
        activeBackgroundColor: "#0EC057",
        icon: "issue",
        isActive: selectedIndex === 3,
      },
      {
        title: "Dependencies",
        count: countData[4],
        key: RaidTypeEnum.DEPENDENCY,
        backgroundColor: "#cad7c1",
        activeBackgroundColor: "#70AD47",
        icon: "depend",
        isActive: selectedIndex === 4,
      },
    ]);
  }, [countData]);

  const { classes } = useStyles();

  const setActive = (index: number) => {
    setTitle({ key: index, value: widgetList[index].title });
    setWidgetList((prevWidgetList) => {
      const updatedList = [...prevWidgetList];
      updatedList.forEach((widget, i) => {
        if (i === index) {
          setSelectedIndex(i);
          widget.isActive = true;
        } else {
          widget.isActive = false;
        }
      });
      return updatedList;
    });
  };

  return (
    <div>
      <Grid className={classes.widgetCardPanel}>
        {widgetList.map((widget, i) => {
          return (
            <Grid.Col
              xs={6}
              sm={6}
              md={6}
              lg={2.4}
              onClick={() => {
                setActive(i);
              }}
              key={i}
            >
              <Widget {...widget} />
            </Grid.Col>
          );
        })}
      </Grid>
    </div>
  );
};

export default WidgetPanel;
