import { Avatar, Box } from "@mantine/core";
import useStyles from "../style";
import { OwnerLabelProps } from "../type";

const MentionedByLabel = ({ imageUrl, color, name }: OwnerLabelProps) => {
  const { classes, cx } = useStyles();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Avatar
        radius="lg"
        src={imageUrl}
        data-testid="test-avatar-comp"
      >
        {name.slice(0, 1)}
      </Avatar>
      <span>{name}</span>
    </Box>
  );
};

export { MentionedByLabel };
