import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  checkboxBody: {
    "& .react-checkbox-tree": {
      // marginTop: "0.5rem",
      padding: "0 0.5rem",

      // minHeight: "5rem",
      maxHeight: "10rem",
      overflow: "auto",
      overflowX: "hidden",

      scrollbarColor: "#878787 transparent",
      "scrollbar-width": "thin",

      "@media (min-width: 1440px)": {
        maxHeight: "20rem",
      },

      "::-webkit-scrollbar": {
        width: "4px",
      },
      "::-webkit-scrollbar-track": {
        background: "#f1f1f1",
        borderRadius: "20px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#878787",
        borderRadius: "20px",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#6e6e6e",
      },
      ol: {
        listStyleType: "none",
      },
      input: {
        display: "none",
      },
    },
  },
  checkboxBodySingle: {
    "& .rct-collapse ": {
      display: "none",
    },
    "& .rct-node-icon  ": {
      display: "none",
    },
    "& .react-checkbox-tree": {
      maxHeight: "8rem !important",
      li: {
        height: "auto",
        paddingTop: "4%",
        "font-size": "0.9rem",
      },
    },
  },
  addItemCheckBox: {
    "& .rct-title": {
      paddingLeft: 10,
    },
    "& .react-checkbox-tree": {
      ol: {
        paddingLeft: 10,
      },
    },
  },
}));

export default useStyles;
