import { Button, Flex, Popover } from "@mantine/core";
import { AddLabelProps, LabelData, ListProps } from "./type";
import { IconAlertTriangle, IconPlus } from "@tabler/icons-react";
import useAddLabelStyles from "./styles";
import { useEffect, useState } from "react";
import Search from "../common/Search/Search";
import CheckboxList from "../common/CheckboxList";
import Typography from "../common/Typography";
import { TypographyTypes } from "../../utils/enums";

const AddLabel = ({
  opened,
  setOpened,
  closeOnClickOutside,
  labelData,
  checked,
  setChecked,
  setCreatedLabels,
}: AddLabelProps) => {
  const { classes } = useAddLabelStyles();
  const [modalOpened, setModalOpened] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [visibleOptions, setVisibleOptions] = useState<ListProps[]>([]);
  const [newLabels, setNewLabels] = useState<string[]>([]);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  useEffect(() => {
    let nodeList = getOptions(labelData);
    setVisibleOptions(nodeList);
    const checkedNodes = nodeList
      .filter((j) => j.isSelected)
      .map((e) => e.value);
    if (checkedNodes.length == 0) {
      setChecked(checked as [string]);
    } else {
      setChecked(checkedNodes as [string]);
    }
  }, [labelData]);

  if (opened === undefined && setOpened === undefined) {
    opened = modalOpened;
    setOpened = setModalOpened;
  }

  useEffect(() => {
    let nodeList = getOptions(labelData);
    const updatedNodeList = [
      ...nodeList,
      ...newLabels.map((label) => ({ label, value: label, isSelected: true })),
    ];

    setVisibleOptions(updatedNodeList);

    setCreatedLabels && setCreatedLabels(newLabels);
  }, [newLabels]);

  useEffect(() => {
    const commonElements = checked.filter((element) =>
      newLabels.includes(element)
    );
    setNewLabels(commonElements);
  }, [checked]);

  const onHandleSearchChange = (e: any) => {
    setSearchString(e.target.value);
    if (newLabels.includes(e.target.value)) {
      setIsDuplicate(true);
    } else {
      setIsDuplicate(false);
    }
    const searchedList = labelData.filter((label) =>
      label.value.toLowerCase().includes(e.target.value.toLowerCase())
    );
    const nodeList: ListProps[] = getOptions(searchedList);

    if (searchedList.length > 0) {
      setVisibleOptions(nodeList);
    } else {
      setVisibleOptions([]);
    }
  };

  const handleCreate = () => {
    const newChecked = [...checked, searchString];
    setChecked(newChecked as [string]);

    const nLabels = [...newLabels, searchString];

    setNewLabels(nLabels);
    setSearchString("");

    let nodeList = getOptions(labelData);
    let newItem = {
      label: searchString,
      value: searchString,
      isSelected: true,
    };
    let newList = [...nodeList, newItem];

    setVisibleOptions(newList);
  };

  const handleCheck = (data: string[]) => {
    const newChecked = [...data];
    setChecked(newChecked as [string]);
    let nodeList = getOptions(labelData);
    setVisibleOptions(nodeList);
  };

  const handleSearchClear = () => {
    setSearchString("");
    // Update nodeList to include newLabels
    let nodeList = getOptions(labelData);
    const updatedNodeList = [
      ...nodeList,
      ...newLabels.map((label) => ({ label, value: label, isSelected: true })),
    ];
    setVisibleOptions(updatedNodeList);
  };

  const getOptions = (data: LabelData[]) => {
    const nodeList: ListProps[] = data.map((i) => {
      return {
        label: i.value,
        value: i.value,
        isSelected: i.isSelected,
      };
    });

    return nodeList;
  };

  return (
    <>
      <Popover
        width={300}
        position="bottom"
        withArrow
        shadow="md"
        opened={opened}
        onChange={setOpened}
        closeOnClickOutside={closeOnClickOutside}
      >
        <Popover.Target>
          <Button
            leftIcon={<IconPlus size={14} />}
            data-testid="popover-trigger"
            className={classes.addLabelBtn}
            loaderPosition="left"
            variant={"filled"}
            onClick={() => setOpened(!opened)}
          >
            {"Add Tag"}
          </Button>
        </Popover.Target>
        <Popover.Dropdown className={classes.popOverDropDown}>
          <Flex direction="column">
            <Search
              value={searchString}
              onChange={(e) => onHandleSearchChange(e)}
              onClear={handleSearchClear}
            />
            {visibleOptions.length > 0 ? (
              <CheckboxList
                nodes={visibleOptions}
                checked={checked}
                onCheck={(checked) => handleCheck(checked)}
                isMultilevel={false}
                expanded={[]}
                isAddItem={true}
              />
            ) : !isDuplicate ? (
              <Flex
                direction="row"
                gap={10}
                align="center"
                className={classes.createLabel}
                onClick={handleCreate}
              >
                <IconPlus size={14} />
                <Typography type={TypographyTypes.label} text={"Create"} />
                <Typography type={TypographyTypes.label} text={searchString} />
              </Flex>
            ) : (
              <Flex
                direction="row"
                gap={5}
                align="center"
                className={classes.errorLabel}
              >
                <IconAlertTriangle size={15} style={{ marginTop: 2 }} />
                <Typography
                  type={TypographyTypes.label}
                  text={"Tag is already created"}
                />
              </Flex>
            )}
          </Flex>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

export default AddLabel;