import { Group, Text, useMantineTheme, rem } from "@mantine/core";
import useStyles from "./styles";
import { ICustomDropzoneProps } from "./type";
import { useTranslation } from "react-i18next";
import { DragEvent } from "react";

export function AttchmentDropzone(
  props: ICustomDropzoneProps
) {
  const theme = useMantineTheme();
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const handleDrop = (event: any) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    props.handleDropFiles(droppedFiles);
  };

  return (
    <div
      className={classes.dropzone}
      onClick={() => props.handleOnclick()}
      onDragOver={(e) => e.preventDefault()} // Prevent the browser's default drag over behavior
      onDrop={(e) => handleDrop(e)} // Call the custom handleDrop function
    >
      <div>
        <Text size="xl" inline>
          + {t("drop_files")}
        </Text>
      </div>
    </div>
  );
}