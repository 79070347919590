import { createContext, useContext, useReducer } from "react";
import { getAuthAccessToken, getUserDetails, getUserImage } from "../services/authService";
import { useMsal } from "@azure/msal-react";

interface UserState {
    details: Iuser;
    status: "idle" | "loading" | "failed" | "authLoading";
    image: string | undefined;
}

interface Iuser {
    displayName: string;
    givenName: string;
    surname: string;
    mail: string;
    id: string;
}

type Action =
    | { type: 'FETCH_DATA_PENDING' }
    | { type: 'FETCH_DATA_FULFILLED'; payload: Iuser }
    | { type: 'FETCH_DATA_REJECTED' }
    | { type: 'FETCH_IMAGE_PENDING' }
    | { type: 'FETCH_IMAGE_FULFILLED'; payload: string | undefined }
    | { type: 'FETCH_IMAGE_REJECTED' };

interface ContextValue extends UserState {
    fetchUserDetails: () => Promise<void>
    fetchUserImage: () => Promise<void>;
}

const UserContext = createContext<ContextValue | undefined>(undefined);

type UserProviderProps = { children: React.ReactNode };

const initialState: UserState = {
    details: {
        displayName: "",
        givenName: "",
        surname: "",
        mail: "",
        id: "",
    },
    status: "idle",
    image: ""
};

const reducer = (state: UserState, action: Action): UserState => {
    switch (action.type) {
        case 'FETCH_DATA_PENDING':
            return { ...state, status: 'loading' };
        case 'FETCH_DATA_FULFILLED':
            return { ...state, status: 'idle', details: action.payload };
        case 'FETCH_DATA_REJECTED':
            return { ...state, status: 'failed' };
        case 'FETCH_IMAGE_PENDING':
            return { ...state, status: 'loading' };
        case 'FETCH_IMAGE_FULFILLED':
            return { ...state, status: 'idle', image: action.payload };
        case 'FETCH_IMAGE_REJECTED':
            return { ...state, status: 'failed' };
        default:
            return state;
    }
};

function UserProvider({ children }: UserProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { instance, accounts } = useMsal();

    const fetchUserDetails = async () => {
        dispatch({ type: 'FETCH_DATA_PENDING' });
        try {
            const accessToken = await getAuthAccessToken(instance, accounts[0]);
            const response = await getUserDetails(accessToken);
            const data = await response.data;
            dispatch({ type: 'FETCH_DATA_FULFILLED', payload: data });
        } catch (error) {
            dispatch({ type: 'FETCH_DATA_REJECTED' });
        }
    };

    const fetchUserImage = async () => {
        dispatch({ type: 'FETCH_IMAGE_PENDING' });
        try {
            const accessToken = await getAuthAccessToken(instance, accounts[0]);
            const response = await getUserImage(accessToken);
            const data = await response?.data;
            dispatch({ type: 'FETCH_IMAGE_FULFILLED', payload: data });
        } catch (error) {
            dispatch({ type: 'FETCH_IMAGE_REJECTED' });
        }
    };

    return (
        <UserContext.Provider value={{ ...state, fetchUserDetails, fetchUserImage }}>
            {children}
        </UserContext.Provider>
    );
};

const useUserContext = (): ContextValue => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};

export { useUserContext, UserProvider };