import useStyles from "./styles";
import { Flex, Progress } from "@mantine/core";
import PNG_Icon from "../../../assets/icons/PNG.png";
import JPG_Icon from "../../../assets/icons/JPG.png";
import GIF_Icon from "../../../assets/icons/GIF.png";
import MP3_Icon from "../../../assets/icons/MP3.png";
import PDF_Icon from "../../../assets/icons/PDF.png";
import XLS_Icon from "../../../assets/icons/XLS.png";
import CSV_Icon from "../../../assets/icons/CSV.png";
import PPTX_Icon from "../../../assets/icons/PPTX.png";
import DOCX_Icon from "../../../assets/icons/DOCX.png";
import moment from "moment";
import { IconCross, IconDownload, IconTrash, IconX } from "@tabler/icons-react";
import Typography from "../../common/Typography";
import { TypographyTypes } from "../../../utils/enums";
import {
  IAttachemntComponentProps,
  IAttachemntProps,
  IUploadingAttachemntProps,
} from "../../../features/AttachmentSection/type";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function UploadingAttachment({
  title,
  type,
  size,
  index,
  handleRemove,
  completeUpload,
  uploadCompleted,
}: IUploadingAttachemntProps & IAttachemntComponentProps) {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 100) {
          completeUpload(index);
          clearInterval(interval);
          return prevProgress;
        }
        return prevProgress + 10;
      });
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getIcon = () => {
    switch (type) {
      case "png":
        return PNG_Icon;
      case "jpeg":
      case "jpg":
        return JPG_Icon;
      case "mp3":
        return MP3_Icon;
      case "pdf":
        return PDF_Icon;
      case "gif":
        return GIF_Icon;
      case "xls":
      case "xlsx":
      case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return XLS_Icon;
      case "csv":
        return CSV_Icon;
      case "pptx":
      case "vnd.openxmlformats-officedocument.presentationml.presentation":
        return PPTX_Icon;
      case "docx":
      case "msword":
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
        return DOCX_Icon;
      default:
        break;
    }
  };

  return (
    <table className={classes.attachmentMain}>
      <tbody>
        <tr>
          <td className={classes.imageTd}>
            <img src={getIcon()} />
          </td>
          <td className={classes.titleTd}>
            <div>
              <Typography
                text={title}
                type={TypographyTypes.defaultBold}
                color="#324054"
              />
            </div>
            <div>
              {!uploadCompleted && progress < 100 ? (
                <>
                  <Progress
                    value={progress}
                    size="sm"
                    radius="xl"
                    color="green"
                    animate
                  />
                  <Typography
                    text={`${progress}% ${t("completed")}`}
                    type={TypographyTypes.default}
                    color="grey"
                  />
                </>
              ) : null}
              <Typography
                text={t("file_ready")}
                type={TypographyTypes.default}
                color="grey"
              />
            </div>
          </td>
          <td className={classes.iconTd}>
            <Flex gap={7} justify={"right"}>
              {uploadCompleted || progress == 100 ? (
                <IconX
                  color="grey"
                  size={"1.1rem"}
                  onClick={() => handleRemove(index)}
                />
              ) : null}
            </Flex>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
