import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  checkboxChildrenBody: { marginLeft: 15, marginTop: 10 },
  notFoundText: {
    fontSize: 13,
    marginTop: 5,
    display: "flex",
    justifyContent: "center",
    color: "#989898",
  },
  flexView: {
    display: "flex",
    flexDirection: "row",
  },
  moveLeft: {
    marginLeft: 28,
  },
}));

export default useStyles;
