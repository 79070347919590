import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";
import { IAssignByUser } from "../../components/Filters/assignByFilter/types";

export interface IAssignByFilterAPIParams {
  containerIds: string[];
  search: string;
}

export const getAssignByUsersList = (
  requestBody: IAssignByFilterAPIParams,
  token: string
) => {
  const bearer = `Bearer ${token}`;
  return axios<IAssignByUser[]>({
    method: "POST",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/filters/item-assigned-by-users`,
    data: requestBody,
    headers: { authorization: bearer },
  });
};
