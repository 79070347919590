import { createStyles } from "@mantine/core";

const useProjectFilterStyles = createStyles((theme) => ({
  prjectDropdownBody: {
    paddingTop: 10,
    paddingBottom: 10,
    maxHeight: "5rem",
    overflow: "scroll",
  },
}));

export default useProjectFilterStyles;
