import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  mainHeading: {
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "48px",
    color: "#1C1C1C",
    fontFamily: "Roboto, sans-serif",
  },
  title: {
    fontWeight: 600,
  },

  default: {
    fontFamily: theme.fontFamily,
    lineHeight: "24px",
  },

  underlined: {
    textDecoration: "underline",
  },

  bold: {
    fontWeight: "bold",
  },

  tools: {
    background: theme.colors.gray[2],
    borderRadius: "5px",
    textAlign: "center",
    marginBottom: "5px",
  },
  label: {
    fontFamily: theme.fontFamily,
    fontSize: "14px",
  },
}));

export default useStyles;
