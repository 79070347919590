import { Avatar, Text } from "@mantine/core";
import { useUserContext } from "../../../contexts/user.context";
import moment from "moment";
import useStyles from "./styles";
import formatDateTime from "../../../utils/functions/formatDate";
import { markNotificationAsRead } from "../../../services/notificationService/notificationService";
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../../../services/authService";
import { useNotificationsContext } from "../../../contexts/notifications.context";
import { DATE_TIME_FORMAT } from "../../../utils/constants/dateFormat";

const InboxMsg = ({ data, onClickItem }: any) => {
  const { image } =
    useUserContext();
  const { classes } = useStyles();
  const { instance, accounts } = useMsal();
  const { notificationCount, notifications } = useNotificationsContext();

  const markAsRead = async (key: any) => {
    const accessToken = await getAccessToken(instance, accounts[0]);
    const response = await markNotificationAsRead(key, accessToken);
    notificationCount();
  }

  return (
    <div>
      {data.map((item: any) => {
        if (item?.typeId == 4)
          // for mention notifications
          return (
            <>
              <div className={classes.mainDiv}>
                <div className={classes.subDiv}>
                  <div className={classes.textDiv}>
                    <div className={classes.avatarDiv}>
                      {!item.isSeen && (
                        <div className={classes.unreadDiv}></div>
                      )}
                      <Avatar color="blue" radius="xl" src={image}>
                        {item.user.firstName.slice(0, 1) +
                          item.user.lastName.slice(0, 1)}
                      </Avatar>
                    </div>
                    <Text style={{ fontWeight: "bold" }}>
                      {`${item.user.firstName} ${item.user.lastName}`}
                    </Text>
                    <Text style={{ marginLeft: 5 }}>
                      {`has mentioned you in`}
                    </Text>
                    <Text
                      className={classes.titleText}
                      onClick={() => {
                        onClickItem(item.itemKey);
                        markAsRead(item.id);
                        item.isSeen = true;
                      }}
                      onContextMenu={() => {
                        window.open(`/item/edit/${item.itemKey}`, "_blank");
                        markAsRead(item.id);
                        item.isSeen = true;
                      }}
                    >
                      {`${item.itemKey} : ${item.itemTitle}`}
                    </Text>
                  </div>
                  <div className={classes.timeDiv}>
                    <Text style={{ color: "#A5ACB8" }}>
                      {/* {`${moment(
                        item.createdOn,
                        "YYYY-MM-DDTHH:mm:ss.0Z"
                      ).calendar()}`} */}
                      {
                        item.createdOn[item.createdOn.length - 1] == "Z"
                          ? moment(item.createdOn).format(DATE_TIME_FORMAT)
                          : moment(item.createdOn).format(DATE_TIME_FORMAT)
                      }
                    </Text>
                  </div>
                </div>
                <div className={classes.commentMainDiv}>
                  <div className={classes.commentSubDiv}></div>
                  <Text style={{ fontWeight: "bold" }}>
                    {`"${item.mention.mentionComment}"`}
                  </Text>
                </div>
              </div>
            </>
          );
        // for assign notifications
        else if (item?.typeId == 1)
          return (
            <>
              <div className={classes.mainDiv}>
                <div className={classes.subDiv}>
                  <div
                    className={classes.textDiv}
                    style={{ alignItems: "center" }}
                  >
                    <div className={classes.avatarDiv}>
                      {!item.isSeen && (
                        <div className={classes.unreadDiv}></div>
                      )}
                      <Avatar color="blue" radius="xl" src={image}>
                        {item.user.firstName.slice(0, 1) +
                          item.user.lastName.slice(0, 1)}
                      </Avatar>
                    </div>
                    <Text style={{ fontWeight: "bold" }}>
                      {`${item.user.firstName} ${item.user.lastName}`}
                    </Text>
                    <Text style={{ marginLeft: 5 }}>
                      {`has assigned you to`}
                    </Text>
                    <Text
                      className={classes.titleText}
                      onClick={() => {
                        onClickItem(item.itemKey);
                        markAsRead(item.id);
                        item.isSeen = true;
                      }}
                      onContextMenu={() => {
                        window.open(`/item/edit/${item.itemKey}`, "_blank");
                        markAsRead(item.id);
                        item.isSeen = true;
                      }}
                    >
                      {`${item.itemKey} : ${item.itemTitle}`}
                    </Text>
                  </div>
                  <div className={classes.timeDiv}>
                    <Text style={{ color: "#A5ACB8" }}>
                      {/* {`${moment(
                        item.createdOn,
                        "YYYY-MM-DDTHH:mm:ss.0Z"
                      ).calendar()}`} */}
                      {
                        item.createdOn[item.createdOn.length - 1] == "Z"
                        ? moment(item.createdOn).format(DATE_TIME_FORMAT)
                        : moment(item.createdOn).format(DATE_TIME_FORMAT)
                      }
                    </Text>
                  </div>
                </div>
              </div>
            </>
          );
      })}
    </div>
  );
};

export default InboxMsg;
