/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Container, Text } from "@mantine/core";
import useStyles from "./style";
import RaidItemTable from "../../components/RaidItemTable";
import WidgetPanel from "../WidgetPanel";
import { useEffect, useRef, useState } from "react";
import {
  FilterBody,
  FilterBodyFilters,
  ListCallOrigin,
  RaidType,
  RaidViewProps,
  TableMetaData,
  WidgetCount,
} from "./type";
import { IPeriodFilter, useFilterContext } from "../../contexts/filter.context";
import {
  getRAIDItems,
  getWidgetCount,
} from "../../services/filterService/raidItems";
import {
  IRaidList,
  useRaidListContext,
} from "../../contexts/raidItems.context";
import { MRT_SortingState } from "mantine-react-table";
import { PeriodEnum } from "../../components/Filters/PeriodFilter";
import { useMsal } from "@azure/msal-react";
import envVariables from "../../utils/config.json";
import { useRefreshRaidListContext } from "../../contexts/listViewRefresh.context";

const RaidView = ({
  dashboardName,
  onClickItem,
  rowSelection,
  setRowSelection,
  onClickShowMore,
  setOnClickShowMore,
  searchText,
  onClickSearch,
}: RaidViewProps) => {
  const { instance, accounts } = useMsal();
  const firstRender = useRef(true);

  const { state: filterState } = useFilterContext();
  const { dispatch: raidItemDispatch, state: raidItemState } =
    useRaidListContext();
  const { dispatch: refreshItemsDispatch, state: refreshItemsState } =
    useRefreshRaidListContext();

  const { classes } = useStyles();

  const [raidType, setRaidType] = useState<RaidType>({ key: 0, value: "All" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (refreshItemsState && refreshItemsState.raidItemRefreshList) {
      const prevStateList = refreshItemsState.raidItemRefreshList;
      const dashboardRaidList = prevStateList.filter(
        (dashboardItem) => dashboardItem.dashboard === dashboardName
      );

      if (dashboardRaidList?.length > 0 && dashboardRaidList[0].refresh) {
        refreshContAndItems();
        refreshItemsDispatch({
          type: "setRaidListRefresh",
          payload: { dashboard: dashboardName, refresh: false },
        });
      }
    }
  }, [refreshItemsState]);

  useEffect(() => {
    localStorage.setItem("raidType", JSON.stringify(raidType));
  }, [raidType]);

  const [tableMetaData, setTableMetaData] = useState<TableMetaData>({
    currentPage: -1,
    totalPages: -1,
    pageSize: -1,
    totalCount: 0,
    hasPrevious: false,
    hasNext: false,
    items: [],
  });
  const [filterObject, setFilterObject] = useState<FilterBodyFilters>();
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [countData, setCountData] = useState([0, 0, 0, 0, 0]);
  var tempCountData: number[] = [0, 0, 0, 0, 0];
  var totalCount = 0;
  const [raidListLoading, setRaidListLoading] = useState(false);

  useEffect(() => {
    const ownerFilter = filterState?.ownerFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const projectFilter = filterState?.projectFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const projectRefacoterdFilter = filterState?.projectRefactoredFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const periodFilter = filterState?.periodFilter?.find(
      (e) => e.dashboard === dashboardName
    );

    const tagFilter = filterState?.tagFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const statusFilter = filterState?.statusFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const assignedByFilter = filterState?.assignedByFilter?.find(
      (e) => e.dashboard === dashboardName
    );

    setFilterObject({
      containers: projectFilter?.selectedValues.map((e) => +e) || [],
      targetDate: periodFilter ? getTargetDateRange(periodFilter) : null,
      owners: ownerFilter?.selectedValues.map((e) => +e) || [],
      status: statusFilter?.selectedValues.map((e) => +e) || [],
      labels: tagFilter?.selectedValues.map((e) => +e) || [],
      assignedByUsers: assignedByFilter?.selectedValues.map((e) => +e) || [],
    });
  }, [filterState]);

  const getTargetDateRange = (
    periodFilter: IPeriodFilter
  ): { startDate: string; endDate: string } | null => {
    switch (periodFilter.type) {
      case PeriodEnum[PeriodEnum.All_Time]:
        return null;
      case PeriodEnum[PeriodEnum.Today]:
        return {
          startDate: new Date().toISOString(),
          endDate: new Date().toISOString(),
        };
      case PeriodEnum[PeriodEnum.This_Week]:
        return {
          startDate: getFirstDayOfWeek(new Date()).toISOString(),
          endDate: new Date().toISOString(),
        };
      case PeriodEnum[PeriodEnum.This_Month]:
        const date = new Date();
        return {
          startDate: new Date(
            date.getFullYear(),
            date.getMonth(),
            1
          ).toISOString(),
          endDate: new Date().toISOString(),
        };
      case PeriodEnum[PeriodEnum.Custom]:
        const [start, end] = periodFilter.selectedValues.split("-");
        return {
          startDate: new Date(start).toISOString(),
          endDate: new Date(end).toISOString(),
        };
      default: {
        return null;
      }
    }
  };

  function getFirstDayOfWeek(d: Date) {
    const date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  }

  const [prevState, setPrevState] = useState<any>();
  useEffect(() => {
    if (filterObject && filterObject.containers.length > 0) {
      if (JSON.stringify(prevState) != JSON.stringify(filterObject)) {
        getRaidItems(ListCallOrigin.FILTER);
        getCount();
      }
    } else {
      setTableMetaData({
        currentPage: -1,
        totalPages: -1,
        pageSize: -1,
        totalCount: 0,
        hasPrevious: false,
        hasNext: false,
        items: [],
      });
      setCountData([0, 0, 0, 0, 0]);
    }
    setPrevState(filterObject);
  }, [filterObject]);

  useEffect(() => {
    if (!firstRender.current) getRaidItems(ListCallOrigin.TYPE);
  }, [raidType]);

  useEffect(() => {
    if (!firstRender.current) getRaidItems(ListCallOrigin.SORT);
  }, [sorting]);

  useEffect(() => {
    if (!firstRender.current) getRaidItems(ListCallOrigin.FILTER);
    getCount();
  }, [onClickSearch]);

  useEffect(() => {
    var dispatchPayload: IRaidList = {
      dashboard: "",
      raidList: [],
    };
    dispatchPayload.dashboard = dashboardName;
    dispatchPayload.raidList = tableMetaData.items.map((e) =>
      e.code.toString()
    );
    raidItemDispatch({ type: "setRaidList", payload: dispatchPayload });
    setOnClickShowMore(onClickShowMore + 1);
  }, [tableMetaData.items]);

  const setLodging = (setValue: boolean, origin: ListCallOrigin) => {
    setIsLoading(setValue);
  };

  async function getRaidItems(origin: ListCallOrigin) {
    const filterBody = createPayload(origin);
    setRaidListLoading(true);
    try {
      setLodging(true, origin);
      await getRAIDItems(filterBody, instance, accounts)
        .then((data) => {
          const resData = data.data;
          if (origin === ListCallOrigin.SEE_MORE) {
            setTableMetaData((prevState: TableMetaData) => ({
              currentPage: resData.currentPage,
              totalPages: resData.totalPages,
              pageSize: resData.pageSize,
              totalCount: resData.totalCount,
              hasPrevious: resData.hasPrevious,
              hasNext: resData.hasNext,
              items: [...prevState.items, ...data.data.items],
            }));
          } else {
            setTableMetaData({
              currentPage: resData.currentPage,
              totalPages: resData.totalPages,
              pageSize: resData.pageSize,
              totalCount: resData.totalCount,
              hasPrevious: resData.hasPrevious,
              hasNext: resData.hasNext,
              items: resData.items.map((e: any) => {
                // if assignedDate is null, then set it to empty string
                return {
                  ...e,
                  assignedDate: e.assignedDate ? e.assignedDate : "",
                };
              }),
            });
          }
          firstRender.current = false;
          setLodging(false, origin);
        })
        .catch((error) => {
          firstRender.current = false;
          setLodging(false, origin);
          console.log(error?.message);
        });
    } catch (error) {
      setIsLoading(false);
      firstRender.current = false;
    }
    setRaidListLoading(false);
  }

  async function getCount() {
    const filterBody = createPayloadForCount();

    try {
      await getWidgetCount(filterBody, instance, accounts)
        .then((data) => {
          var resData = data.data;
          if (resData.length > 0) {
            resData.forEach((element: any) => {
              if (element?.itemType === "Risk") {
                tempCountData[1] = element.count;
                totalCount += element.count;
                tempCountData[0] = totalCount;
              } else if (element?.itemType === "Action Item") {
                tempCountData[2] = element.count;
                totalCount += element.count;
                tempCountData[0] = totalCount;
              } else if (element?.itemType === "Issue") {
                tempCountData[3] = element.count;
                totalCount += element.count;
                tempCountData[0] = totalCount;
              } else {
                tempCountData[4] = element.count;
                totalCount += element.count;
                tempCountData[0] = totalCount;
              }
            });
            setCountData(tempCountData);
          } else {
            setCountData([0, 0, 0, 0, 0]);
          }
        })
        .catch((error) => {
          console.log(error?.message);
        });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  const onShowMoreClick = () => {
    getRaidItems(ListCallOrigin.SEE_MORE);
  };

  const createPayload = (origin: ListCallOrigin): FilterBody => {
    var filter = {
      pageNumber: -1,
      searchText: searchText,
      skip: 0,
      pageSize: -1,
      itemType: raidType.key === 0 ? null : raidType.key,
      targetDate:
        filterObject?.targetDate?.startDate && filterObject?.targetDate?.endDate
          ? {
              startDate: filterObject?.targetDate?.startDate,
              endDate: filterObject?.targetDate?.endDate,
            }
          : null,
      containers:
        filterObject?.containers && filterObject?.containers?.length > 0
          ? filterObject?.containers
          : null,
      owners:
        filterObject?.owners && filterObject?.owners?.length > 0
          ? filterObject?.owners
          : null,
      status:
        filterObject?.status && filterObject?.status?.length > 0
          ? filterObject?.status
          : null,
      labels:
        filterObject?.labels && filterObject?.labels?.length > 0
          ? filterObject?.labels
          : null,
      assignedByUsers:
        filterObject?.assignedByUsers &&
        filterObject?.assignedByUsers?.length > 0
          ? filterObject?.assignedByUsers
          : null,
      sortBy: sorting[0]?.id ?? null,
      sortOrder: sorting[0] ? (sorting[0]?.desc ? "desc" : "asc") : null,
    };

    switch (origin) {
      case ListCallOrigin.TYPE: {
        filter = {
          ...filter,
          pageNumber: 1,
          searchText: searchText,
          pageSize: envVariables.REACT_APP_TABLE_PAGE_SIZE,
        };
        break;
      }
      case ListCallOrigin.FILTER: {
        filter = {
          ...filter,
          pageNumber: 1,
          searchText: searchText,
          pageSize: envVariables.REACT_APP_TABLE_PAGE_SIZE,
        };
        break;
      }
      case ListCallOrigin.SEE_MORE: {
        filter = {
          ...filter,
          pageNumber: tableMetaData.currentPage + 1,
          searchText: searchText,
          pageSize: envVariables.REACT_APP_TABLE_PAGE_SIZE,
        };
        break;
      }
      case ListCallOrigin.SORT: {
        filter = {
          ...filter,
          pageNumber: 1,
          searchText: searchText,
          pageSize: tableMetaData.pageSize * tableMetaData.currentPage,
        };
        break;
      }
      case ListCallOrigin.REFRESH: {
        filter = {
          ...filter,
          pageNumber: 1,
          searchText: searchText,
          pageSize: tableMetaData.pageSize * tableMetaData.currentPage,
        };
        break;
      }
    }
    return filter;
  };

  const createPayloadForCount = (): WidgetCount => {
    var filter = {
      searchText: searchText,
      targetDate:
        filterObject?.targetDate?.startDate && filterObject?.targetDate?.endDate
          ? {
              startDate: filterObject?.targetDate?.startDate,
              endDate: filterObject?.targetDate?.endDate,
            }
          : null,
      containers:
        filterObject?.containers && filterObject?.containers?.length > 0
          ? filterObject?.containers
          : null,
      owners:
        filterObject?.owners && filterObject?.owners?.length > 0
          ? filterObject?.owners
          : null,
      status:
        filterObject?.status && filterObject?.status?.length > 0
          ? filterObject?.status
          : null,
      labels:
        filterObject?.labels && filterObject?.labels?.length > 0
          ? filterObject?.labels
          : null,
    };
    return filter;
  };

  const refreshContAndItems = () => {
    getCount();
    getRaidItems(ListCallOrigin.REFRESH);
  };

  return (
    <>
      <div id="widget-section">
        <WidgetPanel countData={countData} setTitle={setRaidType} />
      </div>
      <Container className={classes.raidItemContainer} mt={10}>
        <div id="title-section">
          {/* <Text className={classes.title}>{raidType.value}</Text> */}
        </div>
        <RaidItemTable
          dashboardName={dashboardName}
          tableMetaData={tableMetaData}
          onShowMoreClick={onShowMoreClick}
          sorting={sorting}
          setSorting={setSorting}
          isLoading={isLoading}
          onClickItem={onClickItem}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          raidListLoading={raidListLoading}
        />
      </Container>
    </>
  );
};

export default RaidView;
