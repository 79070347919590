import { Flex, Loader } from "@mantine/core";
import useOverlayStyles from "./style";

const AppLoadingOverlay = () => {
    const { classes } = useOverlayStyles();
    return (
        <div>
            <Flex
                justify={"center"}
                align={"center"}
                className={classes.overlayBackdrop}
            >
                <Loader size="md" />
            </Flex>
        </div>
    );
};

export default AppLoadingOverlay;