import { createStyles } from "@mantine/core";

const LabelItemStyles = createStyles((theme) => ({
  labelItem: {
    fontSize: 11,
    fontWeight: 400,
    backgroundColor: "#dad6d6",
    color: "#000000",
    height: 18,
    paddingTop: 0,
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 2.5,
    marginRight: 2.5,
    textTransform: "none",
    borderRadius: 50,
  },
}));

export default LabelItemStyles;