import { useMsal } from '@azure/msal-react';
import { Box, Button } from '@mantine/core';

const msg = (accountEmail: string) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <h1>Forbidden</h1>
      <p>
        You do not have permission to view this page. Please contact your administrator.
      </p>
      <p>
        <strong>Account: </strong>
        {accountEmail}
      </p>
    </Box>
  )
}

function Forbidden() {
  const { instance, accounts } = useMsal();

  const logout = () => {
    try {
      // clear local storage
      localStorage.clear();

      // clear session storage
      sessionStorage.clear();

      // if browser is firefox, just logout()  
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        instance.logout({
          account: instance.getActiveAccount(),
        });
        return;
      }

      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Box sx={{ height: '100vh' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        {msg(accounts[0].username)}
        <br />
        <Button
          onClick={() => logout()}
        >
          Logout
        </Button>
      </Box>
    </Box>
  )
}

export default Forbidden
