import { Flex } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  IconAlertCircleFilled,
  IconCircleDotFilled,
  IconCircleXFilled,
} from "@tabler/icons-react";
import {
  IconCircleCheckFilled,
  IconInfoCircleFilled,
} from "@tabler/icons-react";

export enum ToastSensitivity {
  SUCCESS = "SUCCESS",
  DANGER = "DANGER",
  INFO = "INFO",
  WARNING = "WARNING",
}

function ShowToastMsg(message: string, sensitivity: ToastSensitivity, duration: number = 5000) {
  const COLOR_OBJ = {
    SUCCESS: {
      mainColor: "#52C41A",
      backgroundColor: "rgba(246, 255, 237, 1)",
    },
    DANGER: {
      mainColor: "#FF4D4F",
      backgroundColor: "rgba(246, 255, 237, 1)",
    },
    INFO: {
      mainColor: "#1890FF",
      backgroundColor: "rgba(230, 247, 255, 1)",
    },
    WARNING: {
      mainColor: "#FAAD14",
      backgroundColor: "rgba(246, 255, 237, 1)",
    },
  };

  const getIcon = () => {
    const divStyle = { color: COLOR_OBJ[ToastSensitivity[sensitivity]].mainColor, lineHeight: 1 }
    switch (sensitivity) {
      case ToastSensitivity.SUCCESS: {
        return (
          <div style={divStyle}>
            <IconCircleCheckFilled />
          </div>
        );
      }
      case ToastSensitivity.DANGER: {
        return (
          <div style={divStyle}>
            <IconCircleXFilled />
          </div>
        );
      }
      case ToastSensitivity.INFO: {
        return (
          <div style={divStyle}>
            <IconInfoCircleFilled />
          </div>
        );
      }
      case ToastSensitivity.WARNING: {
        return (
          <div style={divStyle}>
            <IconAlertCircleFilled />
          </div>
        );
      }
      default:
        return <IconCircleDotFilled />;
    }
  };

  notifications.show({
    message: (
      <Flex direction={"row"} justify={"flex-start"} align={"center"} gap={5}>
        {getIcon()} {message.toString()}
      </Flex>
    ),
    styles: (theme) => ({
      root: {
        backgroundColor:
          COLOR_OBJ[ToastSensitivity[sensitivity]].backgroundColor,
        border: `1px solid ${COLOR_OBJ[ToastSensitivity[sensitivity]].mainColor}`,

        "&::before": {
          backgroundColor:
            COLOR_OBJ[ToastSensitivity[sensitivity]].backgroundColor,
        },
      },
      description: { color: "#000000" },
      closeButton: {
        color: "rgba(0, 0, 0, 0.45)",
        "&:hover": { backgroundColor: theme.colors.blue[7] },
      },
    }),
  });
  
}

export default ShowToastMsg;
