import { useContext } from "react";
import { WhiteLabelContext } from "../../contexts/whiteLabelContext";

function useBranding() {
  const context = useContext(WhiteLabelContext);
  if (context === null) {
    throw new Error("useBranding must be used within a WhiteLabelProvider");
  }
  return context;
}

export { useBranding };
