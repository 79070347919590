import { IconTag } from "@tabler/icons-react";
import FilterPopover from "../../common/FilterPopover";
import { Divider, Flex, Loader } from "@mantine/core";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ProjectListProps } from "../ProjectFilter";
import FilterButtonPanel from "../../common/FilterButtonPanel";
import CheckboxList from "../../common/CheckboxList";
import Search from "../../common/Search";
import { getHotkeyHandler } from "@mantine/hooks";
import { TagFilterAPIItem, TagFilterProps } from "./types";
import { useTranslation } from "react-i18next";
import { useDebouncedValue } from "@mantine/hooks";
import { customSort } from "../../../utils/functions/project";

const TagsFilter = ({
  tagList,
  getTagFilters,
  checked,
  setChecked,
  handleSubmit,
  handleClear,
  isLoading,
  disabled,
  selectedContainerIds,
  handleSelectAll,
  checkedSelectAll,
  setCheckedSelectAll,
  allKeys,
  isRefetching,
}: TagFilterProps) => {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 500);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [checkedFromFilter, setCheckedFromFilter] = useState<string[]>([]);
  const [publicTagsOptions, setPublicTagsOptions] = useState<
    Array<ProjectListProps>
  >([]);
  const [privateTagsOptions, setPrivateTagsOptions] = useState<
    Array<ProjectListProps>
  >([]);

  useEffect(() => {
    const privateTags = getPublicOrPrivateTags(tagList, false);
    const publicTags = getPublicOrPrivateTags(tagList, true);

    const privateTagsOptions = setTagOption(privateTags);
    const publicTagsOptions = setTagOption(publicTags);

    setPublicTagsOptions(publicTagsOptions);
    setPrivateTagsOptions(privateTagsOptions);
  }, [tagList]);

  const setTagOption = (tags: Array<TagFilterAPIItem>) => {
    return tags.map(({ value, isSelected, key }) => {
      return {
        label: value,
        value: key,
        isSelected: isSelected,
      };
    });
  };

  const getPublicOrPrivateTags = (
    tags: Array<TagFilterAPIItem>,
    isPublic: boolean = true
  ) => {
    return tags.filter(({ isShared }) => isShared === isPublic);
  };

  useEffect(() => {
    if (!modalOpen) setIsActive(checked.length > 0);
    if (checked.length != checkedFromFilter.length) {
      setCheckedFromFilter(checked);
    }
  }, [checked]);

  useEffect(() => {
    var selectedValues = checkedFromFilter
      .map((item) => String(item))
      .filter((value, index, self) => self.indexOf(value) === index);
    setChecked(selectedValues as [string]);
  }, [checkedFromFilter]);

  useEffect(() => {
    getTagFilters({
      containerIds: selectedContainerIds,
      search: debouncedSearchString,
    });
  }, [debouncedSearchString]);

  useEffect(() => {
    if (isRefetching) {
      getTagFilters({
        containerIds: selectedContainerIds,
        search: debouncedSearchString,
      });
    }
  }, [isRefetching]);

  const onHandleSearchChange = (e: any) => {
    setSearchString(e.target.value);
  };

  function handleSearchSubmit() {
    getTagFilters({ containerIds: selectedContainerIds, search: searchString });
  }

  function handleSearchClear(): void {
    setSearchString("");
    getTagFilters({ containerIds: selectedContainerIds, search: "" });
  }

  const handleCheck = (checkedValues: string[], key: string) => {
    const order = [...checked, `${key}`];
    const selectedValues = customSort(checkedValues, order);
    setChecked(selectedValues as [string]);
  };

  return (
    <div>
      <FilterPopover
        children={
          <Flex direction="column">
            <Search
              value={searchString}
              onChange={(e) => onHandleSearchChange(e)}
              onKeyDown={getHotkeyHandler([
                [
                  "Enter",
                  () => {
                    handleSearchSubmit();
                  },
                ],
              ])}
              onClear={handleSearchClear}
            />
            {isLoading ? (
              <Flex direction="column" align="center" style={{ padding: 10 }}>
                <Loader variant="dots" />
              </Flex>
            ) : (
              <>
                {publicTagsOptions.length > 0 && (
                  <>
                    <Divider
                      label="Public"
                      labelPosition="left"
                      style={{ padding: 8 }}
                    />
                    <CheckboxList
                      nodes={publicTagsOptions}
                      checked={checked}
                      onCheck={(checkedValues, node) => {
                        if (node.checked) {
                          setCheckedFromFilter([
                            ...checked,
                            ...checkedFromFilter,
                            node.value,
                          ]);
                        } else {
                          const updatedList = checked.filter(
                            (e) => e != node.value
                          );
                          setCheckedFromFilter([...updatedList]);
                        }
                      }}
                      isMultilevel={false}
                      expanded={[]}
                    />
                  </>
                )}
                {privateTagsOptions.length > 0 && (
                  <>
                    <Divider
                      label="Private"
                      labelPosition="left"
                      style={{ padding: 8 }}
                    />
                    <CheckboxList
                      nodes={privateTagsOptions}
                      checked={checked}
                      onCheck={(checkedValues, node) => {
                        if (node.checked) {
                          setCheckedFromFilter([
                            ...checked,
                            ...checkedFromFilter,
                            node.value,
                          ]);
                        } else {
                          const updatedList = checked.filter(
                            (e) => e != node.value
                          );
                          setCheckedFromFilter([...updatedList]);
                        }
                      }}
                      isMultilevel={false}
                      expanded={[]}
                    />
                  </>
                )}
                <FilterButtonPanel
                  onSubmit={() => {
                    setIsActive(checked.length > 0);
                    handleSubmit();

                    if (checkedSelectAll) {
                      handleSelectAll();
                    }
                    handleSearchClear();
                    setModalOpen(false);
                  }}
                  onClear={() => {
                    setIsActive(false);
                    handleSearchClear();
                    setCheckedFromFilter([]);
                    handleClear();
                    setModalOpen(false);
                  }}
                  checked={checked}
                  enableSelectAll={true}
                  checkedSelectAll={checkedSelectAll}
                  setCheckedSelectAll={setCheckedSelectAll}
                  allKeys={allKeys}
                  setSelected={setChecked as Dispatch<SetStateAction<string[]>>}
                />
              </>
            )}
          </Flex>
        }
        title={t("Tags")}
        isActive={isActive}
        icon={<IconTag size={18} />}
        opened={modalOpen}
        setOpened={setModalOpen}
        closeOnClickOutside={true}
        disabled={disabled}
      ></FilterPopover>
    </div>
  );
};

export default TagsFilter;
