import React from "react";
import "./App.css";
import Layout from "./layouts/Layout";
import MultiContextProvider from "./contexts/app.context.provider";
import { BrowserRouter } from "react-router-dom";
import { Notifications } from "@mantine/notifications";

function App() {
  return (
    <React.StrictMode>
      <MultiContextProvider>
        <Notifications position="top-right" />
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </MultiContextProvider>
    </React.StrictMode>
  );
}

export default App;
