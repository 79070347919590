import React, { createContext, useContext, useReducer } from "react";
import brandingInit from "../whiteLabeling/branding.json";
import { WhiteLabelAction, WhiteLabelState } from "../whiteLabeling/types";

const WhiteLabelContext = createContext<{
  state: WhiteLabelState;
  dispatch: React.Dispatch<WhiteLabelAction>;
} | null>(null);

function whiteLabelReducer(state: WhiteLabelState, action: WhiteLabelAction) {
  switch (action.type) {
    case "setBranding": {
      return state;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function WhiteLabelProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(whiteLabelReducer, brandingInit);
  const value = { state, dispatch };
  return (
    <WhiteLabelContext.Provider value={value}>
      {children}
    </WhiteLabelContext.Provider>
  );
}

export { WhiteLabelProvider, WhiteLabelContext };
