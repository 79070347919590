import { createContext, useContext, useReducer } from "react";
import {
  attachements,
  getAttachments,
} from "../services/attachments/attachments";
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../services/authService";
import { IAttachemntProps, MIMEType } from "../features/AttachmentSection/type";

function convertAttachments(attachments: attachements[]): IAttachemntProps[] {
  return attachments.map((attachment) => {
    const typeArray = attachment?.fileUrl.split(".");
    return {
      url: attachment.fileUrl,
      title: attachment.fileName,
      uploadedOn: attachment.createdOn,
      type: typeArray[typeArray.length - 1] as MIMEType,
    };
  });
}

interface AttachmentsState {
  status: "idle" | "loading" | "failed" | "authLoading";
  attachments: IAttachemntProps[];
}

type Action =
  | { type: "FETCH_ATTACHMENTS_PENDING" }
  | { type: "FETCH_ATTACHMENTS_FULFILLED"; payload: IAttachemntProps[] }
  | { type: "FETCH_ATTACHMENTS_REJECTED" };

interface ContextValue extends AttachmentsState {
  fetchAttachments: (itemId: string) => Promise<void>;
}

const AttachmentContext = createContext<ContextValue | undefined>(undefined);

type AttachmentProviderProps = { children: React.ReactNode };

const initialState: AttachmentsState = {
  attachments: [],
  status: "idle",
};

const reducer = (state: AttachmentsState, action: Action): AttachmentsState => {
  switch (action.type) {
    case "FETCH_ATTACHMENTS_PENDING":
      return { ...state, status: "loading" };
    case "FETCH_ATTACHMENTS_FULFILLED":
      return { ...state, status: "idle", attachments: action.payload };
    case "FETCH_ATTACHMENTS_REJECTED":
      return { ...state, status: "failed" };
    default:
      return state;
  }
};

function AttachmentProvider({ children }: AttachmentProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { instance, accounts } = useMsal();

  const fetchAttachments = async (itemId: string) => {
    dispatch({ type: "FETCH_ATTACHMENTS_PENDING" });
    try {
      const accessToken = await getAccessToken(instance, accounts[0]);
      const response = await getAttachments(itemId, accessToken);
      const data = convertAttachments(response?.data || []);
      dispatch({ type: "FETCH_ATTACHMENTS_FULFILLED", payload: data });
    } catch (error) {
      dispatch({ type: "FETCH_ATTACHMENTS_REJECTED" });
    }
  };

  return (
    <AttachmentContext.Provider value={{ ...state, fetchAttachments }}>
      {children}
    </AttachmentContext.Provider>
  );
}

const useAttachmentContext = (): ContextValue => {
  const context = useContext(AttachmentContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};

export { useAttachmentContext, AttachmentProvider };
