import CustomViewPage from "../../features/CustomViewPage/CustomViewPage";

const PrivateViewPage = () => {
  return (
    <CustomViewPage />
  );
};

export default PrivateViewPage;

