import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  dropzone: {
    border: `2px dashed ${theme.colors.blue[3]}`,
    color: theme.colors.blue[3],
    borderRadius: 15,
    padding: "5rem 0rem",
    textAlign: "center",
    marginTop: "1.5rem",
    cursor: "pointer",
    ":hover": {
      backgroundColor: theme.colors.blue[0],
    }
  }
}));

export default useStyles;
