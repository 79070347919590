import envVariables from "../../utils/config.json";
import axios from '../../axiosInstance';
import { StatusFilterAPIItem } from "../../components/Filters/StatusFilter/type";

export interface IStatusFilterAPIParams {
    containerIds: string[];
  }
  
  export const getStatusFilterList = (
    requestBody: IStatusFilterAPIParams,
    accessToken:any
    ) => {
      const bearer = `Bearer ${accessToken}`;

    return axios<StatusFilterAPIItem[]>({
      method: "POST",
      url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/filters/item-statuses`,
      data: requestBody,

      headers:{Authorization:bearer},
    });
  };
  
 
  