import { createStyles } from "@mantine/core";

const useFilterPanelStyles = createStyles((theme) => ({
  filterPanelWrapper: {
    boxShadow: "0px 4px 9px rgba(136, 136, 136, 0.2);",
    padding: 15,
  },
  filterPanelResultWrapper: {
    marginTop: 10,
    marginBottom: 5,
    height: "auto",
    width: "98%",
  },
  filterPanelResultMinimizedWrapper: {
    marginTop: 10,
    marginBottom: 5,
    width: "98%",
    height: 25,
    overflowY: 'hidden',
  },
  saveFilterButton: {
    height: 32
  },
  clearFilterButton: {
    height: 22,
    width: 76,
    fontSize: 12,
    borderRadius: 0,
    padding: 0,
    border: "1px solid #EA0707",
    color: "#EA0707"
  },
  minimizeFilterButton: {
    height: 22,
    minHeight: 22,
    width: 22,
    borderRadius: 0,
    padding: 0,
    border: "0px solid #3C91E6",
    color: "#3C91E6",
    alignContent: "end"
  },
  expandButtonWrapper: {
    flexWrap: "wrap",
    paddingTop: 8,
    paddingRight: 0,
    alignContent: "start",
    display: "flex",
    justifyContent: "end",
  },
  minimizeButtonWrapper: {
    flexWrap: "wrap",
    paddingBottom: 10,
    paddingRight: 0,
    alignContent: "end",
    display: "flex",
    justifyContent: "end",
  }
}));

export default useFilterPanelStyles;
