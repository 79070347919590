import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  drawer: {
    background: "#ffffff",
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    flexShrink: 0,
    transition: "right .5s cubic-bezier(0.820, 0.085, 0.395, 0.895)",
    overflow: "auto",
    zIndex: 100,
  },
  actionButtonContainer: {
    top: 0,
    borderRadius: 0,
    borderBottom: "2px solid #D6D6D6",
    height: 50,
    width: "100%",
    display: "flex",
    justifyContent: "right",
    background: "#ffffff",
    position: "sticky",
    zIndex: 1
  },
  actionButton: {
    border: 0,
    background: "transparent",
    cursor: "pointer",
  },
  dragger: {
    width: "10px",
    cursor: "ew-resize",
    padding: "4px 0 0",
    borderTop: "1px solid #ddd",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
    backgroundColor: "#f4f7f9",
    "&:hover": {
      backgroundColor: "#e8edf0",
    },
  },
  dragging: {
    userSelect: "none",
  },
}));

export default useStyles;
