import { createStyles } from "@mantine/core";

const useCommentFeatureStyles = createStyles((theme) => ({
  heading: {
    fontSize: 20,
    fontWeight: 600,
    color: "#000000",
    fontFamily: "Roboto, sans-serif",
    marginBottom: 10,
  },
}));

export default useCommentFeatureStyles
