import { createStyles } from "@mantine/core";

const useDatePickerStyle = createStyles((theme) => ({
    p0: {
        padding: 0
    },
    divider: {
        marginTop: 10, 
        marginBottom: 10
    },
    gridRoot: {
        maxWidth: 260, 
        margin: 0
    },
    text: {
        textAlign: "center",
        paddingTop: 5
    },
    button: {
        width: "100%",
        marginTop: 10,
        backgroundColor: "#3C91E6"
    },
    actionButton: {
        border: 0,
        background: "transparent",
        cursor: "pointer",
    },
}));

export default useDatePickerStyle;
