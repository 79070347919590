import react, { createContext, useContext, useState } from 'react';
import { MentionItem } from "react-mentions";

export interface IQuickItemView {
  commentBoxValue: string
  commentBoxMentions: MentionItem[]
  commentBoxPlainTextValue: string
  isCommentBoxEmpty: boolean
  isCommentBoxUpdated: boolean 
  isCommentsLoading: boolean
  isCommentsEdited: boolean
} 

export interface IQuickItemViewContext {
  qkItemViewState: IQuickItemView
  setQkItemViewState: react.Dispatch<react.SetStateAction<IQuickItemView>>
  setIsCommentBoxEmpty: (x:boolean) => void,
  setIsCommentBoxUpdated: (x:boolean) => void,
  setResetState: () => void
}

const initialValues: IQuickItemView = {
  commentBoxValue: '',
  commentBoxMentions: [],
  commentBoxPlainTextValue: '',
  isCommentBoxEmpty: true,
  isCommentsLoading: false,
  isCommentsEdited: false,
  isCommentBoxUpdated: false
}

const Context = createContext<IQuickItemViewContext>({
  qkItemViewState: initialValues,
  setQkItemViewState: () => null,
  setIsCommentBoxEmpty: (x:boolean) => null,
  setIsCommentBoxUpdated: (x:boolean) => null,
  setResetState: () => null
});

export const QuickItemViewContextProvider = ({children}: {children: React.ReactNode}) => {
  const [qkItemViewState, setQkItemViewState] = useState<IQuickItemView>({...initialValues})

  const setResetState = () => {
    setQkItemViewState({...initialValues})
  }

  const setIsCommentBoxEmpty = (x: boolean) => {
    setQkItemViewState((prev) => ({
      ...prev,
      isCommentBoxEmpty: x
    }))
  }

  const setIsCommentBoxUpdated = (x: boolean) => {
    setQkItemViewState((prev) => ({
      ...prev,
      isCommentBoxUpdated: x
    }))
  }

  return <Context.Provider value={{
    qkItemViewState,
    setQkItemViewState,
    setResetState,
    setIsCommentBoxEmpty,
    setIsCommentBoxUpdated
  }} >{children}</Context.Provider>
}

export const useQuickItemViewContext = () => {
  const ctx = useContext(Context);

  if(!ctx) throw Error('IQuickViewContext is null');
  return ctx;
}