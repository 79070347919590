import { Text } from "@mantine/core";
import useStyles from "../style";
import { LinkTextLabelProps } from "../type";

const LinkTextLabel = ({ text }: LinkTextLabelProps) => {
  const { classes } = useStyles();
  return <Text className={classes.linkTextLabel}>{text}</Text>;
};

export { LinkTextLabel };
