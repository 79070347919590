export const getFilterColor = (title: string) => {
  switch (title) {
    case "Project":
      return "#c1abcf";
    case "Target Date":
      return "#f2cece";
    case "Assigned To":
      return "#fae093";
    case "Status":
      return "#d2e39d";
    case "Tags":
      return "#c2d3ed";
    case "Assigned By":
      return "#b7e9e7";
    default:
      break;
  }
};
