import { ProjectListProps } from "../../components/Filters/ProjectFilter";

const generateNewSelectedProject = (
  dataList: ProjectListProps[],
  selectedArray: string[]
): string[] => {
  const newSelected: string[] = [];

  // Helper function to check if all child values are in selectedArray
  function areAllChildrenSelected(parent: ProjectListProps): boolean {
    if (!parent.children) {
      return selectedArray.includes(parent.value);
    }

    for (const child of parent.children) {
      if (
        !selectedArray.includes(child.value) &&
        !areAllChildrenSelected(child)
      ) {
        return false;
      }
    }

    return true;
  }

  // Recursive function to traverse the data structure
  function traverseData(data: ProjectListProps[]) {
    for (const item of data) {
      if (areAllChildrenSelected(item) && !newSelected.includes(item.value)) {
        newSelected.push(item.value);
      } else if (item.children) {
        traverseData(item.children);
        for (const child of item.children) {
          if (
            selectedArray.includes(child.value) &&
            !newSelected.includes(child.value)
          ) {
            newSelected.push(child.value);
          }
        }
      }
    }
  }

  traverseData(dataList);

  return newSelected;
};

const getAvatarText = (name: string) => {
  if (name) {
    const names = name.split(" ");
    if (names.length > 1) {
      return names[0][0].toUpperCase() + names[1][0].toUpperCase();
    } else {
      return names[0][0].toUpperCase();
    }
  }
};

const customSort = (arr: string[], order: string[]): string[] => {
  return arr.sort((a, b) => {
    const indexOfA = order.indexOf(a);
    const indexOfB = order.indexOf(b);
    return indexOfA - indexOfB;
  });
}


export { generateNewSelectedProject, getAvatarText, customSort };
