import useStyles from "./styles";
import { Flex, Progress } from "@mantine/core";
import PNG_Icon from "../../../assets/icons/PNG.png";
import JPG_Icon from "../../../assets/icons/JPG.png";
import GIF_Icon from "../../../assets/icons/GIF.png";
import MP3_Icon from "../../../assets/icons/MP3.png";
import PDF_Icon from "../../../assets/icons/PDF.png";
import XLS_Icon from "../../../assets/icons/XLS.png";
import CSV_Icon from "../../../assets/icons/CSV.png";
import moment from "moment";
import { IconCross, IconDownload, IconTrash, IconX } from "@tabler/icons-react";
import Typography from "../../common/Typography";
import { TypographyTypes } from "../../../utils/enums";
import {
  IAttachemntComponentProps,
  IAttachemntProps,
} from "../../../features/AttachmentSection/type";
import { useEffect, useState } from "react";
import { DATE_FORMAT } from "../../../utils/constants/dateFormat";
import { isDisabled } from "@testing-library/user-event/dist/utils";

export function Attachment({
  title,
  type,
  size,
  uploadedOn,
  index,
  url,
  handleRemove,
  isDisabled
}: IAttachemntProps & IAttachemntComponentProps) {
  const { classes, cx } = useStyles();

  const getIcon = () => {
    switch (type) {
      case "png":
        return PNG_Icon;
      case "jpeg":
      case "jpg":
        return JPG_Icon;
      case "mp3":
        return MP3_Icon;
      case "pdf":
        return PDF_Icon;
      case "gif":
        return GIF_Icon;
      case "xls":
      case "xlsx":
        return XLS_Icon;
      case "csv":
        return CSV_Icon;
      default:
        break;
    }
  };

  const handleDownload = () => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url || "";
    link.target = "_blank";
    link.download = title || ""; // Set the filename for the download

    // Simulate a click event to trigger the download
    link.dispatchEvent(new MouseEvent("click"));
  };

  return (
    <table className={classes.attachmentMain}>
      <tbody>
        <tr>
          <td className={classes.imageTd}>
            <img src={getIcon()} />
          </td>
          <td className={classes.titleTd}>
            <div>
              <Typography
                text={title || ""}
                type={TypographyTypes.defaultBold}
                color="#324054"
                className={classes.titleLink}
              />
            </div>
            <div>
              <Typography
                text={`${moment(uploadedOn).format(DATE_FORMAT)}`}
                type={TypographyTypes.default}
                color="grey"
              />
            </div>
          </td>
          <td className={classes.iconTd}>
            <Flex gap={7} justify={"right"}>
              <IconDownload
                color="grey"
                size={"1.1rem"}
                onClick={handleDownload}
              />
              {!isDisabled && <IconTrash
                color={"red"}
                size={"1.1rem"}
                onClick={() => handleRemove(index)}
              />}
            </Flex>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
