import { createStyles } from "@mantine/core";

const useAddLabelStyles = createStyles((theme) => ({
  addLabelBtn: {
    fontSize: 12,
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Roboto, sans-serif",
    height: 25,
    width: "fit-content",
    backgroundColor: "#F5F5F5",
    borderRadius: 0,
    padding: 5,
    marginLeft: 60,

    "&:hover":{
      backgroundColor: "#F5F5F5",
    }
  },
  popOverDropDown: {
    padding: 0,
  },
  labelItem: {
    fontSize: 12,
    fontWeight: 400,
    backgroundColor: "#F5F5F5",
    color: "#000000",
    height: 25,
    paddingLeft: 10,
    paddingRight: 5,
    textTransform: "none"
  },
  createLabel: {
    paddingLeft: 12,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 12,
    cursor: "pointer",
    display:"flex"
  },
  errorLabel: {
    color: "#EA0707",
    paddingLeft: 12,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 12,
  },
  closeBtn: {
    color: "#F80D0D"
  },
  labelItems:{
    display:"flex",
    background:"black !important"
  }
}));

export default useAddLabelStyles;
