import { ActionIcon, Badge, Group, rem } from "@mantine/core";
import LabelItemStyles from "./LabelItemStyle";

const TableLabelItem = ({ value }: { value: string }) => {
  const { classes } = LabelItemStyles();

  return (
    <Badge className={classes.labelItem}>
      {value}
    </Badge>
  );
};

export default TableLabelItem;
