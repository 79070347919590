import DataTable from "../common/DataTable";
import { StatusLabel } from "./TableComponents/StatusLabel";
import { OwnerLabel } from "./TableComponents/OwnerLabel";
import useStyles from "./style";
import { LinkTextLabel } from "./TableComponents/LinkTextLabel";
import { RaidItemTableProps, StatusLabelProps } from "./type";
import { useTranslation } from "react-i18next";
import { Anchor, Flex, Text, Tooltip } from "@mantine/core";
import { MentionedByLabel } from "./TableComponents/MentionedByLabel";
import TableLabelItem from "./TableComponents/TabelLableItem";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/constants/dateFormat";
import { IconLock } from "@tabler/icons-react";

const RaidItemTable = ({
  dashboardName,
  tableMetaData,
  onShowMoreClick,
  sorting,
  setSorting,
  isLoading,
  onClickItem,
  rowSelection,
  setRowSelection,
  raidListLoading,
}: RaidItemTableProps) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const handleClick = (code: string) => {
    window.open(`/item/edit/${code}`, "_blank");
  };

  const getTextPartition = (text: string) => text.slice(0, 30);

  const getTextWithEllipsis = (text: string): JSX.Element => {
    if (text.length < 30) {
      return <Text>{text}</Text>;
    } else {
      return (
        <Tooltip label={text}>
          <Text>{`${getTextPartition(text)}...`}</Text>
        </Tooltip>
      );
    }
  };

  const columns =
    dashboardName === "/control-center"
      ? [
          {
            accessorKey: "code",
            header: t("ID"),
            size: 60,
            sortType: "string",
            Cell: (cell: any) => (
              <div className={classes.lockIconDiv}>
                {!cell.row?.original?.isAuthorized && (
                  <span className={classes.lockIcon}>
                    <IconLock size={13} />
                  </span>
                )}
                <LinkTextLabel text={cell.renderedCellValue} />
              </div>
            ),
          },
          {
            accessorKey: "itemType",
            header: t("Item Type"),
            size: 110,
          },
          {
            accessorKey: "title",
            header: t("Title"),
            size: 240,
            Cell: (cell: any) => (
              <Anchor
                component="button"
                type="button"
                onClick={() => {
                  setRowSelection({
                    [cell.row.index]: true,
                  });
                  onClickItem(cell.row.original.code);
                }}
                onContextMenu={() => handleClick(cell.row.original.code)}
                style={{ textAlign: "left" }}
              >
                {getTextWithEllipsis(cell.renderedCellValue)}
              </Anchor>
            ),
          },
          {
            accessorKey: "project",
            header: t("Project"),
            size: 160,
          },
          {
            accessorKey: "owner",
            header: t("Assigned To"),
            size: 120,
            Cell: (cell: any) => (
              <OwnerLabel
                imageUrl={cell.renderedCellValue}
                color={cell.renderedCellValue}
                name={cell.renderedCellValue}
              />
            ),
          },
          {
            accessorKey: "assignedBy",
            header: t("Assigned By"),
            size: 120,
            Cell: (cell: any) => (
              <OwnerLabel
                imageUrl={cell.renderedCellValue ?? "Assigned By"}
                color={cell.renderedCellValue}
                name={cell.renderedCellValue ?? "Assigned By"}
              />
            ),
          },
          {
            accessorKey: "assignedDate",
            header: t("Assigned Date"),
            size: 135,
            Cell: (cell: any) => (
              <>{moment(cell.renderedCellValue).format(DATE_FORMAT)}</>
            ),
          },
          {
            accessorKey: "targetDate",
            header: t("Target Date"),
            size: 120,
            Cell: (cell: any) => (
              <>{moment(cell.renderedCellValue).format(DATE_FORMAT)}</>
            ),
          },
          {
            accessorKey: "statuses",
            header: t("Status"),
            size: 110,
            Cell: (cell: any) => (
              <StatusLabel
                {...getStatusLabelProps(
                  cell.renderedCellValue.name,
                  cell.renderedCellValue.colorCode
                )}
                data-testid="StatusLabel"
              />
            ),
          },
          {
            accessorKey: "labels",
            header: t("Tags"),
            size: 100,
            Cell: (cell: any) => (
              <div style={{ whiteSpace: "nowrap" }}>
                {cell.renderedCellValue
                  ? cell.renderedCellValue.map((lbl: string) => {
                      return <TableLabelItem value={lbl} />;
                    })
                  : "-"}
              </div>
            ),
          },
          {
            accessorKey: "createdDate",
            header: t("Created Date"),
            size: 130,
            Cell: (cell: any) => (
              <>{moment(cell.renderedCellValue).format(DATE_FORMAT)}</>
            ),
          },
        ]
      : dashboardName === "my-mentions"
      ? [
          {
            accessorKey: "code",
            header: t("ID"),
            size: 91,
            sortType: "string",
            Cell: (cell: any) => (
              <LinkTextLabel text={cell.renderedCellValue} />
            ),
          },
          {
            accessorKey: "title",
            header: t("Title"),
            size: 400,
            Cell: (cell: any) => (
              <Anchor
                component="button"
                type="button"
                onClick={() => {
                  setRowSelection({
                    [cell.row.index]: true,
                  });
                  onClickItem(cell.row.original.id);
                }}
                onContextMenu={() => handleClick(cell.row.original.code)}
              >
                {getTextWithEllipsis(cell.renderedCellValue)}
              </Anchor>
            ),
          },
          {
            accessorKey: "project",
            header: t("Project"),
            size: 240,
          },
          {
            accessorKey: "mentionedDate",
            header: t("Mentioned Date"),
            size: 152,
            Cell: (cell: any) => (
              <>
                {new Intl.DateTimeFormat("en-GB", {
                  year: "numeric",
                  month: "long",
                  day: "2-digit",
                }).format(new Date(cell.renderedCellValue.toString()))}
              </>
            ),
          },
          {
            accessorKey: "mentionedBy",
            header: t("Mentioned By"),
            size: 150,
            Cell: (cell: any) => (
              <MentionedByLabel
                imageUrl={cell.renderedCellValue}
                color={cell.renderedCellValue}
                name={cell.renderedCellValue}
              />
            ),
          },
          {
            accessorKey: "statuses",
            header: t("Status"),
            size: 150,
            Cell: (cell: any) => (
              <StatusLabel
                {...getStatusLabelProps(
                  cell.renderedCellValue.name,
                  cell.renderedCellValue.colorCode
                )}
                data-testid="StatusLabel"
              />
            ),
          },
        ]
      : [
          {
            accessorKey: "code",
            header: t("ID"),
            size: 60,
            sortType: "string",
            Cell: (cell: any) => (
              <div className={classes.lockIconDiv}>
                {!cell.row?.original?.isAuthorized && (
                  <span className={classes.lockIcon}>
                    <IconLock size={13} />
                  </span>
                )}
                <LinkTextLabel text={cell.renderedCellValue} />
              </div>
            ),
          },
          {
            accessorKey: "itemType",
            header: t("Item Type"),
            size: 110,
          },
          {
            accessorKey: "title",
            header: t("Title"),
            size: 240,
            Cell: (cell: any) => (
              <Anchor
                component="button"
                type="button"
                onClick={() => {
                  setRowSelection({
                    [cell.row.index]: true,
                  });
                  onClickItem(cell.row.original.code);
                }}
                onContextMenu={() => handleClick(cell.row.original.code)}
                style={{ textAlign: "left" }}
              >
                {getTextWithEllipsis(cell.renderedCellValue)}
              </Anchor>
            ),
          },
          {
            accessorKey: "project",
            header: t("Project"),
            size: 160,
          },
          {
            accessorKey: "owner",
            header: t("Assigned To"),
            size: 120,
            Cell: (cell: any) => (
              <OwnerLabel
                imageUrl={cell.renderedCellValue}
                color={cell.renderedCellValue}
                name={cell.renderedCellValue}
              />
            ),
          },
          {
            accessorKey: "assignedBy",
            header: t("Assigned By"),
            size: 120,
            Cell: (cell: any) => (
              <OwnerLabel
                imageUrl={cell.renderedCellValue ?? "Assigned By"}
                color={cell.renderedCellValue}
                name={cell.renderedCellValue ?? "Assigned By"}
              />
            ),
          },
          {
            accessorKey: "assignedDate",
            header: t("Assigned Date"),
            size: 135,
            Cell: (cell: any) => (
              <>{moment(cell.renderedCellValue).format(DATE_FORMAT)}</>
            ),
          },
          {
            accessorKey: "targetDate",
            header: t("Target Date"),
            size: 120,
            Cell: (cell: any) => (
              <>{moment(cell.renderedCellValue).format(DATE_FORMAT)}</>
            ),
          },
          {
            accessorKey: "statuses",
            header: t("Status"),
            size: 110,
            Cell: (cell: any) => (
              <StatusLabel
                {...getStatusLabelProps(
                  cell.renderedCellValue.name,
                  cell.renderedCellValue.colorCode
                )}
                data-testid="StatusLabel"
              />
            ),
          },
          {
            accessorKey: "labels",
            header: t("Tags"),
            size: 100,
            Cell: (cell: any) => (
              <div style={{ whiteSpace: "nowrap" }}>
                {cell.renderedCellValue
                  ? cell.renderedCellValue.map((lbl: string) => {
                      return <TableLabelItem value={lbl} />;
                    })
                  : "-"}
              </div>
            ),
          },
          {
            accessorKey: "createdDate",
            header: t("Created Date"),
            size: 130,
            Cell: (cell: any) => (
              <>{moment(cell.renderedCellValue).format(DATE_FORMAT)}</>
            ),
          },
        ];

  const getStatusLabelProps = (
    statusText: string,
    statusColor: string
  ): StatusLabelProps => {
    return {
      backgroundColor: statusColor + "1A",
      color: statusColor,
      borderColor: statusColor,
      text: statusText,
    };
  };

  return (
    <div className={`${classes.tableDiv} itemTable`}>
      <DataTable
        columns={columns}
        data={tableMetaData.items}
        state={{ sorting, isLoading, rowSelection }}
        setSorting={setSorting}
        rowSelection={rowSelection}
      />
      <div className={classes.seeMoreDiv}>
        <span
          className={cx(classes.seeMoreText, {
            [classes.seeMoreTextDisabled]: !tableMetaData.hasNext,
          })}
          onClick={
            tableMetaData.currentPage != tableMetaData.totalPages &&
            !raidListLoading
              ? onShowMoreClick
              : undefined
          }
        >
          {t("Show More ")}
        </span>
        <span>
          ({tableMetaData.items.length} of {tableMetaData.totalCount})
        </span>
      </div>
    </div>
  );
};

export default RaidItemTable;
