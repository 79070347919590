import { TypographyTypes } from "../../../utils/enums"
import RichTextEditor from "../../common/RichTextEditor"
import Typography from "../../common/Typography"
import useDetailsStyles from "./styles"
import { RaidTypeEnum } from "../../../utils/enums";
import { useState } from "react";
import { DetailsProps } from "./type";
import { TextInput, Textarea } from "@mantine/core";

const Details = ({
    form,
    formDisabled = false
}: DetailsProps) => {
    const { classes } = useDetailsStyles();
    
    return <>
        <div style={{ marginTop: 15 }}>
            
            <Typography type={TypographyTypes.default} className={classes.heading} text={"Description"} />
            <Textarea
                radius={0}
                placeholder={'Description'}
                readOnly= {formDisabled}
                {...form.getInputProps("description")}
            />
        </div>
        {form.values.type === RaidTypeEnum.RISK.toString() || form.values.type === RaidTypeEnum.ISSUE.toString() ?
            <div style={{ marginTop: 15 }}>
                <Typography type={TypographyTypes.default} className={classes.heading}
                    text={form.values.type === RaidTypeEnum.RISK.toString() ? "Mitigation Plan" : "Management Plan"}
                />
                <Textarea
                    radius={0}
                    readOnly= {formDisabled}
                    placeholder={form.values.type === RaidTypeEnum.RISK.toString() ? "Mitigation Plan" : "Management Plan"}
                    {...form.getInputProps("mitigationPlan")}
                />
            </div>
            : null
        }
    </>
}

export default Details