import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { getAccessToken } from "../authService";
import { ItemReq } from "../../features/QuickItemView/type";

export const getItemDetails = async (
  key: string,
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
) => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items`,
    params: { key: key },
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
  });
};

export const getComments = async (
  id: number,
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
) => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/${id}/comments`,
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
  });
};

export const updateRaidItem = async (
  item: ItemReq,
  accessToken: string,
  id: string
): Promise<{ data: any }> => {
  const bearer = `Bearer ${accessToken}`;
  const formData = new FormData();

  formData.set("Id", item.id.toString());
  formData.set("Title", item.title);
  formData.set("TargetDate", new Date(item.targetDate).toString().substring(0, 16));
  formData.set("ItemTypeId", item.itemTypeId.toString());
  formData.set("ItemType", item.itemType);
  formData.set("ItemStatusId", item.itemStatusId.toString());
  formData.set("ItemStatus", item.itemStatus.toString());
  formData.set("ContainerId", item.containerId.toString());
  formData.set("Container", item.container.toString());
  formData.set("OwnerId", item.ownerId.toString());
  formData.set("Owner", item.owner);
  item.newLabels?.forEach((label, i) => {
    formData.set(`NewLabels[${i}].labelId`, label.labelId.toString());
    formData.set(`NewLabels[${i}].name`, label.name);
    formData.set(`NewLabels[${i}].isDeleted`, label.isDeleted.toString());
  });
  item.updateLabels?.forEach((label, i) => {
    formData.set(`UpdateLabels[${i}].labelId`, label?.labelId.toString());
    formData.set(`UpdateLabels[${i}].name`, label?.name);
    formData.set(`UpdateLabels[${i}].isDeleted`, label?.isDeleted.toString());
  });
  formData.set("ItemImpactId", item.itemImpactId?.toString() ?? "");
  formData.set("ItemImpact", item.itemImpact?.toString() ?? "");
  formData.set("ItemPriorityId", item.itemPriorityId?.toString() ?? "");
  formData.set("ItemPriority", item.itemPriority?.toString() ?? "");
  formData.set("ItemProbabilityId", item.itemProbabilityId?.toString() ?? "");
  formData.set("ItemProbability", item.itemProbability?.toString() ?? "");
  formData.set("Description", item.description?.toString() ?? "");
  formData.set("MitigationPlan", item.mitigationPlan?.toString() ?? "");

  item.itemComments?.forEach((comment, i) => {
    formData.set(`ItemComments[${i}].comment`, comment.comment.toString());
    comment.commentId &&
      formData.set(
        `ItemComments[${i}].commentId`,
        comment.commentId.toString() ?? ""
      );
    comment.isDeleted &&
      formData.set(
        `ItemComments[${i}].isDeleted`,
        comment.isDeleted.toString() ?? ""
      );
    comment.isModified &&
      formData.set(
        `ItemComments[${i}].isModified`,
        comment.isModified?.toString() ?? ""
      );
    comment.itemMentions.forEach((mention, j) => {
      mention.id &&
        formData.set(
          `ItemComments[${i}].itemMentions[${j}].id`,
          mention.id.toString() ?? ""
        );
      mention.userId &&
        formData.set(
          `ItemComments[${i}].itemMentions[${j}].userId`,
          mention.userId.toString()
        );
      mention.isDeleted &&
        formData.set(
          `ItemComments[${i}].itemMentions[${j}].isDeleted`,
          mention.isDeleted.toString() ?? ""
        );
    });
  });

  item.ItemUpdateNewAttachements?.forEach((file: any) => {
    formData.append("ItemUpdateNewAttachements", file); // Append each file to the formData
  });

  item.ItemUpdateDeleteAttachements?.forEach((attachment, a) => {
    attachment.fileName &&
      formData.set(
        `ItemUpdateDeleteAttachements[${a}].fileName`,
        attachment.fileName.toString() ?? ""
      );
      attachment.fileUrl &&
      formData.set(
        `ItemUpdateDeleteAttachements[${a}].fileUrl`,
        attachment.fileUrl.toString() ?? ""
      );
  });

  return axios({
    method: "put",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: bearer,
    },
    data: formData,
  });
};

export const getHistory = async (
  id : number,
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
) => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/${id}/audit-events`,
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
  });
}
