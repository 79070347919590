import { createStyles } from "@mantine/core";

const useFilterTagStyles = createStyles((theme) => ({
  FilterTagWrapper: {
    // backgroundColor: "#D6D6D6",
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 50,
    height: 24,
    gap: 5,
    fontSize: 14,
    fontWeight: 400,
  },
}));

export default useFilterTagStyles;
