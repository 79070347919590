import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";

export interface attachements {
  fileUrl: string;
  fileName: string;
  createdOn: string;
}

export const getAttachments = (
  itemId: string,
  token: any
): Promise<{ data: attachements[] }> => {
  const bearer = `Bearer ${token}`;
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/${itemId}/attachments`,
    headers: { authorization: bearer },
  });
};
