import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { getAccessToken } from "../authService";
import { PrivateView } from "../../components/SideNavigation";

export interface SavePrivateViewAPIParams {
  name: string;
  filters: string | null;
  isShared: boolean
}

export interface UpdatePrivateViewAPIParams {
  id:number;
  name: string;
  filters: string | null;
  isShared: boolean
}

export interface DeletePrivateViewAPIParams {
  id:number;
}
export interface DeletePrivateViewAPIResParams {
  isDeleted: boolean;
}

export const getPrivateViews = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
):Promise<{ data: PrivateView[] }> => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/views/private-views`,
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
  });
};

export const savePrivateView = (requestBody: SavePrivateViewAPIParams, token: string) => {
  const bearer = `Bearer ${token}`;
  return axios<UpdatePrivateViewAPIParams>({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/views/private-view`,
    data: requestBody,
    headers: { authorization: bearer },
  });
};

export const updatePrivateView = (requestBody: UpdatePrivateViewAPIParams, token: string) => {
  const bearer = `Bearer ${token}`;
  return axios<UpdatePrivateViewAPIParams>({
    method: "PUT",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/views/private-view`,
    data: requestBody,
    headers: { authorization: bearer },
  });
};

export const deletePrivateView = (requestBody: DeletePrivateViewAPIParams, token: string) => {
  const bearer = `Bearer ${token}`;
  return axios<DeletePrivateViewAPIResParams>({
    method: "PUT",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/views/delete-view`,
    data: requestBody,
    headers: { authorization: bearer },
  });
};

