import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import envVariables from "../../utils/config.json";
import { PublicView } from "../../components/SideNavigation";
import { getAccessToken } from "../authService";
import axios from "../../axiosInstance";

export interface TeamsAndUserList {
  teams: [
    {
      teamId: number,
      teamName: string,
      numberOfTeamMembers: number
    }
  ],
  users: [
    {
      userId: number,
      firstName: string,
      lastName: string,
      userRole: string
    }
  ]
}

export interface SavePublicViewAPIParams {
  name: string;
  filters: string | null;
  isShared: boolean
}

export interface UpdatePublicViewAPIParams {
  id:number;
  name: string;
  filters: string | null;
  isShared: boolean
}

export interface DeletePublicViewAPIParams {
  id:number;
}
export interface DeletePublicViewAPIResParams {
  isDeleted: boolean;
}

export const getPublicViews = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
):Promise<{ data: PublicView[] }> => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/views/public-views`,
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
  });
};

export const getTeamsAndUserList = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
):Promise<{ data: TeamsAndUserList }> => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/views/teams-and-users`,
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
  });
};

export const savePublicView = (requestBody: SavePublicViewAPIParams, token: string) => {
  const bearer = `Bearer ${token}`;
  return axios<UpdatePublicViewAPIParams>({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/views/public-view`,
    data: requestBody,
    headers: { authorization: bearer },
  });
};

export const updatePublicView = (requestBody: UpdatePublicViewAPIParams, token: string) => {
  const bearer = `Bearer ${token}`;
  return axios<UpdatePublicViewAPIParams>({
    method: "PUT",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/views/public-view`,
    data: requestBody,
    headers: { authorization: bearer },
  });
};