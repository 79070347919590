import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    avatarGroupModal: {
        "& .mantine-Modal-body": {
            overflow: "auto",
            maxHeight: 500
        },
        "& .mantine-Modal-title": {
            fontWeight: 600
        }
    },
}));

export default useStyles;