import { Box } from "@mantine/core";
import useStyles from "../style";
import { IconCircleFilled } from "@tabler/icons-react";
import { StatusLabelProps } from "../type";

const StatusLabel = ({
  color,
  backgroundColor,
  borderColor,
  text,
}: StatusLabelProps) => {
  const { classes, cx } = useStyles();
  return (
    <Box data-testid = "OwnerLabel"
      className={cx(classes.statusLabel)}
      style={{
        color: color,
        borderColor: borderColor,
        backgroundColor: backgroundColor,
      }}
    >
      <IconCircleFilled size={10} />
      <span className={classes.cellLabelText}>{text}</span>
    </Box>
  );
};

export { StatusLabel };
