import React, { useEffect, useState } from "react";
import FilterPopover from "../../common/FilterPopover";
import { IconFolder } from "@tabler/icons-react";
import { ProjectFilterProps } from "./types";
import Search from "../../common/Search";

import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-checkbox-tree/src/less/react-checkbox-tree.less";
import useProjectFilterStyles from "./styles";
import FilterButtonPanel from "../../common/FilterButtonPanel";
import { Flex, Loader } from "@mantine/core";
import { useProjectContext } from "../../../contexts/project.context";
import CheckboxHierarchy from "../../common/CheckboxHierarchy";

const ProjectFilter = ({
  title,
  StateHasValue,
  searchValue,
  onChangeSearchValue,
  onClearSearchValue,
  onClearSelectValues,
  onAcceptSelectValues,
  handleSubmit,
  handleClear,
  isSingle,
  selected,
  setSelected,
  values,
  setValues,
  handleProjectSelectAll,
  checkedSelectAll, 
  setCheckedSelectAll,
  allKeys,
  disabled
}: ProjectFilterProps) => {
  const { classes, cx } = useProjectFilterStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [isActive, setIsActive] = useState<boolean>(false);
  const { status: projectStatus } = useProjectContext();
  useEffect(() => {
    if (!modalOpen) setIsActive(selected.length > 0);
  }, [selected]);

  return (
    <div>
      <FilterPopover
        children={
          <>
            <Search
              onChange={(e) => onChangeSearchValue(e.target.value)}
              value={searchValue}
              onClear={onClearSearchValue}
            />
            <Flex direction="column">
              {projectStatus == "loading" ? (
                <Flex direction="column" align="center" style={{ padding: 10 }}>
                  <Loader variant="dots" />
                </Flex>
              ) : (
                <>
                  <CheckboxHierarchy
                    isSingle={isSingle}
                    values={values}
                    setValues={setValues}
                    selected={selected}
                    setSelected={setSelected}
                    searchText={searchValue}
                  />
                  <FilterButtonPanel
                    onSubmit={() => {
                      setIsActive(selected.length > 0);
                      onAcceptSelectValues();
                      handleSubmit();
                      if(checkedSelectAll) {
                        handleProjectSelectAll();
                      }
                      selected.length > 0 && setModalOpen(false);
                    }}
                    onClear={() => {
                      setIsActive(false);
                      onClearSelectValues();
                      handleClear();
                    }}
                    checked={selected as [string]}
                    required={true}
                    errorMessage="Project filter cannot be empty"
                    enableSelectAll={true}
                    checkedSelectAll={checkedSelectAll}
                    setCheckedSelectAll={setCheckedSelectAll}
                    allKeys={allKeys}
                    setSelected={setSelected}
                  />
                </>
              )}
            </Flex>
          </>
        }
        title={title}
        isActive={isActive}
        icon={<IconFolder size={18} />}
        opened={modalOpen}
        setOpened={setModalOpen}
        closeOnClickOutside={StateHasValue}
        disabled={disabled}
      ></FilterPopover>
    </div>
  );
};

export default ProjectFilter;
