import {
  Button,
  Modal,
  Text,
  TextInput,
  SegmentedControl,
  ActionIcon,
  Avatar,
  Checkbox,
} from "@mantine/core";
import { SaveFilterPopupProps } from "./type";
import { useTranslation } from "react-i18next";
import useStyles from "./style";
import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getAccessToken } from "../../services/authService";
import {
  savePrivateView,
  updatePrivateView,
} from "../../services/PrivateViewService/PrivateView";
import SaveFilterIcon from "../../assets/icons/SaveFilter.png";
import UpdateFilterIcon from "../../assets/icons/UpdateFilter.png";
import { useFilterContext } from "../../contexts/filter.context";
import {
  updateEditMode,
  updateViewsAsync,
  useViews,
} from "../../contexts/view.context";
import getViewFromContext from "../../utils/functions/getViewFromContext";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { IconSearch, IconX } from "@tabler/icons-react";
import {
  TeamsAndUserList,
  getTeamsAndUserList,
  savePublicView,
  updatePublicView,
} from "../../services/PublicViewService/PublicView";

const SaveFilterPopup = ({
  opened,
  setOpened,
  save,
  dashboardName,
  disableAll,
  setDisableAll,
  labelsArray,
}: SaveFilterPopupProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [filterName, setFilterName] = useState<string>("");
  const { instance, accounts } = useMsal();
  const { state: filterState } = useFilterContext();
  const { dispatch, state: viewState } = useViews();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [isShared, setIsShared] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [teamsAndUserList, setTeamsAndUserList] = useState<TeamsAndUserList>();
  const [searchedDataList, setSearchedDataList] = useState<TeamsAndUserList>();
  const [publicViewUsers, setPublicViewUsers] = useState<number[]>([]);
  const [publicViewTeams, setPublicViewTeams] = useState<number[]>([]);
  const [newViewUsers, setNewViewUsers] = useState<number[]>([]);
  const [newViewTeams, setNewViewTeams] = useState<number[]>([]);
  const [updateViewUsers, setUpdateViewUsers] = useState<number[]>([]);
  const [updateViewTeams, setUpdateViewTeams] = useState<number[]>([]);
  const [listsUpdated, setListsUpdated] = useState<boolean>(true);

  useEffect(() => {
    setNewViewUsers([]);
    setNewViewTeams([]);
    setUpdateViewUsers([]);
    setUpdateViewTeams([]);
    if (
      viewState.publicViewState.length > 0 ||
      viewState.publicViewState.length > 0
    ) {
      const _view = getViewFromContext(viewState, location.pathname);
      if (_view) {
        setFilterName(_view.name);
        setIsShared(_view.isShared);
        if (viewState.publicViewState.length > 0) {
          let tempPublicViewTeams: number[] = [];
          _view.publicViewTeams?.forEach((team: any) => {
            tempPublicViewTeams.push(team.teamId);
          });
          let tempPublicViewUsers: number[] = [];
          _view.publicViewUsers?.forEach((user: any) => {
            tempPublicViewUsers.push(user.userId);
          });
          setPublicViewTeams(tempPublicViewTeams);
          setPublicViewUsers(tempPublicViewUsers);
          setListsUpdated(!listsUpdated);
        }
      } else {
        setFilterName("");
        setIsShared(false);
      }
    }
  }, [viewState, location.pathname, opened]);

  useEffect(() => {
    async function getTeamsAndUserListData() {
      const teamsAndUserData = await getTeamsAndUserList(instance, accounts);
      setTeamsAndUserList(teamsAndUserData.data);
      setSearchedDataList(teamsAndUserData.data);
    }
    getTeamsAndUserListData();
  }, []);

  async function SaveFilter() {
    setLoading(true);
    const accessToken = await getAccessToken(instance, accounts[0]);
    const filterString = getFilterString();
    if (!isShared) {
      const itemReq = {
        name: filterName,
        filters: filterString,
        isShared: isShared,
      };

      if (filterString) {
        savePrivateView(itemReq, accessToken)
          .then(async ({ data }) => {
            let url = "/private/" + data.id;
            await updateViewsAsync(
              dispatch,
              { type: "setPrivateViews", payload: [] },
              instance,
              accounts
            );
            setOpened(false);
            setLoading(false);
            navigate(url);
          })
          .catch();
      }
    } else {
      const itemReq = {
        name: filterName,
        filters: filterString,
        isShared: isShared,
        publicViewUsers: newViewUsers,
        publicViewTeams: newViewTeams,
      };
      if (filterString) {
        savePublicView(itemReq, accessToken)
          .then(async ({ data }) => {
            let url = "/public/" + data.id;
            await updateViewsAsync(
              dispatch,
              { type: "setPublicViews", payload: [] },
              instance,
              accounts
            );
            setOpened(false);
            setLoading(false);
            navigate(url);
          })
          .catch();
      }
    }
  }

  const getFilterString = () => {
    const ownerFilter = filterState?.ownerFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const projectFilter = filterState?.projectFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const projectRefacoterdFilter = filterState?.projectRefactoredFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const periodFilter = filterState?.periodFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const tagFilter = filterState?.tagFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const statusFilter = filterState?.statusFilter?.find(
      (e) => e.dashboard === dashboardName
    );
    const assignedByFilter = filterState?.assignedByFilter?.find(
      (e) => e.dashboard === dashboardName
    );

    let selectedFilters = {
      ownerFilter: {
        selectedValues: ownerFilter?.selectedValues ?? [],
      },
      statusFilter: {
        selectedValues: statusFilter?.selectedValues ?? [],
      },
      tagFilter: {
        selectedValues: tagFilter?.selectedValues ?? [],
      },
      periodFilter: {
        type: periodFilter?.type ?? "",
        selectedValues: periodFilter?.selectedValues ?? [],
      },
      projectFilter: {
        selectedValues: projectFilter?.selectedValues ?? [],
      },
      assignedByFilter: {
        selectedValues: assignedByFilter?.selectedValues ?? [],
      },
      readOnlyFilter: {
        projectFilter: labelsArray?.projectFilter,
        periodFilter: labelsArray?.periodFilter,
        ownerFilter: labelsArray?.ownerFilter,
        statusFilter: labelsArray?.statusFilter,
        tagFilter: labelsArray?.tagFilter,
        assignedByFilter: labelsArray?.assignedByFilter,
      },
    };
    return JSON.stringify(selectedFilters);
  };

  async function UpdateFilter() {
    setLoading(true);
    const accessToken = await getAccessToken(instance, accounts[0]);
    const filterString = getFilterString();
    const _view = getViewFromContext(viewState, location.pathname);
    if (!isShared) {
      const itemReq = {
        id: parseInt(dashboardName.split("/")[2]),
        name: filterName,
        filters: filterString,
        isShared: isShared,
      };
      if (filterString) {
        updatePrivateView(itemReq, accessToken)
          .then(async ({ data }) => {
            setOpened(false);
            setLoading(false);
            updateEditMode(dispatch, { type: "editMode", payload: false });
            setDisableAll(!disableAll);
            if (_view.isShared !== isShared) {
              await updateViewsAsync(dispatch, null, instance, accounts);
              let url = "/private/" + data.id;
              navigate(url);
            } else {
              await updateViewsAsync(
                dispatch,
                { type: "setPrivateViews", payload: [] },
                instance,
                accounts
              );
            }
          })
          .catch();
      }
    } else {
      const itemReq = {
        id: parseInt(dashboardName.split("/")[2]),
        name: filterName,
        filters: filterString,
        isShared: isShared,
        newViewUsers: newViewUsers,
        newViewTeams: newViewTeams,
        updateViewUsers: updateViewUsers,
        updateViewTeams: updateViewTeams,
      };
      if (filterString) {
        updatePublicView(itemReq, accessToken)
          .then(async ({ data }) => {
            setOpened(false);
            setLoading(false);
            updateEditMode(dispatch, { type: "editMode", payload: false });
            setDisableAll(!disableAll);
            if (_view.isShared !== isShared) {
              await updateViewsAsync(dispatch, null, instance, accounts);
              let url = "/public/" + data.id;
              navigate(url);
            } else {
              await updateViewsAsync(
                dispatch,
                { type: "setPublicViews", payload: [] },
                instance,
                accounts
              );
            }
          })
          .catch();
      }
    }
  }

  function searchByPartOfName(data: any, fieldName: string) {
    return data.filter((item: any) =>
      item[fieldName].toLowerCase().includes(searchText.toLowerCase())
    );
  }

  const onSearchTeamsAndUsers = () => {
    if (searchText === "" || searchText === null) {
      setSearchedDataList(teamsAndUserList);
    } else {
      const searchResult = {
        teams: searchByPartOfName(
          teamsAndUserList && teamsAndUserList.teams,
          "teamName"
        ),
        users: searchByPartOfName(
          teamsAndUserList && teamsAndUserList.users,
          "firstName"
        ).concat(
          searchByPartOfName(
            teamsAndUserList && teamsAndUserList.users,
            "lastName"
          )
        ),
      };
      setSearchedDataList(searchResult);
    }
  };

  const checkSelectedTeamsAndUsers = () => {
    if (isShared) {
      let usersResult = [];
      let teamsResult = [];
      let tempPublicViewUsers = publicViewUsers;
      let tempPublicViewTeams = publicViewTeams;
      usersResult = tempPublicViewUsers.filter(function (item) {
        return !updateViewUsers.includes(item);
      });
      teamsResult = tempPublicViewTeams.filter(function (item) {
        return !updateViewTeams.includes(item);
      });
      usersResult = usersResult.concat(newViewUsers);
      teamsResult = teamsResult.concat(newViewTeams);
      return usersResult.concat(teamsResult).length > 0 && filterName.length > 0
        ? false
        : true;
    } else {
      return filterName.length > 0 ? false : true;
    }
  };

  useEffect(() => {
    onSearchTeamsAndUsers();
  }, [searchText]);

  return (
    <Modal
      opened={opened}
      onClose={() => {
        setOpened(false);
      }}
      className={classes.saveFilterDiv}
      size={428}
    >
      <div className={classes.iconDiv}>
        {save ? <img src={SaveFilterIcon} /> : <img src={UpdateFilterIcon} />}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {save ? (
          <Text style={{ fontWeight: 600, fontSize: 20, marginTop: 5 }}>
            {t("save_custom_filter")}
          </Text>
        ) : (
          <Text style={{ fontWeight: 600, fontSize: 20, marginTop: 5 }}>
            {t("update_custom_filter")}
          </Text>
        )}
      </div>
      <div>
        <Text style={{ fontWeight: 400, fontSize: 16, marginTop: 20 }}>
          Filter Name
        </Text>
        <TextInput
          value={filterName}
          onChange={(e: any) => setFilterName(e.target.value)}
        />
        <Text style={{ fontWeight: 400, fontSize: 16, marginTop: 20 }}>
          Manage Access
        </Text>
        <SegmentedControl
          color="blue"
          value={isShared ? "Public Filter" : "Private Filter"}
          onChange={(val) => {
            val === "Public Filter" ? setIsShared(true) : setIsShared(false);
          }}
          style={{ marginTop: 5 }}
          fullWidth
          data={[
            { value: "Private Filter", label: "Private Filter" },
            { value: "Public Filter", label: "Public Filter" },
          ]}
        />
      </div>
      {isShared && (
        <div style={{ marginTop: 10 }}>
          <TextInput
            placeholder={"Add Individuals, Groups & Projects"}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            rightSection={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: 56,
                  marginLeft: -20,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {searchText && (
                  <ActionIcon variant="transparent">
                    <IconX
                      size={15}
                      onClick={() => {
                        setSearchText("");
                        setSearchedDataList(teamsAndUserList);
                      }}
                    />
                  </ActionIcon>
                )}
                <ActionIcon
                  variant="transparent"
                  style={{
                    minWidth: 36,
                    height: 36,
                    border: "1px solid silver",
                  }}
                >
                  <IconSearch
                    data-testid="search-icon"
                    size={15}
                    onClick={() => onSearchTeamsAndUsers()}
                  />
                </ActionIcon>
              </div>
            }
          />
          <div style={{ overflowY: "scroll", height: 250, marginTop: 10 }}>
            {searchedDataList &&
              searchedDataList.teams.map((team) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 6,
                      marginLeft: 20,
                    }}
                  >
                    <Avatar
                      variant="filled"
                      radius="xl"
                      color="blue"
                      src=""
                      size={"lg"}
                    >
                      {team.teamName[0]}
                    </Avatar>
                    <div style={{ width: 230, marginRight: 20 }}>
                      <Text
                        truncate="end"
                        style={{
                          fontWeight: 700,
                          fontSize: 12,
                          marginLeft: 20,
                        }}
                      >
                        {team.teamName}
                      </Text>
                      <Text
                        style={{
                          fontWeight: 400,
                          fontSize: 10,
                          marginLeft: 20,
                          marginTop: -3,
                        }}
                      >
                        {team.numberOfTeamMembers + " Members"}
                      </Text>
                    </div>
                    <Checkbox
                      checked={
                        (publicViewTeams.includes(team.teamId) &&
                          !updateViewTeams.includes(team.teamId)) ||
                        newViewTeams.includes(team.teamId)
                      }
                      onChange={(event) => {
                        if (event.currentTarget.checked) {
                          if (
                            !newViewTeams.includes(team.teamId) &&
                            !publicViewTeams.includes(team.teamId)
                          ) {
                            newViewTeams.push(team.teamId);
                          } else {
                            let index = updateViewTeams.indexOf(team.teamId);
                            updateViewTeams.splice(index, 1);
                          }
                        } else {
                          if (
                            !updateViewTeams.includes(team.teamId) &&
                            publicViewTeams.includes(team.teamId)
                          ) {
                            updateViewTeams.push(team.teamId);
                          } else {
                            let index = newViewTeams.indexOf(team.teamId);
                            newViewTeams.splice(index, 1);
                          }
                        }
                        setListsUpdated(!listsUpdated);
                      }}
                    />
                  </div>
                );
              })}
            {searchedDataList &&
              searchedDataList.users.map((user) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 6,
                      marginLeft: 20,
                    }}
                  >
                    <Avatar
                      variant="filled"
                      radius="xl"
                      color="blue"
                      src=""
                      size={"lg"}
                    >
                      {user.firstName[0]}
                      {user.lastName[0]}
                    </Avatar>
                    <div style={{ width: 230, marginRight: 20 }}>
                      <Text
                        truncate="end"
                        style={{
                          fontWeight: 700,
                          fontSize: 12,
                          marginLeft: 20,
                        }}
                      >
                        {user.firstName + " "}
                        {user.lastName}
                      </Text>
                      <Text
                        style={{
                          fontWeight: 400,
                          fontSize: 10,
                          marginLeft: 20,
                          marginTop: -3,
                        }}
                      >
                        {user.userRole}
                      </Text>
                    </div>
                    <Checkbox
                      checked={
                        (publicViewUsers.includes(user.userId) &&
                          !updateViewUsers.includes(user.userId)) ||
                        newViewUsers.includes(user.userId)
                      }
                      onChange={(event) => {
                        if (event.currentTarget.checked) {
                          if (
                            !newViewUsers.includes(user.userId) &&
                            !publicViewUsers.includes(user.userId)
                          ) {
                            newViewUsers.push(user.userId);
                          } else {
                            let index = updateViewUsers.indexOf(user.userId);
                            updateViewUsers.splice(index, 1);
                          }
                        } else {
                          if (
                            !updateViewUsers.includes(user.userId) &&
                            publicViewUsers.includes(user.userId)
                          ) {
                            updateViewUsers.push(user.userId);
                          } else {
                            let index = newViewUsers.indexOf(user.userId);
                            newViewUsers.splice(index, 1);
                          }
                        }
                        setListsUpdated(!listsUpdated);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 30,
        }}
      >
        <Button
          style={{ width: "48%", fontSize: 16 }}
          variant="outline"
          data-testid="clear-btn"
          onClick={() => {
            setOpened(false);
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          loading={loading}
          style={{ width: "48%", fontSize: 16 }}
          variant="filled"
          data-testid="clear-btn"
          disabled={checkSelectedTeamsAndUsers()}
          onClick={() => {
            save ? SaveFilter() : UpdateFilter();
          }}
        >
          {t("save")}
        </Button>
      </div>
    </Modal>
  );
};

export { SaveFilterPopup };
