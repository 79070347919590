import { Avatar, Container, Flex, Grid } from "@mantine/core";
import Typography from "../common/Typography";
import { TypographyTypes } from "../../utils/enums";
import useCommentStyles from "./styles";
import { CommentProps } from "./type";
import CommentEditor from "./CommentEditor";
import { IconPencil, IconX } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import { MentionItem } from "react-mentions";
import getTimeAgo from "../../utils/functions/getTimeAgo";

const AppComment = ({
  index,
  commentId,
  comment,
  author,
  createdOn,
  isModified,
  mentions,
  isNewComment,
  onEditComment,
  onDeleteComment,
  setIsCommentBoxEmpty,
  isDisabled = false
}: CommentProps) => {
  const { classes, cx } = useCommentStyles();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [commentText, setCommentText] = useState<string>(comment);
  const [plainText, setPlainText] = useState<string>(comment);
  const [userList, setUserList] = useState<MentionItem[]>();

  useEffect(() => {
    if (mentions) {
      const _mentions: MentionItem[] =
        mentions?.map((mention) => {
          return {
            display: mention.name,
            id: mention.id,
            childIndex: -1,
            index: -1,
            plainTextIndex: -1,
          };
        }) ?? [];

      setUserList(_mentions);
    }
  }, [mentions]);

  function formatAuthorName(author: string) {
    const fullNameParts = author.split(' ');
  
    if (fullNameParts.length > 1) {
      const firstName = fullNameParts[0];
      const lastNameInitial = fullNameParts[fullNameParts.length - 1][0];
      return `${firstName} ${lastNameInitial}`;
    }
  
    return author;
  }

  const getCommentDisplayText = useCallback(() => {
    const originalText = plainText;
    const searchTextArray = userList?.map((user) => user.display) ?? [];
    const renderText = (parts: (string | JSX.Element)[]) => {
      return parts.map((part, index) => {
        if (typeof part === "string") {
          return part;
        } else {
          return <strong key={index}>{part}</strong>;
        }
      });
    };

    let renderedText: (string | JSX.Element)[] = [originalText];

    searchTextArray.forEach((searchText) => {
      renderedText = renderedText.flatMap((part) => {
        if (typeof part === "string") {
          return part
            .split(new RegExp(`(${searchText})`, "gi"))
            .map((subPart, index) => {
              if (subPart?.toLowerCase() === searchText?.toLowerCase()) {
                return <strong key={index}>{subPart}</strong>;
              }
              return subPart;
            });
        } else {
          return [part];
        }
      });
    });

    renderedText = renderText(renderedText);

    return <div>{renderedText}</div>;
  }, [mentions, commentText, userList]);

  const getCommentOriginalText = useCallback(() => {
    var originalText = plainText;
    const userNameIdList: { name: string; id: string }[] =
      userList?.map((user) => {
        return {
          name: user.display,
          id: user.id,
        };
      }) ?? [];
    const userIdListFromSet = Array.from(new Set(userNameIdList));
    // Remove duplicates based on the 'id' property
    const uniqueUserIdList = userIdListFromSet.filter(
      (user, index, self) => index === self.findIndex((u) => u.id === user.id)
    );

    uniqueUserIdList.forEach((user) => {
      originalText = originalText.replaceAll(
        user.name,
        `@[${user.name}](${user.id})`
      );
    });

    return originalText;
  }, [mentions, commentText, userList]);

  return (
    <Grid>
      <Grid.Col span={12}>
        <Flex
          justify="flex-start"
          align="flex-start"
          direction="row"
          wrap="nowrap"
          style={{ marginTop: 5, padding: 10 }}
        >
          <Avatar color="cyan" radius="xl" size={48}>
            {author.slice(0, 1)}
          </Avatar>
          <Container fluid style={{ width: "91%" }}>
            <Flex
              justify="flex-start"
              align="flex-start"
              direction="column"
              wrap="wrap"
              style={{ paddingLeft: 10 }}
            >
              <Flex
                justify="flex-start"
                align="center"
                direction="row"
                gap={5}
                wrap="wrap"
              >
                <div className={classes.commentNameContainer}>
                  <Typography
                    type={TypographyTypes.default}
                    className={classes.commentName}
                    data-testid="author-username"
                    text={formatAuthorName(author)}
                  />
                </div>
                <Typography
                  type={TypographyTypes.default}
                  className={classes.commentTimeDot}
                  text={"•"}
                />
                {/* <Typography
                  type={TypographyTypes.default}
                  className={classes.commentTime}
                  text={new Intl.DateTimeFormat("en-GB", {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  }).format(new Date(timeStamp.toString()))}
                /> */}
                <Typography
                  type={TypographyTypes.default}
                  className={classes.commentTime}
                  text={getTimeAgo(createdOn.toString())}
                />
                {isModified && (
                  <Typography
                    type={TypographyTypes.default}
                    className={classes.commentTime}
                    text={"Edited"}
                  />
                )}
                {!editMode && !isDisabled && (
                  <IconPencil
                    size={15}
                    color="gray"
                    onClick={() => {
                      setEditMode(true);
                    }}
                  ></IconPencil>
                )}
                {!isDisabled && <IconX
                  size={15}
                  color="gray"
                  onClick={() => {
                    if (onDeleteComment) {
                      if (isNewComment) {
                        onDeleteComment(true, index);
                      } else {
                        onDeleteComment(false, commentId ?? -1);
                      }
                    }
                  }}
                ></IconX>}
              </Flex>
              <Flex
                justify="flex-start"
                align="flex-end"
                direction="row"
                wrap="wrap"
                style={{ width: "100%" }}
              >
                {editMode ? (
                  <CommentEditor
                    onClear={() => {
                      setEditMode(false);
                    }}
                    onSubmit={(original, plain, mention) => {
                      setCommentText(original);
                      setPlainText(plain);
                      setEditMode(false);
                      onEditComment &&
                        onEditComment(
                          isNewComment ?? false,
                          commentId ? commentId : index,
                          original,
                          plain,
                          mention.length > 0 ? mention : userList || []
                        );
                    }}
                    setUserList={setUserList}
                    comment={getCommentOriginalText()}
                    plainText={plainText}
                  />
                ) : (
                  <div>{getCommentDisplayText()}</div>
                )}
              </Flex>
            </Flex>
          </Container>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default AppComment;
