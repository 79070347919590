import { createStyles } from "@mantine/core";

const useTabGroupStyles = createStyles((theme) => ({
  heading: {
    fontSize: 16,
    fontWeight: 600,
    color: "#000000",
    fontFamily: "Roboto, sans-serif",
  },
  miscData: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000000",
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000000",
  }
}));

export default useTabGroupStyles
