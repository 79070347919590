import { Avatar, Flex } from "@mantine/core";
import { TypographyTypes } from "../../../utils/enums";
import Typography from "../../common/Typography";
import useHistoryStyles from "./styles";
import { HistoryObj, HistoryTabProps } from "./type";
import { getAvatarText } from "../../../utils/functions/getAvatarText";
import { IconArrowRight } from "@tabler/icons-react";
import parse from "html-react-parser";
import { DATE_TIME_FORMAT } from "../../../utils/constants/dateFormat";
import { convertDateTime } from "../../../utils/functions/utcOffsetHandler";

const HistoryTab = ({ historyData, itemTitle }: HistoryTabProps) => {
  const { classes } = useHistoryStyles();

  const HistoryTitle = (el: HistoryObj) => {
    return (
      <Flex
        justify="flex-start"
        align="flex-start"
        direction="row"
        wrap="wrap"
        gap={10}
        style={{ paddingLeft: 20 }}
      >
        <Typography
          type={TypographyTypes.default}
          className={classes.name}
          text={el.user.firstName + " " + el.user.lastName}
        />
        <Typography
          type={TypographyTypes.default}
          className={classes.description}
          text={
            el.event == "item_updated"
              ? " updated the "
              : ` Created the item ${itemTitle}`
          }
        />
        <Typography
          type={TypographyTypes.default}
          className={classes.name}
          text={el.attribute || ""}
        />
        <Typography
          type={TypographyTypes.default}
          className={classes.dateTime}
          text={convertDateTime(el.eventDateTime, DATE_TIME_FORMAT)}
        />
      </Flex>
    );
  };

  return (
    <>
      {historyData.map((el) => (
        <Flex
          justify="flex-start"
          align="flex-start"
          direction="row"
          wrap="wrap"
          style={{ marginTop: 5, padding: 10 }}
        >
          <Avatar color="cyan" radius="xl" size={35}>
            {getAvatarText(el.user.firstName + " " + el.user.lastName)}
          </Avatar>
          <Flex
            justify="flex-start"
            align="flex-start"
            direction="column"
            wrap="wrap"
            style={{ width: "90%" }}
          >
            <HistoryTitle {...el}></HistoryTitle>
            {el.event == "item_updated" ? (
              <Flex
                justify="flex-start"
                align="center"
                direction="row"
                wrap="nowrap"
                gap={10}
                style={{ paddingLeft: 20 }}
              >
                {el.oldValue ? (
                  <>
                    {el.attribute === "Target Date" ? (
                      <div className={classes.changeDiv}>
                        {parse(el.oldValue?.split(" ")[0])}
                      </div>
                    ) : (
                      <div className={classes.changeDiv}>
                        {parse(el.oldValue || "")}
                      </div>
                    )}
                    <IconArrowRight
                      className={classes.iconArrow}
                      size={18}
                    ></IconArrowRight>
                  </>
                ) : (
                  <></>
                )}
                {el.attribute === "Target Date" ? (
                  <div className={classes.changeDiv}>
                    {parse(el.newValue?.split(" ")[0] || "")}
                  </div>
                ) : (
                  <div className={classes.changeDiv}>
                    {parse(el.newValue || "No Status")}
                  </div>
                )}
              </Flex>
            ) : (
              <></>
            )}
          </Flex>
        </Flex>
      ))}
    </>
  );
};

export default HistoryTab;
