import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";

export interface ILabelFilterAPIParams {
  containerIds: string[];
  search: string;
}

export const getLabelFilterList = (
  requestBody: ILabelFilterAPIParams,
  accessToken: any
) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/filters/item-labels`,
    data: requestBody,
    headers: { authorization: bearer },
  });
};
