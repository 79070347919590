import { useEffect, useState } from "react";
import CommonViewPage from "../CommonViewPage";
import { useViews } from "../../contexts/view.context";
import getViweFromContext from "../../utils/functions/getViewFromContext";
import {
  FilterState,
  LOCAL_STORAGE_KEY,
  ViewFilterObject,
  useFilterContext,
} from "../../contexts/filter.context";
import { useLocation, useNavigate } from "react-router";
import {
  PublicViewTeam,
  PublicViewUser,
  View,
} from "../../components/SideNavigation/types";
import AvatarGroup, {
  AvatarGroupItems,
} from "../../components/common/AvatarGroup";

const CustomViewPage = () => {
  const { state: viewState } = useViews();
  const navigate = useNavigate();
  const [view, setView] = useState<View>();
  const [initFilter, setInitFilter] = useState<ViewFilterObject>();
  const { state: filterState } = useFilterContext();
  const location = useLocation();
  const [avtList, setAvtList] = useState<AvatarGroupItems[]>([]);

  useEffect(() => {
    if (viewState.state === "LOADED") {
      const _view = getViweFromContext(viewState, location.pathname);
      if (_view) {
        const privatefilter = JSON.parse(_view.filters) as ViewFilterObject;
        setInitFilter(privatefilter);
        const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
        var parsedState = storedState
          ? (JSON.parse(storedState) as FilterState)
          : JSON.parse(JSON.stringify(filterState));
        const editedState = updateViewFilter(
          parsedState,
          location.pathname,
          privatefilter
        );
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(editedState));
        setView(_view);

        _view.isShared &&
          setAvtList([
            _view.ownerFirstName && _view.ownerLastName
              ? {
                  name: `${_view.ownerFirstName} ${_view.ownerLastName}`,
                  id: _view.ownerUserId,
                  isOwner: true,
                }
              : { name: "Unkonwn User", id: 0, isOwner: true },
            ..._view?.publicViewTeams?.map((e: PublicViewTeam) => {
              return {
                name: e.teamName,
                id: e.teamId,
                groupMembers: e.numberOfTeamMembers,
              };
            }),
            ..._view?.publicViewUsers?.map((e: PublicViewUser) => {
              return { name: `${e.firstName} ${e.lastName}`, id: e.userId };
            }),
          ]);
      } else {
        navigate("/page-not-found");
      }
    }
  }, [viewState, location.pathname]);

  function updateViewFilter(
    prevState: FilterState,
    dashboardName: string,
    filterObject: ViewFilterObject
  ): FilterState {
    const state = JSON.parse(JSON.stringify(prevState));
    const filterKeys = [
      "ownerFilter",
      "statusFilter",
      "tagFilter",
      "projectFilter",
      "periodFilter",
      "assignedByFilter",
    ];

    filterKeys.forEach((key) => {
      const filter = filterObject[key as keyof ViewFilterObject];
      if (filter) {
        state[key as keyof FilterState] = addOrEditIfDashboardElementExists(
          dashboardName,
          {
            ...filter,
            dashboard: dashboardName,
          },
          state[key as keyof FilterState]
        );
      }
    });
    return state;
  }

  function addOrEditIfDashboardElementExists(
    dashboard: string,
    element: any,
    stateArray: any
  ) {
    const existingIndex = stateArray.findIndex(
      (item: any) => item.dashboard === dashboard
    );
    if (existingIndex !== -1) {
      stateArray.splice(existingIndex, 1);
    }
    if (element?.selectedValues?.length > 0 || element?.type?.length > 0) {
      stateArray.push(element);
    }
    return stateArray;
  }

  return view?.name ? (
    <>
      <CommonViewPage
        view={view}
        initFilter={initFilter}
        setEnableMode={true}
        readonlyFilter={
          view?.isShared && !view?.isOwned
            ? initFilter?.readOnlyFilter ?? {}
            : undefined
        }
        customHeader={
          view.isShared ? (
            <AvatarGroup avatarList={avtList} showAvatars={3} />
          ) : (
            <></>
          )
        }
      />
    </>
  ) : (
    <></>
  );
};

export default CustomViewPage;
