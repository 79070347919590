function getTimeAgo(dateString: string): string {
  const ISO_String = (dateString[dateString.length-1] == "Z" ? dateString: `${dateString}Z`)
  const currentDate = new Date();
  const targetDate = new Date(ISO_String);

  const timeDifference = currentDate.getTime() - targetDate.getTime();

  const secondsAgo = Math.floor(timeDifference / 1000);
  const minutesAgo = Math.floor(timeDifference / (1000 * 60));
  const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const monthsAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
  const yearsAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

  if (secondsAgo < 60) {
    return `${secondsAgo} sec${secondsAgo === 1 ? "" : "s"} ago`;
  } else if (minutesAgo < 60) {
    return `${minutesAgo} min${minutesAgo === 1 ? "" : "s"} ago`;
  } else if (hoursAgo < 24) {
    return `${hoursAgo} hr${hoursAgo === 1 ? "" : "s"} ago`;
  } else if (daysAgo < 30) {
    return `${daysAgo} day${daysAgo === 1 ? "" : "s"} ago`;
  } else if (monthsAgo < 12) {
    return `${monthsAgo} mth${monthsAgo === 1 ? "" : "s"} ago`;
  } else {
    return `${yearsAgo} yr${yearsAgo === 1 ? "" : "s"} ago`;
  }
}

export default getTimeAgo;
