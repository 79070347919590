import { createStyles } from "@mantine/core";

const useDetailsStyles = createStyles((theme) => ({
  heading: {
    fontSize: 16,
    fontWeight: 600,
    color: "#000000",
    fontFamily: "Roboto, sans-serif",
    marginTop: 20,
    marginBottom: 10
  },
  miscData: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000000",
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: "#000000",
  },
  warning: {
    fontSize: 14,
    fontWeight: 400,
    color: "#fa5252",
    marginTop: 2
  }
}));

export default useDetailsStyles
