import { createStyles, rem } from "@mantine/styles";

const HEADER_HEIGHT = rem(50);

const useStyles = createStyles((theme) => ({
  headerWrapper: {
    padding: 15,
    marginTop: 15,
  },
  iconButton: {
    width: "100%",
    height: 38,
    gap: 10,
    backgroundColor: "#3C91E6"
  },
}));

export default useStyles;
