import { Group, Text, Box, Avatar, Flex, useMantineTheme } from "@mantine/core";
import useStyles from "./styles";
import HeaderProps from "./type";
import { useBranding } from "../../../hooks/functions/branding";

const UserAvatar = ({ name, position, avatarAlt, avatarUrl }: HeaderProps) => {
  const branding = useBranding();
  const { classes } = useStyles(branding.state);
  const theme = useMantineTheme();

  return (
    <Box>
      <Group position="left" sx={{ height: "100%", marginBottom:5 }}>
        <Avatar
          color={theme.colors.cyan[1]}
          radius="xl"
          src={avatarUrl}
          data-testid="test-avatar-comp"
        >
          {avatarAlt}
        </Avatar>
        <Flex
          direction={"column"}
          justify={"space-around"}
          className={classes.textMinWidth}
        >
          <Text className={classes.userNameText}>{name}</Text>
          <Text className={classes.positionText}>{position}</Text>
        </Flex>
      </Group>
    </Box>
  );
};

export default UserAvatar;
