import { IconStar, IconUser } from "@tabler/icons-react";
import FilterPopover from "../../common/FilterPopover";
import { Flex, Loader } from "@mantine/core";
import FilterButtonPanel from "../../common/FilterButtonPanel";
import CheckboxList from "../../common/CheckboxList";
import Search from "../../common/Search";
import { getHotkeyHandler } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import {
  StatusFilterAPIItem,
  StatusFilterCheckboxItem,
  StatusFilterProp,
} from "./type";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { customSort } from "../../../utils/functions/project";

const StatusFilter = ({
  statusList,
  StateHasValue,
  getStatusFilters,
  checked,
  setChecked,
  handleSubmit,
  handleClear,
  selectedContainerIds,
  isLoading,
  disabled,
  handleSelectAll,
  checkedSelectAll,
  setCheckedSelectAll,
  allKeys,
}: StatusFilterProp) => {
  const { t: translate } = useTranslation();

  const [searchString, setSearchString] = useState("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [nodes, setNodes] = useState<StatusFilterCheckboxItem[]>([]);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    const dataList: StatusFilterAPIItem[] = statusList;
    const nodeList: StatusFilterCheckboxItem[] = dataList.map((i) => {
      return {
        label: i.value,
        value: i.key,
        isSelected: i.isSelected,
      };
    });

    setNodes(nodeList);

    const checked: string[] = nodeList
      .filter((j) => j.isSelected)
      .map((e) => e.value);
    setChecked(checked as [string]);
  }, [statusList]);

  useEffect(() => {
    if (!modalOpen) setIsActive(checked.length > 0);
  }, [checked]);

  const onHandleSearchChange = (e: any) => {
    setSearchString(e.target.value);
  };

  const handleSearchSubmit = () => {
    getStatusFilters({
      containerIds: selectedContainerIds,
    });
  };

  const handleSearchClear = () => {
    setSearchString("");
    getStatusFilters({
      containerIds: selectedContainerIds,
    });
  };

  const handleCheck = (checkedValues: string[], key: string) => {
    const order = [...checked, `${key}`]
    const selectedValues = customSort(checkedValues, order)
    setChecked(selectedValues as [string])
  }

  return (
    <div>
      <FilterPopover
        children={
          <Flex direction="column">
            {/* <Search
              value={searchString}
              onChange={(e) => onHandleSearchChange(e)}
              onKeyDown={getHotkeyHandler([
                [
                  "Enter",
                  () => {
                    handleSearchSubmit();
                  },
                ],
              ])}
              onClear={handleSearchClear}
            /> */}
            {isLoading ? (
              <Flex direction="column" align="center" style={{ padding: 10 }}>
                <Loader variant="dots" />
              </Flex>
            ) : (
              <>
                <CheckboxList
                  nodes={nodes}
                  checked={checked}
                  onCheck={(checkedValues, node) => {
                    handleCheck(checkedValues, node.value)
                  }}
                  isMultilevel={false}
                  expanded={[]}
                />
                <FilterButtonPanel
                  onSubmit={() => {
                    setIsActive(checked.length > 0);
                    handleSubmit();
                    if(checkedSelectAll) {
                      handleSelectAll();
                    }
                    setModalOpen(false);
                  }}
                  onClear={() => {
                    setIsActive(false);
                    handleClear();
                    setModalOpen(false);
                  }}
                  checked={checked}
                  enableSelectAll={true}
                  checkedSelectAll={checkedSelectAll}
                  setCheckedSelectAll={setCheckedSelectAll}
                  allKeys={allKeys}
                  setSelected={setChecked as Dispatch<SetStateAction<string[] >>}
                />
              </>
            )}
          </Flex>
        }
        title={translate("Status")}
        isActive={isActive}
        icon={<IconStar size={18} />}
        opened={modalOpen}
        setOpened={setModalOpen}
        closeOnClickOutside={true}
        disabled={disabled}
      ></FilterPopover>
    </div>
  );
};

export default StatusFilter;
