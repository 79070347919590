import { createStyles } from "@mantine/core";

const useWidgetStyles = createStyles((theme) => ({
  widgetCard: {
    width: "100%",
    height: 65,
    color: "#ffffff",
    borderRadius: 0,
    "font-weight": "700",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  widgetFlex: {
    height: "100%",
  },
  titleClass: {
    "font-weight": "500",
    fontSize: "17px",
    "@media (min-width: 1920px)": {
      fontSize: "20px",
    }
  },
  countClass: {
    fontSize: "20px",
    lineHeight: 1,
    "@media (min-width: 1920px)": {
      fontSize: "25px",
    }
  },
  widgetIcon: {
    position: "absolute",
    opacity: "0.15",
    top: 8,
    right: "3%",
    "@media (min-width: 1920px)": {
      width:50,
      height:50,
    }
  },
  arrow: {
    position: "relative",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: 0,
    height: 0,
    borderLeft: "0.9rem solid transparent",
    borderRight: "0.9rem solid transparent",
    borderTop: "0.9rem solid",
  },
 
}));

export default useWidgetStyles;
