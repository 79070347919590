import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";
import { FilterBody, TableMetaData } from "../../features/RaidView";
import { MsalContext } from "@azure/msal-react";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { getAccessToken, getAuthAccessToken } from "../authService";
import { FilterBodyFilters, WidgetCount } from "../../features/RaidView/type";

export const getRAIDItems = async (
  filterData: FilterBody,
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
): Promise<{ data: TableMetaData }> => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;

  return axios({
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/search`,
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
    data: filterData,
  });
};

export const getWidgetCount = async (
  payload: WidgetCount,
  instance: IPublicClientApplication,
  accounts: AccountInfo[]
) => {
  const accessToken = await getAccessToken(instance, accounts[0]);
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/items/count`,
    headers: {
      accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      authorization: bearer,
    },
    data: payload,
  });
};
