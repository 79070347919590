import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  raidItemContainer: {
    padding: "calc(1rem/2)",
    maxWidth: "100%",
  },
  title: {
    fontWeight: 600,
    fontSize: 22,
    marginBottom: 16,
    marginTop: 20,
    color: "#1C1C1C",
  },
}));

export default useStyles;
