import { createStyles } from "@mantine/core";
import { WhiteLabelState } from "../../../whiteLabeling/types";

const useStyles = (brandingState: WhiteLabelState) => {
  const style = createStyles((theme) => ({
    textMinWidth: {
      minWidth: "100px",
    },

    userNameText: {
      color: brandingState.colors.mainFontColor || theme.colors.dark[9],
      fontWeight: 500,
      fontSize: "16px",
    },

    positionText: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "5px",
      color: brandingState.colors.secondaryFontColor || theme.colors.dark[9],
    },
  }));
  return style();
};

export default useStyles;
