import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  attachmentMain: {
    width: "100%",
    marginTop: "2rem",
    marginBottom: "2rem",
  },

  imageTd: {
    width: "3.5rem",
    "img": {
      width: "2.5rem"
    },
  },

  titleTd: {
    
  },

  iconTd: {
    width: "4rem",
    "svg:hover": {
      cursor: "pointer",
      transform: "scale(1.1)"
    }
  },

  titleLink: {
    ":hover": {
      // textDecoration: "underline",
      // cursor: "pointer"
    }
  }
}));

export default useStyles;
