import FilterPanel from "../FilterPanel/FilterPanel";
import HeaderFeature from "../Header";
import RaidTableView from "../RaidView";
import ItemViewDrawer from "../../components/common/ItemViewDrawer";
import { useState, useEffect, useCallback, SetStateAction, Dispatch, ReactElement } from "react";
import { getItemDetails } from "../../services/itemViewService/itemView";
import { MRT_RowSelectionState } from "mantine-react-table";
import { useMsal } from "@azure/msal-react";
import { useRaidListContext } from "../../contexts/raidItems.context";
import Modal from "../../components/common/Modal";
import { Button, Text } from "@mantine/core";
import { ReadOnlyFilter, ViewFilterObject, useFilterContext } from "../../contexts/filter.context";
import { PrivateView } from "../../components/SideNavigation";
import { useLocation } from "react-router";

interface CommonViewProps {
    view?: PrivateView
    initFilter?: ViewFilterObject
    customHeader?: JSX.Element
    setEnableMode?: boolean
    searchText?: string
    setSearchText?: Dispatch<SetStateAction<string>>
    onClickSearch?: number
    setOnClickSearch?: Dispatch<SetStateAction<number>>
    readonlyFilter?: ReadOnlyFilter
}

const CommonViewPage = ({ view, initFilter, setEnableMode = false, customHeader = <></>, searchText="", setSearchText, onClickSearch, setOnClickSearch, readonlyFilter }: CommonViewProps) => {
    const [opened, setOpened] = useState(false);
    const [result, setResult] = useState(null);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const { instance, accounts } = useMsal();
    const [loading, setLoading] = useState(false);
    const [onClickShowMore, setOnClickShowMore] = useState(0);
    const [isDirty, setIsDirty] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [closeType, setCloseType] = useState(0);
    const [selectedItemKey, setSelectedItemKey] = useState('');
    const { state: raidItemState } = useRaidListContext();
    const { state: filterState, dispatch } = useFilterContext();

    const location = useLocation();

    useEffect(() => {
        // Defining the event handler
        const handleBeforeUnload = (e: any) => {
            if (isDirty && !modalOpen) {
                e.preventDefault();
                e.returnValue = "";
            }
        };

        // Adding the event listener
        window.addEventListener("beforeunload", handleBeforeUnload);

        // Cleaning up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [isDirty]);

    async function onClickItem(key: string, flag?: boolean) {
        if (!opened || !isDirty || flag) {
            setLoading(true);
            setOpened(true);
            await getItemDetails(key, instance, accounts)
                .then((data) => {
                    setSelectedRow(key);
                    const modifiedData = data.data;
                    if (!modifiedData.mitigationPlan) {
                        modifiedData.mitigationPlan = "";
                    }
                    if (!modifiedData.description) {
                        modifiedData.description = "";
                    }
                    setResult(data.data);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                });
        } else {
            setSelectedItemKey(key);
            setCloseType(1);
            setModalOpen(true);
        }
    }

    function setSelectedRow(dbId: string) {
        const tableIndex: number =
            raidItemState.raidItemList
                .find((e) => e.dashboard === "control-center")
                ?.raidList.indexOf(dbId.toString()) ?? -1;

        tableIndex !== -1
            ? setRowSelection({ [tableIndex]: true })
            : setRowSelection({});
    }

    const setClose = () => {
        if (isDirty) {
            setCloseType(2);
            setModalOpen(true);
        } else {
            setOpened(false);
            setRowSelection({});
        }
    };

    const setToInitFilter = () => {
        if (initFilter) {
            dispatch({ type: "setFilters", dashboard: location.pathname, payload: initFilter });
        }
    }

    return (
        <div>
            <div id="header-and-filter">
                <HeaderFeature
                    dashboardName={view?.name ?? "control-center"}
                    displayName={view?.name ?? "Control Center"}
                    childComponent={customHeader}
                />
                <FilterPanel dashboardName={location.pathname} editMode={setEnableMode} setToInitFilter={setToInitFilter} readOnlyFilter={readonlyFilter} />
            </div>
            <div>
                <RaidTableView
                    setRowSelection={setRowSelection}
                    rowSelection={rowSelection}
                    dashboardName={location.pathname}
                    onClickItem={onClickItem}
                    onClickShowMore={onClickShowMore}
                    setOnClickShowMore={setOnClickShowMore}
                    searchText={searchText}
                    onClickSearch={onClickSearch}
                />
            </div>
            <ItemViewDrawer
                opened={opened}
                setClose={setClose}
                onClickItem={onClickItem}
                result={result}
                loading={loading}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                setOpened={setOpened}
                dashboardName={location.pathname}
            />
            {modalOpen &&
                <Modal
                    opened={modalOpen}
                    onClose={() => {
                        setModalOpen(false);
                    }}
                    title="Discard changes?"
                    size={428}
                >
                    <Text>You have unsaved changes. Are you sure you want to discard them?</Text>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                        <Button variant="outline"
                            color="gray"
                            compact
                            onClick={() => setModalOpen(false)}>
                            Keep Editing
                        </Button>
                        <Button compact
                            style={{ marginLeft: 5 }}
                            color="red"
                            onClick={() => {
                                setModalOpen(false);
                                if (closeType === 2) {
                                    setModalOpen(false);
                                    setOpened(false);
                                    setRowSelection({});
                                } else if (closeType === 1) {
                                    onClickItem(selectedItemKey, true);
                                }
                                setIsDirty(false);
                                setResult(null);
                                setCloseType(0);
                            }}>
                            Close and Discard
                        </Button>
                    </div>
                </Modal>
            }
        </div>);
};

export default CommonViewPage;
