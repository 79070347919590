export enum TypographyTypes {
  default,
  defaultUnderlined,
  defaultBold,
  mainHeading,
  title,
  tools,
  label,
}

export enum RaidTypeEnum {
  RISK = 1,
  ACTION = 2,
  ISSUE = 3,
  DEPENDENCY = 4,
}

export enum TabTypeEnum {
  DETAILS = "Details",
  ATTACHMENTS = "Attachments",
  LINKS = "Links",
  HISTORY = "History"
}