import { createStyles } from "@mantine/styles";

const useAddItemFormStyles = createStyles((theme) => ({
  iconButton: {
    width: "150px",
    height: 38,
    gap: 10,
    backgroundColor: "#3C91E6",
  },
  formRightContainer: {
    borderTop: "thick solid #e9ebf0",
    borderLeft: "thick solid #e9ebf0",
    borderWidth: 1,
    height: "content",
  },
  formLeftContainer: {
    borderTop: "thick solid #e9ebf0",
    borderWidth: 1,
    height: "content",
  },
  button: {
    width: "100%",
    height: "content",
  },
  projectSearch: {
    margin: 0,
    padding: 0,
    width: "100%",
    marginTop:"15px"
  }
}));

export default useAddItemFormStyles;
