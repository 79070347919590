import { Flex, Loader } from "@mantine/core";
import { CommentItem, CommentSectionProp, Mentions } from "./type";

import { MentionItem } from "react-mentions";
import useCommentFeatureStyles from "./styles";
import Typography from "../../components/common/Typography";
import { TypographyTypes } from "../../utils/enums";
import AppComment from "../../components/Comment";
import CommentEditor from "../../components/Comment/CommentEditor";
import { useUserContext } from "../../contexts/user.context";
import { useQuickItemViewContext } from "../../contexts/quickItemView.context";

const CommentSection = ({
  itemId,
  isNewItem,
  commentList,
  setCommentList,
  isCommentsLoading,
  isDisabled = false
}: CommentSectionProp) => {
  const { classes } = useCommentFeatureStyles();
  const { details } = useUserContext();

  const { setQkItemViewState, setResetState, qkItemViewState } = useQuickItemViewContext();

  async function addItemOnNew(
    originalText: string,
    plainText: string,
    mentions: MentionItem[]
  ) {
    if (plainText.length > 0) {
      const newCommentItem: CommentItem = {
        editPending: true,
        comment: plainText,
        originalText: originalText,
        author: details.displayName,
        createdOn: new Date().toISOString(),
        isModified: false,
        isDeleted: false,
        isNewComment: true,
        mentions: mentions.map((mention) => {
          return {
            id: mention.id,
            name: mention.display,
          };
        }),
      };

      setCommentList &&
        setCommentList([...(commentList ?? []), newCommentItem]);

      setResetState();
    }
  }

  async function onEditComment(
    isNewComment: boolean,
    commentId: number,
    originalText: string,
    plainText: string,
    mentions: MentionItem[]
  ) {
    if (isNewComment) {
      const editedCommentItem: CommentItem = {
        comment: plainText,
        originalText: originalText,
        author: details.displayName,
        createdOn: new Date().toISOString(),
        isModified: false,
        isDeleted: false,
        isNewComment: true,
        editPending: true,
        mentions: mentions.map((mention) => {
          return {
            id: mention.id,
            name: mention.display,
          };
        }),
      };

      setCommentList &&
        setCommentList(
          (prevArray) => {
            const updatedItems = [...prevArray];
            updatedItems[commentId] = editedCommentItem;
            return updatedItems;
          }
        );
    } else {
      setCommentList &&
        setCommentList((prevArray) =>
          prevArray.map((item, idx) =>
            item.commentId === commentId ? getEditedComment(item) : item
          )
        );

      const getEditedComment = (prevItem: CommentItem): CommentItem => {
        var fullArray: Mentions[] =
          prevItem.mentions?.map((item) => {
            return { ...item, isDeleted: true };
          }) ?? [];

        mentions.forEach((editorMention) => {
          var index = prevItem.mentions?.findIndex((prevItemMention) => {
            return prevItemMention.id == editorMention.id;
          });
          if (index === -1) {
            fullArray.push({
              id: editorMention.id,
              name: editorMention.display,
            });
          } else {
            fullArray = fullArray.map((fullArrayItem) => {
              if (fullArrayItem.id == editorMention.id) {
                return { ...fullArrayItem, isDeleted: false };
              }
              return fullArrayItem;
            });
          }
        });

        return {
          commentId: commentId,
          comment: plainText,
          originalText: originalText,
          author: details.displayName,
          createdOn: new Date().toISOString(),
          isModified: true,
          isDeleted: false,
          editPending: true,
          mentions: fullArray,
        };
      };
    }

    setResetState();
  }

  async function onDeleteComment(isNewComment: boolean, index: number) {
    if (isNewComment) {
      const _commentList = JSON.parse(JSON.stringify(commentList));
      setCommentList &&
        setCommentList(
          _commentList.filter((_: CommentItem, i: number) => i !== index)
        );
    } else {
      setCommentList &&
        setCommentList((prevArray) =>
          prevArray.map((comment) => {
            if (comment.commentId == index) {
              return { ...comment, editPending: true, isDeleted: true };
            }
            return comment;
          })
        );
    }
  }

  const setIsCommentBoxEmptyHandler = (value: boolean) => {
    setQkItemViewState((prev) => ({
      ...prev,
      isCommentBoxEmpty: value
    }))
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Typography
        type={TypographyTypes.default}
        className={classes.heading}
        text={"Discussion"}
      />
      {!isDisabled && <CommentEditor
        onSubmit={addItemOnNew}
        comment={qkItemViewState.commentBoxValue}
        mentions={qkItemViewState.commentBoxMentions}
        plainText={qkItemViewState.commentBoxPlainTextValue}
        commentList={commentList}
        onChangeHandler={(x) => {
          setQkItemViewState((prev) => ({
            ...prev,
            commentBoxValue: x.originalText,
            commentBoxPlainTextValue: x.plainText,
            commentBoxMentions: x.mentions
          }))
        }}
      />}
      {isNewItem ? (
        <Flex direction={"column-reverse"}>
          {commentList?.map((comment, i) => {
            return (
              <AppComment
                key={i}
                index={i}
                onEditComment={onEditComment}
                onDeleteComment={onDeleteComment}
                {...comment}
                setIsCommentBoxEmpty={setIsCommentBoxEmptyHandler}
              />
            );
          })}
        </Flex>
      ) : (
        <>
          {isCommentsLoading ? (
            <Flex direction="column" align="center" style={{ padding: 10 }}>
              <Loader variant="dots" />
            </Flex>
          ) : (
            <Flex direction={"column-reverse"}>
              {commentList &&
                commentList.map((comment, i) => {
                  if (!comment.isDeleted) {
                    return (
                      <AppComment
                        key={i}
                        index={i}
                        {...comment}
                        onEditComment={onEditComment}
                        onDeleteComment={onDeleteComment}
                        isDisabled= {isDisabled}
                      />
                    );
                  }
                })}
            </Flex>
          )}
        </>
      )}
    </div>
  );
};

export default CommentSection;
