import { useEffect, useState } from "react";
import { getItemDetails } from "../../services/itemViewService/itemView";
import { useMsal } from "@azure/msal-react";
import QuickItemView from "../../features/QuickItemView";
import { ActionIcon, Button, Flex, Modal, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { IconX } from "@tabler/icons-react";

const ItemPage = () => {

  let pageURL = window.location.href.toString();
  let urlContent = pageURL.split("/");
  let key = urlContent.slice(-1)[0];

  const [result, setResult] = useState(null);
  const { instance, accounts } = useMsal();
  const [isDirty, setIsDirty] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false); // Flag to track redirection

  useEffect(() => {
    // Defining the event handler
    const handleBeforeUnload = (e:any) => {
      if (isDirty && !modalOpen) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    // Adding the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleaning up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty, isRedirecting]);


  async function getData() {
    await getItemDetails(key, instance, accounts)
      .then((data) => {
        setResult(data.data);
      })
      .catch((err) => console.log(err));
  };
  // eslint-disable-next-line no-lone-blocks, @typescript-eslint/no-unused-expressions
  { result === null ? getData() : null }

  function redirect() {
  setIsRedirecting(true);
  setTimeout(() => {
    var url = "/";
    window.location.assign(url);
    setModalOpen(false);
  }, 200);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Flex justify="flex-end" align="flex-start">
        <ActionIcon>
          <IconX
            size="20"
            onClick={() =>
              isDirty ? setModalOpen(true) : redirect()
            }
          />
        </ActionIcon>
      </Flex>
      {result && <QuickItemView setIsDirty={setIsDirty} isDirty={isDirty} result={result} redirect={redirect} isDrawer={false} />}
      {modalOpen &&
        <Modal
          opened={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
          title="Discard changes?"
          size={428}
        >
          <Text>You have unsaved changes. Are you sure you want to discard them?</Text>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
            <Button variant="outline"
              color="gray"
              compact
              onClick={() => setModalOpen(false)}>
              Keep Editing
            </Button>
            <Button compact
              style={{ marginLeft: 5 }}
              color="red"
              onClick={() => {
                setIsDirty(false)
                redirect()
              }}>
              Close and Discard
            </Button>
          </div>
        </Modal>
      }
    </div>
  );
};

export default ItemPage;
