import { axiosMockAdapterInstance as mock } from "../axiosInstance";
import mockDataOwnerFilter from "./data/mock-owner-filters-api.json";
import envVariables from "../utils/config.json";

const mockGetOwnerFilters = mock
  .onGet(`${process.env.REACT_APP_APPLICATION_URL || envVariables.REACT_APP_APPLICATION_URL}/ownerFilter`)
  .reply(function () {
    return [200, mockDataOwnerFilter.ownerList];
  });

const mockSearchOwner = mock
  .onGet(`${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/search/owner`)
  .reply(function () {
    return [200, mockDataOwnerFilter.ownerList];
  });

export { mockGetOwnerFilters, mockSearchOwner };
