import { Card,Grid  } from "@mantine/core";
import { WidgetProps } from "./type";
import useStyles from "./styles";
import {
  IconAlertCircle,
  IconAlertTriangle,
  IconClipboardCheck,
  IconFileDescription,
  IconPackageImport,
} from "@tabler/icons-react";
import { ReactNode } from "react";

const Widget = ({
  title,
  count,
  backgroundColor,
  activeBackgroundColor,
  icon,
  isActive,
}: WidgetProps) => {
  const { classes } = useStyles();

  const getIconComponent = (): ReactNode => {
    switch (icon) {
      case "all":
        return (
          <IconFileDescription
            className={classes.widgetIcon}
            size={45}
            data-testid="widget-icon-all"
          />
        );
      case "action":
        return (
          <IconClipboardCheck
            className={classes.widgetIcon}
            size={45}
            data-testid="widget-icon-action"
          />
        );
      case "issue":
        return (
          <IconAlertTriangle
            className={classes.widgetIcon}
            size={45}
            data-testid="widget-icon-issue"
          />
        );
      case "risk":
        return (
          <IconAlertCircle
            className={classes.widgetIcon}
            size={45}
            data-testid="widget-icon-risk"
          />
        );
      case "depend":
        return (
          <IconPackageImport
            className={classes.widgetIcon}
            size={45}
            data-testid="widget-icon-depend"
          />
        );
      default:
        return (
          <IconFileDescription
            className={classes.widgetIcon}
            size={45}
            data-testid="widget-icon-default"
          />
        );
    }
  };

  return (
    <div>
      <Card
        className={classes.widgetCard}
        style={{
          backgroundColor: isActive ? activeBackgroundColor : backgroundColor,
          boxShadow: isActive
            ? "0px 4px 14px rgba(54, 54, 54, 0.25)"
            : undefined,
        }}
      >
        <Grid gutterXs="lg" style={{ display: "flex" }}>
          <Grid.Col span={"content"}>
            <span className={classes.titleClass}>{title}</span>
          </Grid.Col>
          <Grid.Col
            span={"auto"}
            style={{ textAlignLast: "end", marginRight: "8%" }}
          >
            <span className={classes.countClass}>{count}</span>
          </Grid.Col>
          <Grid.Col span={"content"}>
            <span>{getIconComponent()}</span>
          </Grid.Col>
        </Grid>
      </Card>
      {isActive && (
        <div
          className={classes.arrow}
          style={{
            borderTopColor: activeBackgroundColor,
          }}
        />
      )}
    </div>
  );
};

export { Widget };
