import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";

export interface nofiticationProps {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPrevious: boolean;
  hasNext: boolean;
  items: any[];
}

export enum NotificationModesEnum {
  Email = 1,
  Web = 2,
}

export enum NotificationTypesEnum {
  all = 0,
  item_assignment_email = 1,
  item_assignment_web = 2,
  item_mention_email = 3,
  item_mention_web = 4,
  item_update_email = 5,
  item_update_web = 6,
}

export const getNotifications = (
  type: number,
  pageNumber: number,
  pageSize: number,
  token: any
): Promise<{ data: nofiticationProps }> => {
  const bearer = `Bearer ${token}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/notification`,
    headers: { authorization: bearer },
    params: {
      type,
      pageNumber,
      pageSize,
    },
  });
};

export const getNotificationCount = (
  token: any
): Promise<{ data: any }> => {
  const bearer = `Bearer ${token}`;
  return axios({
    method: "GET",
    url: `${
      process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL
    }/items/notifications/count`,
    headers: { authorization: bearer },
    params: {
      notificationMode: NotificationModesEnum.Web,
      isSeen: false,
    },
  }).catch((error) => {
    throw error;
  });
};

export const markAllNotificationsAsRead = async (accessToken: any) => {
  const bearer = `Bearer ${accessToken}`;
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/notification/mark-all-as-read`,
    headers: { authorization: bearer },
  });
}

export const markNotificationAsRead = async (notificationId: any, accessToken: any) => {
  const bearer = `Bearer ${accessToken}`;
  return await axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/notification/mark-as-read`,
    headers: { authorization: bearer },
    params: { notificationId },
  });
}
