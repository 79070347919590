import { axiosMockAdapterInstance as mock } from "../axiosInstance";
import mockDataProjectFilter from "./data/mock-project-filters-api.json";
import envVariables from "../utils/config.json";

const mockGetProjectFilters = mock
  .onGet(`${process.env.REACT_APP_APPLICATION_URL || envVariables.REACT_APP_APPLICATION_URL}/projectFilter`)
  .reply(function () {
    return [200, mockDataProjectFilter.projectList];
  });

export { mockGetProjectFilters };
