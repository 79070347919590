import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";
import { OwnerFilterAPIItem } from "../../components/Filters/OwnerFilter/types";

export interface IOwnerFilterAPIParams {
  containerIds: string[];
  search: string;
}

export const getOwnerFilterList = (requestBody: IOwnerFilterAPIParams, token: string) => {
  const bearer = `Bearer ${token}`;
  return axios<OwnerFilterAPIItem[]>({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/filters/item-owners`,
    data: requestBody,
    headers: { authorization: bearer },
  });
};

export const searchOwner = () => {
  return axios<OwnerFilterAPIItem[]>({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/search/owner`,
  });
};
