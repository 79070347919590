import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  btnPannelWrapper: {
    padding: "0.6rem",
    background: "#f5f1f1",
  },
  btnPanelError:{
    color: "#EA0707",
    fontSize: 14,
    paddingBottom: 10,
  },
  btnPanel: {
    background: "#f5f1f1",
  },
}));

export default useStyles;
