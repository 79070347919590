const mentionInputStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 16,
  },
  "&multiLine": {
    control: {
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      border: "0.5px solid silver",
    },
    "input:hover": {
      padding: 9,
      border: "0.5px solid silver",
    },
  },
  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 16,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

const mentionStyle = { backgroundColor: "#cee4e5" };

export { mentionInputStyle,mentionStyle };
