import React, { useContext } from "react";
import { useReducer } from "react";

interface IAddItemSF {
  title: string;
  project: string;
  projectId: string;
  type: string;
  owner: string;
  ownerId: string;
  status: string;
  targetDate: string;
  labels: string[];
}

interface AddItemState {
  shortForm: IAddItemSF;
}

type AddItemAction =
  | { type: "setAddItemSFData"; payload: IAddItemSF }
  | { type: "clearAddItemSFData" };

type AddItemDispatch = (action: AddItemAction) => void;
const AddItemContext = React.createContext<
  { state: AddItemState; dispatch: AddItemDispatch } | undefined
>(undefined);

function addItemReducer(state: AddItemState, action: AddItemAction) {
  switch (action.type) {
    case "setAddItemSFData": {
      return { ...state, shortForm: action.payload };
    }
    case "clearAddItemSFData": {
      return {
        ...state,
        shortForm: {
          title: "",
          project: "",
          projectId: "",
          type: "",
          owner: "",
          ownerId: "",
          targetDate: "",
          status: "",
          labels: [],
        },
      };
    }
  }
}

const initialState: AddItemState = {
  shortForm: {
    title: "",
    project: "",
    projectId: "",
    type: "",
    owner: "",
    ownerId: "",
    targetDate: "",
    status: "",
    labels: [],
  },
};

function AddItemProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(addItemReducer, initialState);
  const value = { state, dispatch };

  return (
    <AddItemContext.Provider value={value}>{children}</AddItemContext.Provider>
  );
}

const useAddItemContext = () => {
  const context = useContext(AddItemContext);
  if (!context) {
    throw new Error("useAddItemContext must be used within a DataProvider");
  }
  return context;
};

export { useAddItemContext, AddItemProvider };
