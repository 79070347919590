import { TextInput, TextInputProps, ActionIcon } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons-react";
import { ISearchProps } from "./type";

const Search = (props: TextInputProps & ISearchProps) => {
  const {
    placeholder = "Search",
    icon = <IconSearch data-testid="search-icon" size={14} />,
    onClear,
    value,
    ...rest
  } = props;
  return (
    <TextInput
      placeholder={placeholder}
      icon={icon}
      value={value}
      {...rest}
      rightSection={
        <ActionIcon variant="transparent">
          <IconX size={16} onClick={onClear ? () => onClear() : undefined} />
        </ActionIcon>
      }
    />
  );
};

export default Search;
