import { IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "../../axiosInstance";
import {
  authRequest,
  commonRequest,
  graphConfig,
  msalConfig,
} from "./authConfig";

const tokenHandler = async(instance: IPublicClientApplication, type: 'auth' | 'common') => {
  const accounts = instance.getAllAccounts();
	let token = '';

	// async iterator
  for await (const account of accounts) {
    try {
      const silentRequest = {
        scopes: type === 'common'? commonRequest.scopes : authRequest.scopes,
        account: account,
      };

      const response = await instance.acquireTokenSilent(silentRequest);
      token = response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const interactiveRequest = {
          scopes: commonRequest.scopes,
          account: account,
        };

        const response = await instance.acquireTokenPopup(interactiveRequest);
        token = response.accessToken;
      }
    }
  }

	return token;
}

const getAccessToken = async (
	instance: IPublicClientApplication,
	account: any
) => await tokenHandler(instance, 'common');

const getAuthAccessToken = async (
  instance: IPublicClientApplication,
  account: any
) => await tokenHandler(instance, 'auth');

const getUserDetails = async (accessToken: any) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: graphConfig.graphMeEndpoint,
    headers: { authorization: bearer },
  });
};

const getUserImage = async (accessToken: any) => {
  try {
    const bearer = `Bearer ${accessToken}`;

    const response = await axios({
      method: "GET",
      url: graphConfig.graphMeImageEndpoint,
      headers: { authorization: bearer },
      responseType: "blob",
    });

    const blob = await response.data;

    const imageURL = URL.createObjectURL(blob);
    return { data: imageURL };
  } catch (error) {
    console.log(error);
  }
};

export {
  authRequest,
  commonRequest,
  msalConfig,
  getAccessToken,
  getAuthAccessToken,
  getUserDetails,
  getUserImage
};
