import { Button, Modal } from "@mantine/core";
import { useTranslation } from "react-i18next";
import UserAvatar from "../UserAvatar";
import { Dispatch, SetStateAction } from "react";
import { AvatarGroupItems } from "../AvatarGroup";
import useStyles from "./style";
import { IconUserStar, IconUsersGroup } from "@tabler/icons-react";

export interface AvatarGroupModalProps {
    modalOpen: boolean
    setModalOpen: Dispatch<SetStateAction<boolean>>;
    userAndTeamsList: AvatarGroupItems[];
}


const AvatarGroupModal = ({ modalOpen, setModalOpen, userAndTeamsList }: AvatarGroupModalProps) => {
    const { classes } = useStyles()
    return <Modal
        opened={modalOpen}
        onClose={() => {
            setModalOpen(false);
        }}
        title="Members"
        size={428}
        className={classes.avatarGroupModal}
    >
        {
            userAndTeamsList.map((e) =>
                <UserAvatar
                    name={e.name}
                    position={e.groupMembers ? `${e.groupMembers} Members` : (e.isOwner) ? "Owner" : "User"}
                    avatarAlt={e.groupMembers ?
                        <IconUsersGroup /> :
                        (e.isOwner) ? <IconUserStar /> : ""} avatarUrl={""} ></UserAvatar>
            )
        }
    </Modal>
}

export default AvatarGroupModal;