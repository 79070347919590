import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    mainDiv: {
        paddingTop: 10,
        paddingBottom: 20,
        borderBottom: '1px solid #E4E8EE'
    },
    subDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textDiv: {
        display: 'flex',
        flexDirection: 'row',
        height: 40
    },
    avatarDiv: {
        width: 64
    },
    unreadDiv: {
        width: 8,
        height: 8,
        background: 'red',
        borderRadius: 8
    },
    timeDiv: {
        display: 'flex',
        alignItems: 'center'
    },
    commentMainDiv: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    commentSubDiv: {
        background: '#DDDEE1',
        width: 5,
        height: 20,
        marginLeft: 64,
        marginRight: 10
    },
    titleText: {
        marginLeft: 5,
        fontWeight: 'bold',
        cursor: "pointer",
        color: "#1c7ed6",
        "&:hover": {
            textDecoration: 'underline'
        }
    }
}));

export default useStyles;
