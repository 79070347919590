import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import "./index.css";
import "./localization/i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./services/authService";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { CountProvider } from "./contexts/sample.context";

import "./__mock__/Mock-period-filter-api.ts";
import "./__mock__/Mock-owner-filter-api.ts";
import "./__mock__/Mock-tag-filter-api.ts";
import "./__mock__/Mock-status-filter-api.ts";
import "./__mock__/Mock-project-filter-api.ts";
// import "./__mock__/Mock-item-details-api.ts";
import "./__mock__/Mock-raid-items.ts";
import "./__mock__/Mock-default-api.ts";  //keep this at the bottom

const container = document.getElementById("root")!;
const root = createRoot(container);

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

