import { Indicator } from "@mantine/core";
import { DatePicker as DatePickerMM } from '@mantine/dates';
import { MDatePickerProps } from "./types";

const DatePicker = ({ ...props }: MDatePickerProps) => {
    return (
        <DatePickerMM
            data-testid="date-picker"
            type="range"
            renderDay={(date) => {
                const day = date.getDate();
                const currentDate = new Date()
                return (
                    <Indicator size={6} color='#344054' offset={-4} position='bottom-center' disabled={date.toDateString() !== currentDate.toDateString()}>
                        <div className='day'>{day}</div>
                    </Indicator>
                );
            }}
            styles={{
                day: {
                    color: "#344054",
                    '&[data-weekend]': {
                        color: "#344054"
                    },
                    '&[data-selected] div': {
                        backgroundColor: "#083D63"
                    },
                    '&[data-first-in-range]': {
                        borderTopLeftRadius: "5rem",
                        borderBottomLeftRadius: "5rem",
                        color: "#ffffff",
                        backgroundColor: "#E0EFFF"
                    },
                    '&[data-first-in-range]:hover': {
                        backgroundColor: "#E0EFFF"
                    },
                    '&[data-first-in-range] div': {
                        borderRadius: "5rem",
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                        color: "#ffffff"
                    },
                    '&[data-first-in-range] div .day': {
                        paddingTop: 8,
                    },
                    '&[data-first-in-range] div .mantine-Indicator-common': {
                        display: "none"
                    },
                    '&[data-last-in-range]': {
                        borderTopRightRadius: "5rem",
                        borderBottomRightRadius: "5rem",
                        color: "#ffffff",
                        backgroundColor: "#E0EFFF"
                    },
                    '&[data-last-in-range]:hover': {
                        backgroundColor: "#E0EFFF"
                    },
                    '&[data-last-in-range] div': {
                        borderRadius: "5rem",
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                        color: "#ffffff"
                    },
                    '&[data-last-in-range] div .day': {
                        paddingTop: 8,
                    },
                    '&[data-last-in-range] div .mantine-Indicator-common': {
                        display: "none"
                    },
                },
            }}
            {...props}
        />
    )
}

export default DatePicker