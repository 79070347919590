import { createStyles } from "@mantine/core";

const useQuickItemViewStyles = createStyles((theme) => ({
  ticketType: {
    fontSize: 20,
    fontWeight: 600,
    color: "#616161",
    paddingBottom: 0,
    fontFamily: "Roboto, sans-serif",
  },
  main: {
    fontSize: 32,
    fontWeight: 600,
    color: "#000000",
    paddingTop: 0,
    fontFamily: "Roboto, sans-serif",
    lineHeight: 'normal',
  },
  labelsText: {
    fontSize: 13,
    fontWeight: 600,
    color: "#616161",
    paddingBottom: 0,
    fontFamily: "Roboto, sans-serif",
  },
  pb0: {
    paddingBottom: 0,
  },
  pt0: {
    paddingTop: 0,
  },
  addLabelBtn: {
    fontSize: 12,
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Roboto, sans-serif",
    height: "fit-content",
    width: "fit-content",
    backgroundColor: "#F5F5F5",
    borderRadius: 0,
    padding: 5,
    marginLeft: 60,

    "&:hover":{
      backgroundColor: "#F5F5F5",
    }
  },
  buttonMenu: {
    "button": {
      backgroundColor: theme.colors.blue[6],
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeft: "1px solid white"
    },
  },
  buttonMenuDisabled: {
    "button": {
      backgroundColor: theme.colors.gray[2],
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeft: "1px solid white"
    },
    "button:hover": {
      backgroundColor: theme.colors.gray[2],
      cursor:"default"
    },
  },
  saveButton: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
  },
  titleInput: {
    "input": {
      fontSize: 20,
      fontWeight: 600,
      color: "#000000",
      fontFamily: "Roboto, sans-serif",
      padding: "5px 10px",
    }
  }.input
}));

export default useQuickItemViewStyles;
