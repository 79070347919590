import { Group } from "@mantine/core";
import React from "react";
import { FilterTagProps } from "./type";
import { CloseButton } from "@mantine/core";
import useFilterTagStyles from "./styles";
import {getFilterColor} from "../../../utils/functions/getFilterColor";

const FilterTag = ({ icon, name, onClose, filterType, hideClose = false }: FilterTagProps) => {
  const { classes, cx } = useFilterTagStyles();
  return (
    <Group className={classes.FilterTagWrapper} style={{backgroundColor: filterType ? getFilterColor(filterType) : undefined}}>
      {icon}
      {name}
      {!hideClose && <CloseButton variant="transparent" color="red" onClick={onClose} />}
    </Group>
  );
};

export default FilterTag;
