import { Avatar, Box, Tooltip } from "@mantine/core";
import useStyles from "../style";
import { OwnerLabelProps } from "../type";

const OwnerLabel = ({ imageUrl, color, name }: OwnerLabelProps) => {
  const { classes, cx } = useStyles();
  return (
    <Tooltip label={name}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "15px",
        }}
      >
        <span>{name.split(" ")?.[0]}</span>
      </Box>
    </Tooltip>
  );
};

export { OwnerLabel };
