import * as React from "react";
import { getPrivateViews } from "../services/PrivateViewService/PrivateView";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { PrivateView, PublicView, PublicViews } from "../components/SideNavigation/types";
import { getPublicViews } from "../services/PublicViewService/PublicView";

type Action = { type: "setPrivateViews", payload?: PrivateView[] } |
{ type: "setPublicViews", payload?: PublicView[] } |
{ type: "editMode", payload?: boolean } |
{ type: "setState", payload: "IDEAL" | "LOADED" | "ERROR" | "LOADING" };
type Dispatch = (action: Action) => void;
type State = {
    privateViewState: PrivateView[];
    publicViewState: PublicView[];
    editMode: boolean;
    state: "IDEAL" | "LOADED" | "ERROR" | "LOADING"
};

type ViewsProviderProps = { children: React.ReactNode };

const ViewsStateContext = React.createContext<
    { state: State; dispatch: Dispatch } | undefined
>(undefined);

function viewsReducer(state: State, action: Action) {
    switch (action.type) {
        case "setPrivateViews": {
            return { ...state, privateViewState: action.payload ?? [] };
        }
        case "setPublicViews": {
            return { ...state, publicViewState: action.payload ?? [] };
        }
        case "editMode": {
            return { ...state, editMode: action.payload ?? false };
        }
        case "setState": {
            return { ...state, state: action.payload }
        }
        default: {
            throw new Error(`Unhandled action type`);
        }
    }
}

function ViewsProvider({ children }: ViewsProviderProps) {
    const [state, dispatch] = React.useReducer(viewsReducer, {
        privateViewState: [],
        publicViewState: [],
        editMode: false,
        state: "IDEAL"
    });
    
    const value = { state, dispatch };
    return (
        <ViewsStateContext.Provider value={value}>
            {children}
        </ViewsStateContext.Provider>
    );
}

async function updateViewsAsync(dispatch: Dispatch, action: Action | null, instance: IPublicClientApplication, accounts: AccountInfo[]) {
    try {
        dispatch({type:"setState", payload: "LOADING"})
        if (action?.type == "setPrivateViews") {
            const views = await getPrivateViews(instance, accounts);
            dispatch({ type: "setPrivateViews", payload: views.data });
        } else if (action?.type == "setPublicViews") {
            const publicViews = await getPublicViews(instance, accounts);
            dispatch({ type: "setPublicViews", payload: publicViews.data });
        } else {
            const privateViews = await getPrivateViews(instance, accounts);
            dispatch({ type: "setPrivateViews", payload: privateViews.data });
            const publicViews = await getPublicViews(instance, accounts);
            dispatch({ type: "setPublicViews", payload: publicViews.data });
        }
        dispatch({type:"setState", payload: "LOADED"})
    } catch (error) {
        dispatch({type:"setState", payload: "ERROR"})
     }
}


async function updateEditMode(dispatch: Dispatch, action: Action) {
    try {
        if (action.type == "editMode") {
            dispatch({ type: "editMode", payload: action.payload });
        }
    } catch (error) { }
}

function useViews() {
    const context = React.useContext(ViewsStateContext);
    if (context === undefined) {
        throw new Error("useCount must be used within a CountProvider");
    }
    return context;
}

export { ViewsProvider, useViews, updateViewsAsync, updateEditMode };