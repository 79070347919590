import { Avatar, Tooltip } from "@mantine/core";
import { IconUserStar, IconUsersGroup } from "@tabler/icons-react";
import { IconGraph, IconUsers } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import useStyles from "./style";
import AvatarGroupModal from "../AvatarGroupModal";

export interface AvatarGroupProps {
    avatarList: AvatarGroupItems[]
    showAvatars?: number
}

export interface AvatarGroupItems {
    name: string
    id: number
    groupMembers?: any[]
    isOwner?: boolean
    img?: string
}

const AvatarGroup = ({ avatarList, showAvatars = 3 }: AvatarGroupProps) => {

    const { classes } = useStyles();

    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const AvatarItem = ({ name, img, groupMembers, isOwner = false }: AvatarGroupItems) => {
        return <Tooltip label={name} withArrow>
            {!(groupMembers) ?
                <Avatar src={img} radius="xl" color="blue">
                    {(isOwner) ?
                        <IconUserStar /> : name?.slice(0, 1) + name?.split(" ")?.[1]?.slice(0, 1)}
                </Avatar>
                : <Avatar radius="xl" color="blue">
                    <IconUsersGroup />
                </Avatar>}
        </Tooltip>
    }

    return (
        <div className={classes.avatarGroup}>
            <Tooltip.Group openDelay={300} closeDelay={100}>
                <Avatar.Group spacing="xs" onClick={() => setModalOpen(!modalOpen)}>
                    {
                        avatarList?.slice(0, showAvatars).map((avatar, i) => <AvatarItem {...avatar} />)
                    }
                    {(avatarList.length > showAvatars) && <Tooltip
                        withArrow
                        label={
                            <>
                                {avatarList?.slice(showAvatars, avatarList.length).map((avatar) => <div>{avatar.name}</div>)}
                            </>
                        }
                    >
                        <Avatar radius="xl" color="blue">+{avatarList.length - showAvatars}</Avatar>
                    </Tooltip>}
                </Avatar.Group>
            </Tooltip.Group>
            <AvatarGroupModal modalOpen={modalOpen} setModalOpen={setModalOpen} userAndTeamsList={avatarList} />
        </div>

    );
}

export default AvatarGroup;