import { Popover, Button } from "@mantine/core";
import useStyles from "./style";
import { FilterPopoverProps } from "./type";
import { IconChevronDown } from "@tabler/icons-react";
import { useState } from "react";
import useScreenSize from "../../../utils/functions/screenSize";
import {getFilterColor} from "../../../utils/functions/getFilterColor";

const FilterPopover = ({
  children,
  title,
  icon,
  isActive,
  opened,
  setOpened,
  closeOnClickOutside,
  disabled,
}: FilterPopoverProps) => {
  const { classes, cx } = useStyles();

  const [modalOpened, setModalOpened] = useState(false);
  const screenSize = useScreenSize();

  if (opened === undefined && setOpened === undefined) {
    opened = modalOpened;
    setOpened = setModalOpened;
  }

  return (
    <Popover
      width={
        (title === "Project" && screenSize.width < 1920)
          ? 300
          : screenSize.width < 1920 ? 250 : "target"
      }
      position="bottom"
      offset={{
        mainAxis: 5,
        crossAxis:  0,
        alignmentAxis: null,
      }}
      withArrow
      shadow="md"
      opened={opened}
      onChange={setOpened}
      closeOnClickOutside={closeOnClickOutside}
    >
      <Popover.Target>
        <Button
          leftIcon={icon}
          rightIcon={<IconChevronDown data-testid="icon-chevron-down" />}
          data-testid="popover-trigger"
          className={cx(classes.btnLabel, isActive && classes.btn_Active)}
          loaderPosition="left"
          variant={isActive ? "filled" : "outline"}
          onClick={() => setOpened(!opened)}
          disabled={disabled}
          style={{borderColor: isActive ? title ? getFilterColor(title) : "#e3e3e3" : "#e3e3e3"}}
        >
          {title}
        </Button>
      </Popover.Target>
      <Popover.Dropdown className={classes.popOverDropDown}>
        {children}
      </Popover.Dropdown>
    </Popover>
  );
};

export default FilterPopover;
