import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    assignedToMeNode: {
        paddingBottom: 3,
        borderBottom: "1px solid #ced4da"
    },
    secondNode: {
        marginTop: 1
    }
}));

export default useStyles;
