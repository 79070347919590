import {
  Button,
  Flex,
  Grid,
  Loader,
  Group,
  Menu,
  ActionIcon,
  TextInput,
  Textarea,
} from "@mantine/core";
import {
  IconTrash,
  IconBookmark,
  IconCalendar,
  IconChevronDown,
} from "@tabler/icons-react";
import Typography from "../../components/common/Typography";
import { TabTypeEnum, TypographyTypes } from "../../utils/enums";
import useQuickItemViewStyles from "./styles";
import TabGroup from "../../components/common/TabGroup";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import CommentSection from "../../features/CommentSection";
import { QuickItemForm } from "../../components/QuickItemView/Form/QuickItemForm";
import Details from "../../components/QuickItemView/Details/Details";
import HistoryTab from "../../components/QuickItemView/History/HistoryTab";
import AddLabel from "../../components/AddLabel/AddLabel";
import LabelItem from "../../components/AddLabel/LabelItem";
import { useProjectContext } from "../../contexts/project.context";
import { DataProps } from "../../components/common/CheckboxHierarchy";
import ItemAttachments from "../../components/AddItem/ItemDetails/ItemAttachments";
import {
  ItemComment,
  ItemReq,
  LabelGet,
  UpdateLabel,
  QuickItemViewProps,
} from "./type";
import {
  getComments,
  updateRaidItem,
} from "../../services/itemViewService/itemView";
import ShowToastMsg, {
  ToastSensitivity,
} from "../../utils/functions/showToastMsg";
import { getAccessToken } from "../../services/authService";
import { getLabelOptions } from "../../services/AddItemService/addItems";
import { useMsal } from "@azure/msal-react";
import { LabelData } from "../../components/AddLabel/type";
import { CommentItem } from "../CommentSection/type";
import { IRemovedFile, IUploadedFile } from "../AttachmentSection/type";
import { useHistoryContext } from "../../contexts/history.context";
import { useRefreshRaidListContext } from "../../contexts/listViewRefresh.context";
import { useNotificationsContext } from "../../contexts/notifications.context";
import { MentionItem } from "react-mentions";
import { useUserContext } from "../../contexts/user.context";
import { useQuickItemViewContext } from "../../contexts/quickItemView.context";

const QuickItemView = ({
  result,
  setIsDirty,
  isDirty,
  setOpened,
  redirect,
  isDrawer,
  dashboardName = "control-center",
}: QuickItemViewProps) => {
  const { t } = useTranslation();
  const { classes, cx } = useQuickItemViewStyles();
  const {
    status: projectStatus,
    Projects,
    fetchProjectList,
  } = useProjectContext();

  const { instance, accounts } = useMsal();
  const { fetchHistory, historyStatus, history } = useHistoryContext();
  const { details } = useUserContext();
  const { dispatch: refreshItemsDispatch, state: refreshItemsState } =
    useRefreshRaidListContext();
  const { notificationCount, fetchNotifications } = useNotificationsContext();

  const { qkItemViewState, setResetState: resetQkItemViewState } =
    useQuickItemViewContext();
  const {
    isCommentBoxEmpty,
    commentBoxValue,
    commentBoxMentions,
    commentBoxPlainTextValue,
    isCommentBoxUpdated,
  } = qkItemViewState;

  const tabData = ["Details", "Attachments", "History"];
  const [labelData, setLabelData] = useState<LabelData[]>([]);
  const [comments, setComments] = useState<CommentItem[]>([]);
  const [originalComments, setOriginalComments] = useState<CommentItem[]>([]);
  const [isCommentsLoading, setIsCommentsLoading] = useState<boolean>(false);
  const [isCommentsEdited, setIsCommentsEdited] = useState<boolean>(false);
  const [searchString, setSearchString] = useState("");
  const [activeTab, setActiveTab] = useState<string | null>(tabData[0] || null);
  const [labelChecked, setLabelChecked] = useState<string[]>([]);
  const [projectData, setProjectData] = useState<DataProps[]>([]);
  const [projectSelected, setProjectSelected] = useState<string[]>([]);
  const [isLabelsLoading, setIsLabelsLoading] = useState<boolean>(false);
  const [createdLabels, setCreatedLabels] = useState<string[]>([]);
  const [updateLabels, setUpdateLabels] = useState<UpdateLabel[]>([]);
  const [newLabels, setNewLabels] = useState<UpdateLabel[]>([]);
  const [isUpdatePending, setIsUpdatePending] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([]);
  const [removedFileList, setRemovedFileList] = useState<IRemovedFile[]>([]);
  const [title, setTitle] = useState<string>(result?.title);
  const [isLabelsUpdated, setIsLabelsUpdated] = useState<boolean>(false);

  useEffect(() => {
    getLabels();
  }, []);

  useEffect(() => {
    !isDrawer && setProjectData(Projects);
  }, [projectStatus]);

  useEffect(() => {
    setProjectData(Projects);
  }, []);

  useEffect(() => {
    fetchProjectList();
    const project = result.containerId.toString();
    if (project) setProjectSelected([project]);
    resetForm(result);
    setLabelChecked(
      result.labels.map(
        (e: { id: number; itemId: number; itemName: string }) => e.itemName
      )
    );
    getCommentsList(result.id);
    fetchHistory(result.id);
    setTitle(result.title);
  }, [result]);

  useEffect(() => {
    const prevChecked = result.labels;
    const removedLabels = prevChecked.filter(
      (e: { id: number; itemId: number; itemName: string }) =>
        !labelChecked.includes(e.itemName)
    );
    const currentLabels = labelData.filter((e) =>
      labelChecked.includes(e.value)
    );
    setUpdateLabels(
      removedLabels.map(
        (e: { id: number; itemId: number; itemName: string }) => ({
          labelId: e.id,
          name: e.itemName,
          isDeleted: true,
        })
      )
    );
    const unchangedLabels: string[] = prevChecked
      .filter(
        (e: { id: number; itemId: number; itemName: string }) =>
          !removedLabels.includes(e)
      )
      .map((e: { id: number; itemId: number; itemName: string }) => e.itemName);
    const updatedLabels = currentLabels
      .filter((e) => !unchangedLabels.includes(e.value))
      .map((e) => ({
        labelId: e.key,
        name: e.value,
        isDeleted: false,
      }));
    const newlyAddedLabels = createdLabels.map((e) => ({
      labelId: 0,
      name: e,
      isDeleted: false,
    }));
    setNewLabels([...updatedLabels, ...newlyAddedLabels] as UpdateLabel[]);
    setIsLabelsUpdated(
      newLabels.length > 0 || updateLabels.length > 0 ? true : false
    );
  }, [labelChecked, createdLabels, result]);

  async function getCommentsList(id: number) {
    setIsCommentsLoading(true);
    await getComments(id, instance, accounts)
      .then(({ data }) => {
        var _data = data.map((e: any) => {
          return {
            ...e,
            mentions: e.mentions.map((mentions: any) => {
              return {
                ...mentions,
                id: mentions.id,
                display: mentions.name,
              };
            }),
          };
        });
        setComments(_data);
        setOriginalComments([...data]);
        setIsCommentsLoading(false);
      })
      .catch((error) => {
        console.log(error?.message);
        setIsCommentsLoading(false);
      });
  }

  function addItemOnNew(
    originalText: string,
    plainText: string,
    mentions: MentionItem[]
  ): CommentItem[] {
    if (plainText.length > 0) {
      const newCommentItem: CommentItem = {
        editPending: true,
        comment: plainText,
        originalText: originalText,
        author: details.displayName,
        createdOn: new Date().toISOString(),
        isModified: false,
        isDeleted: false,
        isNewComment: true,
        mentions: mentions.map((mention) => {
          return {
            id: mention.id,
            name: mention.display,
          };
        }),
      };

      const newComments = [...(comments ?? []), newCommentItem];
      setComments(newComments);

      return newComments;
    }

    return comments ?? [];
  }

  const findLabelByValue = (
    arr: DataProps[],
    targetValue: string
  ): string | null => {
    for (const item of arr) {
      if (item.key === targetValue) {
        return item.label;
      }
      if (item.children) {
        const result = findLabelByValue(item.children, targetValue);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };
  const formatLabels = (values: LabelGet[]) => {
    return values.map((label) => {
      return {
        labelId: label.itemId,
        name: label.itemName,
      };
    });
  };

  let form = useForm({
    initialValues: {
      title: result?.title,
      key: result?.id,
      id: result?.key,
      status: result?.statusId.toString(),
      statusLabel: result?.status,
      project: !result?.isAuthorized
        ? result?.container
        : findLabelByValue(projectData, projectSelected[0]),
      projectId: result?.containerId.toString(),
      type: result?.itemTypeId.toString(),
      typeLabel: result?.itemType,
      owner: result?.ownerId.toString(),
      ownerLabel: result?.owner,
      targetDate: new Date(result?.targetDate),
      labels: formatLabels(result?.labels ? result?.labels : []),
      impact:
        result?.itemImpactId !== null ? result?.itemImpactId.toString() : "",
      impactLabel:
        result?.itemImpact !== null ? result?.itemImpact.toString() : "",
      priority:
        result?.itemPriorityId !== null
          ? result?.itemPriorityId.toString()
          : "",
      prioritylabel:
        result?.itemPriority !== null ? result?.itemPriority.toString() : "",
      probability:
        result?.itemProbabilityId !== null
          ? result?.itemProbabilityId.toString()
          : "",
      probabilityLabel:
        result?.itemProbability !== null
          ? result?.itemProbability.toString()
          : "",
      createdDate: new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }).format(new Date(result?.createdDate.toString())),
      description: result?.description || "",
      mitigationPlan: result?.mitigationPlan || "",
    },
    validateInputOnBlur: true,
    validate: {
      title: (value: string) => {
        if (!value.trim().length) {
          return t("quick_item_view.errors.title_required");
        }
        if (value.length > 250) {
          return t("quick_item_view.errors.title_character_limit");
        }
        return null;
      },
      status: (value) =>
        value.trim().length > 0 ? null : t("quick_item_view.errors.status"),
      type: (value) =>
        value?.trim().length > 0 ? null : t("quick_item_view.errors.type"),
      owner: (value) =>
        value?.trim().length > 0 ? null : t("quick_item_view.errors.owner"),
      targetDate: (value) =>
        value.toString()?.trim().length > 0
          ? null
          : t("quick_item_view.errors.targetDate"),
    },
  });

  function resetForm(result: any) {
    form.reset();
    form.setFieldValue("title", result?.title);
    form.setFieldValue("key", result?.id);
    form.setFieldValue("id", result?.key);
    form.setFieldValue("status", result?.statusId.toString());
    form.setFieldValue("statusLabel", result?.status);
    form.setFieldValue(
      "project",
      result?.isAuthorized
        ? findLabelByValue(projectData, projectSelected[0])
        : result?.container
    );
    form.setFieldValue("projectId", result?.containerId.toString());
    form.setFieldValue("type", result?.itemTypeId.toString());
    form.setFieldValue("typeLabel", result?.itemType);
    form.setFieldValue("owner", result?.ownerId.toString());
    form.setFieldValue("ownerLabel", result?.owner);
    form.setFieldValue("targetDate", new Date(result?.targetDate));
    form.setFieldValue(
      "labels",
      formatLabels(result?.labels ? result?.labels : [])
    );
    form.setFieldValue(
      "impact",
      result?.itemImpactId !== null ? result?.itemImpactId.toString() : ""
    );
    form.setFieldValue(
      "impactLabel",
      result?.itemImpact !== null ? result?.itemImpact : ""
    );
    form.setFieldValue(
      "priority",
      result?.itemPriorityId !== null ? result?.itemPriorityId.toString() : ""
    );
    form.setFieldValue(
      "prioritylabel",
      result?.itemPriority !== null ? result?.itemPriority : ""
    );
    form.setFieldValue(
      "probability",
      result?.itemProbabilityId !== null
        ? result?.itemProbabilityId.toString()
        : ""
    );
    form.setFieldValue(
      "probabilityLabel",
      result?.itemProbability !== null ? result?.itemProbability : ""
    );
    form.setFieldValue(
      "createdDate",
      new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }).format(new Date(result?.createdDate.toString()))
    );
    form.setFieldValue("description", result?.description);
    form.setFieldValue("mitigationPlan", result?.mitigationPlan);
  }

  const onUpdateItems = async (action: "save" | "save_and_close") => {
    if (!form.values?.owner) {
      form.setFieldError("owner", t("quick_item_view.errors.owner"));
      return;
    }

    if (!isUpdatePending) {
      setIsUpdatePending(true);
      const accessToken = await getAccessToken(instance, accounts[0]);
      var itemCommentsList: ItemComment[] = [];

      var commentsList: CommentItem[] = comments;

      if (commentBoxValue !== "" && commentBoxValue !== null) {
        commentsList = addItemOnNew(
          commentBoxValue,
          commentBoxPlainTextValue,
          commentBoxMentions
        );
      }

      itemCommentsList = commentsList
        .filter((comment) => {
          return comment?.editPending;
        })
        .map((comment) => {
          return {
            commentId: comment.commentId !== -1 ? comment.commentId : undefined,
            comment: comment.comment,
            isModified:
              comment.commentId && comment.commentId !== -1 ? true : undefined,
            isDeleted: comment.isDeleted,
            itemMentions:
              comment?.mentions?.map((mention) => {
                return {
                  userId: +mention.id,
                  id: mention.mentionId,
                  isDeleted: mention.isDeleted,
                };
              }) ?? [],
          };
        });

      const itemReq: ItemReq = {
        id: form.values?.key,
        title: form.values.title,
        itemTypeId: JSON.parse(form.values?.type),
        itemType: form.values?.typeLabel,
        containerId: JSON.parse(form.values.projectId),
        container: form.values?.project ? form.values?.project : "",
        ownerId: JSON.parse(form.values?.owner),
        owner: form.values?.ownerLabel,
        targetDate: form.values.targetDate,
        updateLabels: updateLabels,
        newLabels: newLabels,
        description: form.values.description,
        itemStatus: form.values?.statusLabel,
        itemStatusId: JSON.parse(form.values?.status),
        itemComments: itemCommentsList,
        ItemUpdateNewAttachements: getNewAttachmentList(),
        ItemUpdateDeleteAttachements: removedFileList,
      };

      if (form.values.mitigationPlan)
        itemReq.mitigationPlan = form.values.mitigationPlan;

      if (form.values?.impact === null || form.values?.impact === "") {
        itemReq.itemImpactId = null;
      } else {
        itemReq.itemImpactId = JSON.parse(form.values?.impact);
      }
      itemReq.itemImpact = form.values?.impactLabel;

      if (form.values?.priority === null || form.values?.priority === "") {
        itemReq.itemPriorityId = null;
      } else {
        itemReq.itemPriorityId = JSON.parse(form.values?.priority);
      }
      itemReq.itemPriority = form.values?.prioritylabel;

      if (
        form.values?.probability === null ||
        form.values?.probability === ""
      ) {
        itemReq.itemProbabilityId = null;
      } else {
        itemReq.itemProbabilityId = JSON.parse(form.values?.probability);
      }
      itemReq.itemProbability = form.values?.probabilityLabel;

      updateRaidItem(itemReq, accessToken, result.id)
        .then(async ({ data }) => {
          updateResultOnSave(data);
          notificationCount();
          fetchNotifications(
            JSON.parse(localStorage.selectedNotificationType || "2"),
            1,
            10
          );
          fetchHistory(result.id);
          setUploadedFiles([]);
          setIsLabelsUpdated(false);
          getCommentsList(result.id);
          setIsCommentsEdited(false);
          setIsUpdatePending(false);
          setRemovedFileList([]);
          setIsDirty(false);
          resetQkItemViewState();
          await getLabels();
          dashboardName &&
            refreshItemsDispatch({
              type: "setRaidListRefresh",
              payload: { dashboard: dashboardName, refresh: true },
            });
          ShowToastMsg("Success: Item Updated", ToastSensitivity.SUCCESS);

          if (action === "save_and_close") {
            setOpened?.(false);
            redirect?.();
          }
        })
        .catch();
    }
  };

  function updateResultOnSave(data: any) {
    result.container = data.container;
    result.containerId = data.containerId;
    result.createdDate = data.createdDate;
    result.description = data.description;
    result.id = data.id;
    result.itemImpact = data.itemImpact;
    result.itemImpactId = data.itemImpactId;
    result.itemPriority = data.itemPriority;
    result.itemPriorityId = data.itemPriorityId;
    result.itemProbability = data.itemProbability;
    result.itemProbabilityId = data.itemProbabilityId;
    result.itemType = data.itemType;
    result.itemTypeId = data.itemTypeId;
    result.key = data.key;
    result.labels = data.labels === null ? [] : data.labels;
    result.mitigationPlan = data.mitigationPlan;
    result.owner = data.owner;
    result.ownerId = data.ownerId;
    result.status = data.itemStatus;
    result.statusId = data.itemStatusId;
    result.targetDate = data.targetDate;
    result.title = data.title;
  }

  async function getLabels() {
    setIsLabelsLoading(true);
    const accessToken = await getAccessToken(instance, accounts[0]);
    getLabelOptions("", accessToken)
      .then(({ data }) => {
        setLabelData(
          data.map((lbl) => {
            return {
              key: lbl.key,
              value: lbl.value,
              isSelected: false,
            };
          })
        );
        setIsLabelsLoading(false);
      })
      .catch();
  }

  useEffect(() => {
    form.setValues({
      ...form.values,
      project:
        projectSelected.length > 0
          ? findLabelByValue(projectData, projectSelected[0])
          : !result?.isAuthorized
          ? result?.container
          : "",
      projectId: projectSelected[0],
    });
  }, [projectSelected, projectData]);

  useEffect(() => {
    const checkValues =
      form.values.title != result?.title ||
      form.values.projectId != result?.containerId.toString() ||
      form.values.type != result?.itemTypeId.toString() ||
      form.values.status != result?.statusId.toString() ||
      form.values.owner != result?.ownerId.toString() ||
      form.values.targetDate.toString() !=
        new Date(result?.targetDate).toString() ||
      (form.values.impact !== "" &&
        form.values.impact != result?.itemImpactId?.toString()) ||
      (form.values.priority !== "" &&
        form.values.priority != result?.itemPriorityId?.toString()) ||
      (form.values.probability !== "" &&
        form.values.probability != result?.itemProbabilityId?.toString()) ||
      uploadedFiles.length > 0 ||
      removedFileList.length > 0 ||
      isCommentsEdited ||
      newLabels.length > 0 ||
      updateLabels.length > 0 ||
      commentBoxValue != "" ||
      isCommentBoxUpdated;

    const checkDescription =
      form.values.description == ""
        ? result?.description == null
          ? false
          : form.values.description !== result?.description
        : form.values.description !== result?.description;
    const checkMitigationPlan =
      form.values.mitigationPlan == ""
        ? result?.mitigationPlan === null
          ? false
          : form.values.mitigationPlan !== result?.mitigationPlan
        : form.values.mitigationPlan !== result?.mitigationPlan;

    if (checkValues || checkDescription || checkMitigationPlan) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [
    form,
    uploadedFiles,
    removedFileList,
    isCommentsEdited,
    newLabels,
    updateLabels,
    result,
  ]);

  useEffect(() => {
    setIsCommentsEdited(
      comments.filter((comment) => comment.editPending).length > 0
    );
  }, [comments]);

  const getNewAttachmentList = () => {
    if (uploadedFiles.length > 0) {
      const fileValues: File[] = uploadedFiles.map((item) => item.file);
      return fileValues;
    }
    return [];
  };

  useEffect(() => {
    setTitle(form.values.title);
  }, [form.values.title]);

  return (
    <>
      <Grid columns={12}>
        <Grid.Col span={12} className={classes.pb0}>
          <Typography
            type={TypographyTypes.default}
            className={classes.ticketType}
            text={`${result?.key} - ${result?.itemType}`}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            classNames={{ input: cx(classes.titleInput) }}
            radius={0}
            placeholder={"Title"}
            readOnly={!result?.isAuthorized}
            autosize
            minRows={1}
            maxRows={3}
            {...{
              ...form.getInputProps("title"),
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                }
              },
            }}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex
            justify="flex-start"
            align="center"
            direction="row"
            wrap="wrap"
            gap={10}
          >
            {isLabelsLoading ? (
              <Flex direction="column" align="center" style={{ padding: 10 }}>
                <Loader variant="dots" />
              </Flex>
            ) : (
              <>
                <Typography
                  type={TypographyTypes.default}
                  className={classes.labelsText}
                  text={"Tags"}
                />
                {result?.isAuthorized && (
                  <AddLabel
                    labelData={labelData}
                    checked={labelChecked}
                    setChecked={setLabelChecked}
                    setCreatedLabels={setCreatedLabels}
                  />
                )}

                {labelChecked.map((label) => (
                  <LabelItem
                    value={label}
                    checked={labelChecked}
                    setChecked={setLabelChecked}
                    isDisabled={!result?.isAuthorized} // sholud change to !result?.isAuthorized
                  />
                ))}
              </>
            )}
          </Flex>
        </Grid.Col>
      </Grid>
      <Flex
        direction={"row"}
        gap="md"
        justify="flex-start"
        align="flex-start"
        style={{ marginTop: 20 }}
      >
        <div style={{ width: "100%" }}>
          <TabGroup
            data={tabData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
          {activeTab == TabTypeEnum.DETAILS ? (
            <>
              <Details
                code={result?.key}
                itemTypeId={result?.itemTypeId}
                description={result?.description}
                mitigationPlan={result?.mitigationPlan}
                form={form}
                formDisabled={!result?.isAuthorized} // sholud change to !result?.isAuthorized
                setIsDirty={setIsDirty}
              />
              <CommentSection
                itemId={result?.id}
                commentList={comments}
                setCommentList={setComments}
                isCommentsLoading={isCommentsLoading}
                isDisabled={!result?.isAuthorized}
              />
            </>
          ) : activeTab == TabTypeEnum.ATTACHMENTS ? (
            <>
              <ItemAttachments
                form={form}
                formType={"edit"}
                itemId={result?.id}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                removedFileList={removedFileList}
                setRemovedFileList={setRemovedFileList}
                isUpdatePending={isUpdatePending}
                isDisabled={!result?.isAuthorized} // sholud change to !result?.isAuthorized
              />
            </>
          ) : activeTab == TabTypeEnum.HISTORY ? (
            <>
              {historyStatus != "loading" ? (
                <HistoryTab historyData={history} itemTitle={result?.title} />
              ) : (
                <Flex
                  direction="column"
                  align="flex-start"
                  style={{ padding: 30 }}
                >
                  <Loader variant="dots" />
                </Flex>
              )}
            </>
          ) : activeTab == TabTypeEnum.LINKS ? (
            <></>
          ) : (
            <></>
          )}
        </div>
        <div
          style={
            !isDrawer
              ? { flex: "0 0 400px" }
              : { flex: "1 1 346px", minWidth: 277 }
          }
        >
          <div
            style={{
              border: "1px solid #E8E8E8",
              height: "100%",
              padding: 15,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
            }}
          >
            <QuickItemForm
              searchString={searchString}
              setSearchString={setSearchString}
              form={form}
              selected={projectSelected}
              setSelected={setProjectSelected}
              values={projectData}
              setValues={setProjectData}
              isFormDisabled={!result?.isAuthorized} // sholud change to !result?.isAuthorized
            />
            {result?.isAuthorized && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 35,
                }}
              >
                <Button
                  onClick={() => {
                    resetForm(result);
                    setOpened?.(false);
                    redirect?.();
                  }}
                  style={{ marginRight: 10 }}
                  color="gray"
                >
                  Cancel
                </Button>

                <Group noWrap spacing={0}>
                  <Button
                    loading={isUpdatePending}
                    loaderPosition="center"
                    disabled={
                      !(
                        isDirty ||
                        isCommentsEdited ||
                        isUpdatePending ||
                        isLabelsUpdated ||
                        !isCommentBoxEmpty
                      ) || form.values.title.length === 0
                    }
                    onClick={() => onUpdateItems("save_and_close")}
                    className={classes.saveButton}
                  >
                    Save & Close
                  </Button>
                  <span
                    className={
                      !(
                        isDirty ||
                        isCommentsEdited ||
                        isUpdatePending ||
                        isLabelsUpdated ||
                        !isCommentBoxEmpty
                      ) || form.values.title.length === 0
                        ? classes.buttonMenuDisabled
                        : classes.buttonMenu
                    }
                  >
                    <Menu
                      transitionProps={{ transition: "pop" }}
                      position="bottom-end"
                      withinPortal
                      disabled={
                        !(
                          isDirty ||
                          isCommentsEdited ||
                          isUpdatePending ||
                          isLabelsUpdated ||
                          !isCommentBoxEmpty
                        ) || form.values.title.length === 0
                      }
                    >
                      <Menu.Target>
                        <ActionIcon variant="filled" size={36}>
                          <IconChevronDown size="1rem" stroke={1.5} />
                        </ActionIcon>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Menu.Item onClick={() => onUpdateItems("save")}>
                          Save Item
                        </Menu.Item>
                      </Menu.Dropdown>
                    </Menu>
                  </span>
                </Group>
              </div>
            )}
          </div>
        </div>
      </Flex>
    </>
  );
};

export default QuickItemView;
