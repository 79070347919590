import { createStyles } from "@mantine/core";
import { IconButtonStyleProps } from "./type";

const UseIconButtonStyles = createStyles((theme, { color }: IconButtonStyleProps) => ({
  iconButton: {
    backgroundColor: color ? color : theme.colors.gray[3],
  },
 
 
}));

export default UseIconButtonStyles;
