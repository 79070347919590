import envVariables from "../../utils/config.json";
import axios from "../../axiosInstance";

export const getProjectFilterList = async (accessToken: any) => {
  const bearer = `Bearer ${accessToken}`;
  return axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/filters/containers`,
    headers: { authorization: bearer },
  });
};
