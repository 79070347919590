import { useState } from "react";
import { Button, Group } from "@mantine/core";
import FilterPopover from "../../common/FilterPopover";
import { IconClockHour5 } from "@tabler/icons-react";
import { PeriodFilterProps } from "./type";
import usePeriodStyles from "./styles";
import DatePickerModal from "../../DatePickerModal";

const PeriodFilter = ({
  title,
  StateHasValue,
  periodList,
  period,
  setPeriod,
  handlePeriodSelection,
  disabled
}: PeriodFilterProps) => {
  const { classes } = usePeriodStyles();
  const [opened, setOpened] = useState(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <div>
      <FilterPopover
        children={
          <Group position="center">
            {periodList.map((item) => {
              return item.value !== "Custom" ? (
                <Button
                  id={`period-${item.value}`}
                  key={`period-${item.value}`}
                  fullWidth
                  compact
                  className={
                    period === item.key ? classes.selected : classes.period
                  }
                  onClick={() => {
                    setPeriod(item.key as string);
                    setOpened(false);
                    handlePeriodSelection(item.key as string, "");
                    setModalOpen(false);
                  }}
                >
                  {item.value}
                </Button>
              ) : (
                <>
                  <Button
                      id={`period-${item.value}`}
                      key={`period-${item.value}`}
                    fullWidth
                    compact
                    className={
                      period === item.key ? classes.selected : classes.period
                    }
                    onClick={() => {
                      setPeriod(item.key as string);
                      setOpened(true);
                      // handlePeriodSelection(item.key as string);
                    }}
                  >
                    {item.value}
                  </Button>
                </>
              );
            })}
          </Group>
        }
        title={title}
        isActive={period !== null && period !== ""}
        icon={<IconClockHour5 size={18} />}
        opened={modalOpen}
        setOpened={setModalOpen}
        closeOnClickOutside={true}
        disabled={disabled}
      ></FilterPopover>
      <DatePickerModal
        setOpened={setModalOpen}
        setPeriod={handlePeriodSelection}
        isModalOpen={opened}
        setIsModalOpen={setOpened}
      />
    </div>
  );
};

export default PeriodFilter;
