import { Button } from "@mantine/core";
import { CommentSectionProp, IUploadedFile } from "./type";
import { useRef, useState } from "react";
import { ATTACHMENTS } from "./tempData";
import useCommentFeatureStyles from "./styles";
import { IconPaperclip } from "@tabler/icons-react";
import { AttchmentDropzone } from "../../components/Attachments/AttachmentDropzone";
import { Attachment } from "../../components/Attachments/Attachment";
import { UploadingAttachment } from "../../components/Attachments/UploadingAttachment";
import { AddItemAttachmentsProp } from "../../components/AddItem/ItemDetails/type";
import ShowToastMsg, { ToastSensitivity } from "../../utils/functions/showToastMsg";
import { ACCEPTED_MIME_TYPES } from "../../components/Attachments/AttachmentDropzone/type";
import { useTranslation } from "react-i18next";

const AttachmentSection = ({ form, uploadedFiles, setUploadedFiles }: AddItemAttachmentsProp) => {
  const { classes } = useCommentFeatureStyles();
  const [isFileUploading, setIsFileUploading] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  const handleAttachmentClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    validateFiles(files);
  };

  const handleDropFiles = (files: any) => {
    validateFiles(files);
  };

  const validateFiles = (files: any) => {
    if (files && files.length > 0) {
      const newFiles: any = Array.from(files).reduce((acc: any, file: any) => {
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes
        const acceptedTypes = ACCEPTED_MIME_TYPES;
        if (file.size > maxSize) {
          ShowToastMsg(`File ${file.name} exceeds the size limit of 2MB.`, ToastSensitivity.DANGER);
          return acc;
        }

        if (!acceptedTypes.includes(file.type)) {
          ShowToastMsg(`File ${file.name} has an unsupported file type.`, ToastSensitivity.DANGER);
          return acc;
        }

        const newFile = {
          file,
          title: file.name,
          type: file.type.split("/")[1],
        };
        return [...acc, newFile];
      }, [] as IUploadedFile[]);
      setUploadedFiles((prevFiles: any) => [...prevFiles, ...newFiles]);
    }
  };

  const handleNewAttachmentRemove = (index: number) => {
    let modifiedFilesList = uploadedFiles;
    modifiedFilesList.splice(index, 1);
    setUploadedFiles(() => [...modifiedFilesList]);
  }

  const completeUpload = (index: number) => {
    let files = uploadedFiles;
    files[index].uploadCompleted = true;
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Button
        variant="outline"
        compact
        leftIcon={<IconPaperclip size={"1rem"} />}
        onClick={handleAttachmentClick}
      >
        {t("add_attachment")}
      </Button>

      <input
        type="file"
        id="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={handleFileSelected}
        multiple
      />

      <AttchmentDropzone handleOnclick={handleAttachmentClick} handleDropFiles={handleDropFiles} />

      {/* Attachment Section */}

      {
      uploadedFiles.length > 0
        ? uploadedFiles.map((file, index) => (
          <UploadingAttachment
            title={file.title}
            type={file.type}
            handleRemove={handleNewAttachmentRemove}
            index= {index}
            completeUpload={completeUpload}
            uploadCompleted={file.uploadCompleted || false}
          />
        ))
      : null
    }
      
    </div>
  );
};

export default AttachmentSection;
