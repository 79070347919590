import { createStyles } from "@mantine/core";

const useOverlayStyles = createStyles((theme) => ({
    overlayBackdrop: {
        width: "100%",
        height: "100%",
        background: "white",
        position: "absolute",
        opacity: 0.5,
        zIndex: 900,
        overflow: "hidden",
    },
}));

export default useOverlayStyles;