import {
    Grid,
    Text,
    Tooltip,
} from "@mantine/core";
import { memo } from "react";
import { Link } from "react-router-dom";
import useSideNavigationStyles from "../styles";
import { PrivateView, PublicView } from "../types";

interface ViewLinkProps {
    publicView: PublicView | PrivateView,
    location: string,
    collapsed: boolean,
    index: number,
    type: "public" | "private",
    ClickHandler: (e: any, path: string) => void
}

const ViewLink = memo(({ publicView, location, collapsed, index, ClickHandler, type }: ViewLinkProps) => {
    const { classes, cx } = useSideNavigationStyles();

    const WithToolTip = ({ showToolTip, name, children }: any) => {
        return (showToolTip)
            ? <Tooltip label={name} position="right-start" offset={20} withArrow arrowOffset={13} arrowSize={8}>{children}</Tooltip>
            : <>{children}</>
    }

    return (
        <WithToolTip showToolTip={collapsed} name={publicView.name}>
            <Link onClick={(e) => ClickHandler(e, `/${type}/${publicView.id}`)} to={`${type}/${publicView.id}`} style={{ textDecoration: "none" }}>
                <Grid columns={12} key={index} className={
                    `/${type}/${publicView.id}` === location
                        ? classes.activeItemWrapper
                        : classes.itemWrapper
                }>
                    {!collapsed && <Grid.Col xs={1}></Grid.Col>}
                    <Grid.Col
                        className={cx(
                            collapsed ? classes.flexCenter : classes.flexStart
                        )}
                        xs={collapsed ? 12 : 3}
                    >
                        <div
                            className={(type == "private") ? classes.privateViewTag : classes.publicViewTag}
                        ></div>
                    </Grid.Col>
                    {!collapsed && (
                        <>
                            <Grid.Col xs={7} style={{ paddingLeft: 0 }}>
                                <Text c="white" className={cx(classes.privateFiltersText)}>
                                    {publicView.name}
                                </Text>
                            </Grid.Col>
                            <Grid.Col xs={1}></Grid.Col>
                        </>
                    )}
                </Grid>
            </Link>
        </WithToolTip>
    );
});

export default ViewLink;