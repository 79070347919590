import moment from "moment";

//@ts-ignore
const timeZoneNameOffSetHrs = Intl.DateTimeFormat("en", {
  timeZoneName: "short",
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
})
  .formatToParts()
  .find((i) => i.type === "timeZoneName").value;
// first 3 letters of timezone name and offset in hours
const timeZoneName = timeZoneNameOffSetHrs.slice(0, 3);
const timeZoneOffsetHrs = timeZoneNameOffSetHrs.slice(3);

// timeZoneOffsetHrs example: +5:30 => 5.5 or -5:30 => -5.5
// split by : and convert to number
const timeZoneOffsetHrsInSec = timeZoneOffsetHrs
  .split(":")
  .reduce((acc, curr, index) => {
    if (index === 0) {
      return acc + parseFloat(curr) * 60 * 60;
    } else {
      return acc + parseFloat(curr) * 60;
    }
  }, 0);

export function utcOffsetHandler(serverDateTime: string) {
  // convert server date to new Date
  const serverDate = new Date(serverDateTime);

  // convert to hours to newDate
  const serverDateTimeWithOffset = new Date(
    serverDate.setSeconds(serverDate.getSeconds() + timeZoneOffsetHrsInSec)
  );

  return {
    timeZoneName,
    timeZoneOffsetHrs,
    serverDateTimeWithOffset,
  };
}

export const convertDateTime = (date: string, format: string) => {
  const utcDate = moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
  const stillUtc = moment.utc(utcDate).toDate();
  const local = moment(stillUtc).local().format(format);
  return local;
};
