import React from "react";
import { TypographyProps } from "./type";
import { Text, TextProps } from "@mantine/core";
import useStyles from "./style";
import { forwardRef } from "react";
import { TypographyTypes } from "../../../utils/enums";

const Typography = forwardRef<HTMLDivElement, TypographyProps & TextProps>(
  (props, ref) => {
    const { type, text, className, ...rest } = props;
    const { classes, cx } = useStyles();

    const getClassName = () => {
      switch (type) {
        case TypographyTypes.mainHeading:
          return classes.mainHeading;
        case TypographyTypes.defaultUnderlined:
          return cx(classes.default, classes.underlined);
        case TypographyTypes.defaultBold:
          return cx(classes.default, classes.bold);
        case TypographyTypes.title:
          return classes.title;
        case TypographyTypes.tools:
          return classes.tools;
        case TypographyTypes.label:
          return classes.label;
        default:
          return classes.default;
      }
    };

    return (
      <div
        style={{ border: "none", padding: "0px", margin: "0px" }}
        ref={ref}
        {...props}
      >
        <Text
          data-testid="typography-component"
          className={cx(getClassName(), className)}
          {...rest}
        >
          {text}
        </Text>
      </div>
    );
  }
);

export default Typography;
