import { memo } from "react"
import { ReadOnlyFilterPanelProps } from "./types"
import FilterTag from "../FilterTag";
import { Tooltip } from "@mantine/core";
import { IconClock, IconFolder, IconStar, IconTag, IconUser } from "@tabler/icons-react";

const ReadOnlyFilterPanel = memo(({ readonlyFilters: readOnlyFilter }: ReadOnlyFilterPanelProps) => {
    return <>
        {readOnlyFilter?.projectFilter?.map((project) => <FilterTag
            filterType="Project"
            hideClose={true}
            icon={
                <Tooltip label="Project" position="bottom" withArrow>
                    <IconFolder size={15} />
                </Tooltip>
            }
            name={project}
            onClose={() => {}}
        />)}
        {readOnlyFilter?.periodFilter?.map((td) =>
            <FilterTag
                filterType="Target Date"
                hideClose={true}
                icon={
                    <Tooltip label="Period" position="bottom" withArrow>
                        <IconClock size={15} />
                    </Tooltip>
                }
                name={td}
                onClose={() => {}}
            />)}
        {readOnlyFilter?.ownerFilter?.map((owner) =>
            <FilterTag
                filterType="Assigned To"
                hideClose={true}
                icon={
                    <Tooltip label="Owner" position="bottom" withArrow>
                        <IconUser size={15} />
                    </Tooltip>
                }
                name={owner}
                onClose={() => {}}
            />)}
        {readOnlyFilter?.statusFilter?.map((status) =>
            <FilterTag
                hideClose={true}
                filterType="Status"
                icon={
                    <Tooltip label="Status" position="bottom" withArrow>
                        <IconStar size={15} />
                    </Tooltip>
                }
                name={status}
                onClose={() => {}}
            />)}
        {readOnlyFilter?.tagFilter?.map((tag) =>
            <FilterTag
                hideClose={true}
                filterType="Tags"
                icon={
                    <Tooltip label="Tag" position="bottom" withArrow>
                        <IconTag size={15} />
                    </Tooltip>
                }
                name={tag}
                onClose={() => {}}
            />)}
    </>
});

export default ReadOnlyFilterPanel