import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";

import { authRequest } from "../../services/authService";

interface LoginProps {
  children?: React.ReactNode;
}

// if it's firefox use loginPopup else use loginRedirect
const loginHandler = (instance: IPublicClientApplication) => {
  if (navigator.userAgent.indexOf("Firefox") > -1) {
    instance.loginPopup(authRequest);
  } else {
    instance.loginRedirect(authRequest);
  }
};

const Login = ({ children }: LoginProps) => {
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if ((inProgress === InteractionStatus.None)) {
      loginHandler(instance)
      return;
    }

    loginHandler(instance)

  }, [inProgress, accounts]);

  return <>{children}</>;
};

export default Login;
