import SideNavigation from "./SideNavigation";
import {
  Dashboards,
  PrivateView,
  PublicView,
  SideNavigationProps,
} from "./types";

export default SideNavigation;
export type { Dashboards, PrivateView, PublicView, SideNavigationProps };
