import axios, { AxiosRequestConfig } from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import envVariables from "../utils/config.json";
import { getToken } from '../services/authService/tokenFetcher';
import { commonRequest } from '../services/authService';

const axiosMockInstance = axios.create();
const axiosLiveInstance = axios.create();

export const axiosMockAdapterInstance = new AxiosMockAdapter(axiosMockInstance, { delayResponse: 0 });
export default process.env.REACT_APP_IS_AXIOS_MOCK || envVariables.REACT_APP_IS_AXIOS_MOCK ? axiosMockInstance : axiosLiveInstance;
