import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  btnLabel: {
    width: "100%",
    height: 32,
    borderRadius: 0,
    border: "1px solid #C2C2C2",
    color: "rgb(0 0 0 / 76%)",
    "& .mantine-Button-inner": {
      justifyContent: "space-between",
      "& .mantine-Button-label": {
        width: "100%",
        fontSize: 14,
        fontWeight: "bold",
        fontStyle: "normal",
      },
      "mantine-Button-label": {
        minWidth: 100,
      },
    },
    '&:hover': {
      backgroundColor: "#b1b0b0",
    },
   
  },
  btn_Active: {
    backgroundColor: "white",
    color: "rgb(0 0 0 / 76%)",
    borderColor: "#D6D6D6",
    border: "2px solid",
  },
  popOverDropDown: {
    padding: 0,
    ".mantine-1ozs37u .react-checkbox-tree li": {
      height: "auto",
      paddingTop:"0.4rem !important",
    },
    "label":{
      display:"flex"
    }
  },
}));

export default useStyles;