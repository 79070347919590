import { createContext, useContext, useReducer } from "react";
import { getAccessToken } from "../services/authService";
import { getProjectFilterList } from "../services/filterService/projectFilter";
import { useMsal } from "@azure/msal-react";
import { ProjectListProps } from "../components/Filters/ProjectFilter";

interface ProjectState {
  Projects: any;
  status: "idle" | "loading" | "failed";
}

interface APIProjectData {
  key: number;
  label: string;
  isSelect?: boolean;
  children?: APIProjectData[];
}

interface ProjectListInputProps {
  data: APIProjectData[];
}

type Action =
  | { type: "FETCH_DATA_PENDING" }
  | { type: "FETCH_DATA_FULFILLED"; payload: any }
  | { type: "FETCH_DATA_REJECTED" };

interface ContextValue extends ProjectState {
  fetchProjectList: () => Promise<void>;
}

const ProjectContext = createContext<ContextValue | undefined>(undefined);

type ProjectProviderProps = { children: React.ReactNode };

const initialState: ProjectState = {
  Projects: [],
  status: "idle",
};

const reducer = (state: ProjectState, action: Action): ProjectState => {
  switch (action.type) {
    case "FETCH_DATA_PENDING":
      return { ...state, status: "loading" };
    case "FETCH_DATA_FULFILLED":
      return { ...state, status: "idle", Projects: action.payload };
    case "FETCH_DATA_REJECTED":
      return { ...state, status: "failed" };
    default:
      return state;
  }
};

function ProjectProvider({ children }: ProjectProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { instance, accounts } = useMsal();

  const fetchProjectList = async () => {
    dispatch({ type: "FETCH_DATA_PENDING" });
    try {
      const accessToken = await getAccessToken(instance, accounts[0]);
      const response = await getProjectFilterList(accessToken);
      const data = response?.data;
      dispatch({ type: "FETCH_DATA_FULFILLED", payload: data });
    } catch (error) {
      dispatch({ type: "FETCH_DATA_REJECTED" });
    }
  };

  return (
    <ProjectContext.Provider value={{ ...state, fetchProjectList }}>
      {children}
    </ProjectContext.Provider>
  );
}

const useProjectContext = (): ContextValue => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error("useDataContext must be used within a DataProvider");
  }
  return context;
};

export { useProjectContext, ProjectProvider };
