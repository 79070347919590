import { createStyles } from "@mantine/core";

const useModalStyles = createStyles((theme) => ({
  subTitle: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.colors.gray[6]
  },
  title: {
    color:theme.colors.dark[9],
    fontSize: 19,
    fontWeight: 700,
  }
}))

export default useModalStyles;
