import { Modal as MModal, useMantineTheme } from "@mantine/core";
import { Text } from "@mantine/core";
import { IModalProps } from "./type";
import useModalStyles from "./styles";

const Modal = (props: IModalProps) => {
  const { classes } = useModalStyles();
  const theme = useMantineTheme();

  const { subTitle, children, title, ...rest } = props;

  return (
    <MModal
      {...rest}
      title={
        <Text className={classes.title} data-testid="modal-title">
          {title}
        </Text>
      }
    >
      {subTitle && (
        <Text
          size="xs"
          color={theme.colors.gray[3]}
          className={classes.subTitle}
          data-testid="modal-subtitle"
        >
          {subTitle}
        </Text>
      )}
      {children}
    </MModal>
  );
};

export default Modal;
