import React, { useState, useEffect } from "react";
import { AppShell } from "@mantine/core";
import { useTranslation } from "react-i18next";
import SideNavigation from "../features/SideNavigation/SideNavigation";
import { useBranding } from "../hooks/functions/branding";

import Navigations from "../navigations";
import { getNotificationCount } from "../services/notificationService/notificationService";
import { getAccessToken } from "../services/authService";
import { useMsal } from "@azure/msal-react";
import Forbidden from "../pages/Forbidden";


const AppLayout = () => {
  const { t } = useTranslation();
  const branding = useBranding();
  const [isLoading, setIsLoading] = useState(true);
  const [isForbidden, setIsForbidden] = useState(false);

  const { instance, accounts } = useMsal();

  const fetchData = async () => {
    const accessToken = await getAccessToken(instance, accounts[0]);
    return await getNotificationCount(accessToken).catch((error) => {
      if (error.response.status === 403) {
        setIsForbidden(true);
      }
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (isLoading) {
    return (
      <div></div>
    );
  }

  if (isForbidden) return <Forbidden />;

  return (
    <>
      <AppShell
        padding="md"
        layout="alt"
        // header={<HeaderFeature />}
        navbar={<SideNavigation />}
      >
        <Navigations />
      </AppShell>
    </>
  );
};

export default AppLayout;
