import { createStyles, rem } from "@mantine/core";

const useTabStyles = createStyles((theme) => ({
  tab: {
    ...theme.fn.focusStyles(),
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[9],
    border: "1px solid #D9D9D9",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    borderRadius: 0,

    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },

    "&:not(:first-of-type)": {
      borderLeft: 0,
    },

    "&:first-of-type": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },

    "&:last-of-type": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },

    "&[data-active]": {
      backgroundColor: "#62BAFD",
      borderColor: "#62BAFD",
      color: theme.white,
    },
  },

  tabIcon: {
    marginRight: theme.spacing.xs,
    display: "flex",
    alignItems: "center",
  },

  tabsList: {
    display: "flex",
  },
}));

export default useTabStyles;
