import { ActionIcon, Badge, Group, rem } from "@mantine/core";
import { LabelItemProps } from "./type";
import useAddLabelStyles from "./styles";
import { IconX } from "@tabler/icons-react";
import { useState } from "react";

const LabelItem = ({ value, checked, setChecked, isDisabled = false }: LabelItemProps) => {
    const { classes } = useAddLabelStyles()
    const [hovered, setHovered] = useState(false)

    const handleClose = () => {
        const newChecked = checked.filter((label) => label != value)
        setChecked(newChecked as [string])
    }

    const removeButton = (
        <ActionIcon size="xs" className={classes.closeBtn} radius="xs" variant="transparent" onClick={() => handleClose()}>
            <IconX size={rem(10)} />
        </ActionIcon>
    );

    return <Badge radius="xs"
        onMouseEnter={() => !isDisabled && setHovered(true)}
        onMouseLeave={() => !isDisabled && setHovered(false)}
        className={classes.labelItem}
        rightSection={hovered ? removeButton : <></>}>
        {value}
    </Badge>
}

export default LabelItem