import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
    avatarGroup: {
        margin: "auto",
        "& .mantine-Avatar-root": {
            borderColor: "#bad0e3"
        },
    },
}));

export default useStyles;
