import { createStyles } from "@mantine/core";

const useHistoryStyles = createStyles((theme) => ({
  name: {
    fontSize: 14,
    fontWeight: 600,
    color: "#000000D9",
  },
  description: {
    fontSize: 14,
    color: "#868E96",
  },
  changeDiv: {
    fontSize: 14,
    color: "#868E96",
    maxWidth: "60%",
  },
  dateTime: {
    fontSize: 14,
    fontWeight: 400,
    color: "#00000040",
  },
  iconArrow: {
    fontSize: 14,
    fontWeight: 400,
    color: "#00000040",
    marginTop: "auto",
    marginBottom: "auto",
  },
}));

export default useHistoryStyles;
