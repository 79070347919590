import { Button, Checkbox, Flex, Text } from "@mantine/core";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { FilterButtonPanelProp } from "./type";
import { useState } from "react";
import { IconAlertTriangle } from "@tabler/icons-react";
import Typography from "../Typography";
import { TypographyTypes } from "../../../utils/enums";

const FilterButtonPanel = ({
  onSubmit,
  onClear,
  checked,
  required,
  errorMessage,
  enableSelectAll,
  checkedSelectAll,
  setCheckedSelectAll,
  allKeys,
  setSelected,
}: FilterButtonPanelProp) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <Flex
      direction="column"
      justify="flex-start"
      className={classes.btnPannelWrapper}
      wrap="nowrap"
    >
      {isError && (
        <Flex
          direction={"row"}
          justify={"flex-start"}
          gap={10}
          className={classes.btnPanelError}
        >
          <IconAlertTriangle size={15} style={{ marginTop: 2 }} />
          {errorMessage}
        </Flex>
      )}

      <Flex
        direction="row"
        justify="flex-start"
        align="center"
        gap="sm"
        className={classes.btnPanel}
        wrap="nowrap"
      >
        {enableSelectAll ? (
          <Checkbox
            labelPosition="right"
            label={
              <Text
                fw={700}
                color="#868e96"
                style={{ whiteSpace: "nowrap", marginLeft: "-0.5rem" }}
              >
                {t("selectAll")}
              </Text>
            }
            size="xs"
            checked={checkedSelectAll}
            onChange={(event) => {
              if (event.currentTarget.checked) {
                setCheckedSelectAll && setCheckedSelectAll(true);
                setSelected && allKeys && setSelected(allKeys);
              } else {
                setCheckedSelectAll && setCheckedSelectAll(false);
                setSelected && setSelected([]);
              }
            }}
          />
        ) : (
          <></>
        )}

        <Flex
          direction={"row"}
          justify={"flex-end"}
          style={{ width: "100%" }}
          gap={10}
        >
          <Button
            variant="outline"
            color="gray"
            compact
            data-testid="clear-btn"
            onClick={onClear}
          >
            {t("clear")}
          </Button>
          <Button
            compact
            data-testid="accept-btn"
            onClick={() => {
              onSubmit();
              if (checked.length == 0 && required) {
                setIsError(true);
              } else {
                setIsError(false);
              }
            }}
          >
            {t("accept")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterButtonPanel;
