import React, { useState, useEffect } from "react";
import { CheckboxHierarchyProps, DataProps } from "./type";
import { ActionIcon, Checkbox, Collapse, Text } from "@mantine/core";
import useStyles from "./styles";
import {
  generateNewSelectedProject,
  getCheckedKeys,
  setAllCheckedFalse,
  setCheckedVlues,
  setVisibilityByLabel,
  setVisibilityToFalse,
  setVisibilityToTrue,
  updateCheckedStatusWithParent,
  setVisibilityAndParents,
  updateCheckedStatus,
  updateCheckedStatusRecursive,
} from "./functions";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { customSort } from "../../../utils/functions/project";

const CheckboxHierarchy = ({
  values,
  selected,
  setValues,
  setSelected,
  isSingle,
  searchText,
  closeDropdown,
}: CheckboxHierarchyProps) => {
  const { classes, cx } = useStyles();

  const [visibleData, setVisibleData] = useState(values);

  useEffect(() => {
    if (searchText != "") {
      const newVisible = [...setVisibilityToFalse(values)];
      const updatedVisible = [
        ...setVisibilityAndParents(newVisible, searchText),
      ];
      setVisibleData(updatedVisible);
    } else {
      const newVisible = [...setVisibilityToTrue(values)];
      setVisibleData(newVisible);
    }
  }, [searchText]);

  useEffect(() => {
    setCheckedVlues(selected, values, setValues);
  }, [selected]);

  // Custom hook for individual checkbox state
  const CheckboxState = (initialState: boolean) => {
    const [checked, setChecked] = React.useState(initialState);
    return { setChecked, checked };
  };

  const setCheckedStatus = (node: DataProps, checked: boolean) => {
    node.checked = checked;
    if (node.children) {
      node.children = updateCheckedStatusRecursive(node.children, checked);
    }
    return node;
  };

  const ChildCheckBox = ({ data }: { data: DataProps[] }) => {
    const renderItem = (item: DataProps) => {
      const { checked, setChecked } = CheckboxState(true);

      return (
        <div key={item.key}>
          {item.visible ? (
            <div className={classes.flexView}>
              {item?.children ? (
                <ActionIcon onClick={() => setChecked(!checked)}>
                  {checked ? (
                    <IconChevronUp size="1.125rem" />
                  ) : (
                    <IconChevronDown size="1.125rem" />
                  )}
                </ActionIcon>
              ) : (
                <div className={classes.moveLeft}></div>
              )}
              <Checkbox
                mt="xs"
                ml={13}
                label={item.label}
                checked={item.checked}
                onChange={(event) => {
                  if (isSingle) {
                    const SelectedData = updateCheckedStatusWithParent(
                      setAllCheckedFalse(values),
                      item.key,
                      !item.checked
                    );
                    setSelected(getCheckedKeys(SelectedData));
                    setValues(SelectedData);
                    closeDropdown && closeDropdown(false);
                  } else {
                    let SelectedData: DataProps[] = updateCheckedStatus(
                      values,
                      item.key,
                      !item.checked
                    );
                    setCheckedStatus(item, item.checked);
                    const selectedValues = customSort(
                      getCheckedKeys(SelectedData),
                      [...selected, item.key]
                    );
                    setSelected(selectedValues);
                    setValues(SelectedData);
                  }
                }}
              />
            </div>
          ) : null}
          {item.children ? (
            <Collapse in={checked}>
              <div className={classes.checkboxChildrenBody}>
                <ChildCheckBox data={item.children || []} />
              </div>
            </Collapse>
          ) : null}
        </div>
      );
    };

    return <>{data.map(renderItem)}</>;
  };

  const checkForEmpty = () => {
    var empty = true;
    visibleData.forEach((item) => {
      if (item.visible === true) {
        empty = false;
      }
    });
    return empty;
  };

  return (
    <div style={{ height: checkForEmpty() ? 30 : 200, overflowY: "scroll" }}>
      {checkForEmpty() ? (
        <Text className={classes.notFoundText}>Nothing Found</Text>
      ) : (
        <ChildCheckBox data={visibleData} />
      )}
    </div>
  );
};

export default CheckboxHierarchy;
