import { createContext, useContext, useReducer } from "react";
import { getHistory } from "../services/itemViewService/itemView";
import { useMsal } from "@azure/msal-react";

interface HistoryState {
    history: IHistory[];
    historyStatus: "idle" | "loading" | "failed";
}

interface IHistory {
    itemId: number,
    attribute: string | null,
    oldValue: string | null,
    newValue: string | null,
    event: string,
    eventDateTime: string,
    description: string | null,
    user: IUser,
}

interface IUser {
    id: number,
    firstName: string,
    lastName: string,
}

type Action =
    | { type: 'FETCH_DATA_PENDING' }
    | { type: 'FETCH_DATA_FULFILLED'; payload: IHistory[] }
    | { type: 'FETCH_DATA_REJECTED' }

interface ContextValue extends HistoryState {
    fetchHistory: (id: number) => Promise<void>
}

const HistoryContext = createContext<ContextValue | undefined>(undefined);

type HsitoryProviderProps = { children: React.ReactNode };

const initialState: HistoryState = {
    history: [],
    historyStatus: "idle"
}

const reducer = (state: HistoryState, action: Action): HistoryState => {
    switch (action.type) {
        case 'FETCH_DATA_PENDING':
            return { ...state, historyStatus: 'loading' };
        case 'FETCH_DATA_FULFILLED':
            return { ...state, historyStatus: 'idle', history: action.payload };
        case 'FETCH_DATA_REJECTED':
            return { ...state, historyStatus: 'failed' };
        default:
            return state;
    }
};

function HistoryProvider({ children }: HsitoryProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { instance, accounts } = useMsal();

    const fetchHistory = async (id: number) => {
        dispatch({ type: 'FETCH_DATA_PENDING' });
        try {
            const response = await getHistory(id, instance, accounts);
            const data = await response.data;
            dispatch({ type: 'FETCH_DATA_FULFILLED', payload: data });
        } catch (error) {
            dispatch({ type: 'FETCH_DATA_REJECTED' });
        }
    };

    return (
        <HistoryContext.Provider value={{ ...state, fetchHistory }}>
            {children}
        </HistoryContext.Provider>
    );
};

const useHistoryContext = (): ContextValue => {
    const context = useContext(HistoryContext);
    if (!context) {
        throw new Error("useHistoryContext must be used within a DataProvider");
    }
    return context;
};

export { useHistoryContext, HistoryProvider };