import { createStyles } from "@mantine/core";

const useCommentFeatureStyles = createStyles((theme) => ({
  heading: {
    fontSize: 16,
    fontWeight: 600,
    color: "#000000",
    fontFamily: "Roboto, sans-serif",
    marginBottom: 10,
    marginTop: 20
  },
}));

export default useCommentFeatureStyles
