import React from "react";
import { AddItemAttachmentsProp, AttachmentSectionProps } from "./type";
import {
  AddAttachmentSection,
  EditAttachmentSection,
} from "../../../features/AttachmentSection";

function ItemAttachments({ form, formType, itemId, uploadedFiles, setUploadedFiles, removedFileList, setRemovedFileList, isUpdatePending, isDisabled }: AttachmentSectionProps) {
  return (
    <>
      {(!isDisabled) && <AddAttachmentSection form={form} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />}

      {formType == "edit" ? <EditAttachmentSection form={form} itemId={itemId} removedFileList={removedFileList} setRemovedFileList={setRemovedFileList} isUpdatePending={isUpdatePending} isDisabled={isDisabled} /> : null}
    </>
  );
}

export default ItemAttachments;
