import { MouseEvent, useCallback, useState } from "react";
import { ItemViewDrawerProps } from "./type";
import { IconChevronDown, IconChevronUp, IconX } from "@tabler/icons-react";
import useStyles from "./styles";
import { useRaidListContext } from "../../../contexts/raidItems.context";
import QuickItemView from "../../../features/QuickItemView";
import AppLoadingOverlay from "../LoadingOverlay";
import { QuickItemViewContextProvider } from "../../../contexts/quickItemView.context";

const ItemViewDrawer = ({
  opened,
  isFromNotification,
  setClose,
  onClickItem,
  result,
  loading,
  setIsDirty,
  isDirty,
  setOpened,
  dashboardName
}: ItemViewDrawerProps) => {
  const { classes } = useStyles();
  const minDrawerWidth = 760;
  const maxDrawerWidth = 1300;
  const [drawerWidth, setDrawerWidth] = useState(760);
  const { state: raidItemState } = useRaidListContext();

  const handleMouseDown = (
    _e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
    document.body.classList.add(classes.dragging);
  };
  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
    document.body.classList.remove(classes.dragging);
  };
  const handleMouseMove = useCallback((e: { clientX: number }) => {
    const newWidth = document.body.clientWidth - e.clientX;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setDrawerWidth(newWidth);
    }
  }, []);

  function onClickSwitchItem(type: string, id: number) {
    const dashboardList = raidItemState.raidItemList.filter((e) => e.dashboard === dashboardName)[0] ?? raidItemState.raidItemList[0]
    for (
      let index = 0;
      index < dashboardList?.raidList.length;
      index++
    ) {
      if (dashboardList?.raidList[index] === id.toString()) {
        if (type === "prev") {
          onClickItem(dashboardList?.raidList[index - 1]);
          return;
        } else {
          onClickItem(dashboardList?.raidList[index + 1]);
          return;
        }
      }
    }
  }

  return (
    <div
      className={classes.drawer}
      style={{
        width: drawerWidth,
        right: opened ? 0 : -drawerWidth,
      }}
    >
      {(loading || !result) && <AppLoadingOverlay />}
      <div
        style={{
          position: "relative",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          onMouseDown={(e) => handleMouseDown(e)}
          className={classes.dragger}
        />
        <div className={classes.actionButtonContainer}>
          {!isFromNotification && (
            <>
              <button
                className={classes.actionButton}
                onClick={() => onClickSwitchItem("next", result.key)}
                disabled={
                  raidItemState.raidItemList[0]?.raidList.slice(-1)[0] ===
                    result?.key.toString()
                    ? true
                    : false
                }
              >
                <IconChevronDown data-testid="icon-chevron-down" />
              </button>
              <button
                className={classes.actionButton}
                onClick={() => onClickSwitchItem("prev", result.key)}
                disabled={
                  raidItemState.raidItemList[0]?.raidList[0] ===
                    result?.key.toString()
                    ? true
                    : false
                }
              >
                <IconChevronUp data-testid="icon-chevron-up" />
              </button>
            </>
          )}
          <button
            className={classes.actionButton}
            onClick={() => setClose()}
            style={{ marginRight: 10 }}
          >
            <IconX data-testid="icon-x" />
          </button>
        </div>
        <div
          style={{
            paddingLeft: 30,
            paddingTop: 30,
            paddingRight: 10,
            flexGrow: 1,
            display: "flex",
            paddingBottom: 10,
            flexDirection: "column",
          }}
        >
          {result && (
            <QuickItemViewContextProvider>
              <QuickItemView
                result={result}
                setIsDirty={setIsDirty}
                isDirty={isDirty}
                setOpened={setOpened}
                isDrawer={true}
                dashboardName={dashboardName}
              />
            </QuickItemViewContextProvider>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemViewDrawer;
