import { createStyles } from "@mantine/core";

const usePeriodStyles = createStyles((theme) => ({
    period: {
        backgroundColor: '#ffffff',
        color: '#000000',
        height: 40,
        marginBottom: -15,
        '&:not([data-disabled]):hover': {
            backgroundColor: '#ECF4FF'
        },
        "& .mantine-Button-inner": {
            justifyContent: "left",
        },
    },
    selected: {
        backgroundColor: '#d4dbe5',
        color: '#000000',
        height: 40,
        marginBottom: -15,
        '&:not([data-disabled]):hover': {
            backgroundColor: '#d4dbe5'
        },
        "& .mantine-Button-inner": {
            justifyContent: "left",
        },
    },
}));

export default usePeriodStyles;
