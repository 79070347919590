import { axiosMockAdapterInstance as mock } from "../axiosInstance";
import mockRaidItems from "./data/mock-raid-items.json";
import envVariables from "../utils/config.json";

const mockGetRaidItems = mock
  .onPost(`${process.env.REACT_APP_BASE_URL || envVariables.REACT_APP_BASE_URL}/search`)
  .reply(function () {
    return [200, mockRaidItems.raidItems];
  });

export { mockGetRaidItems };
