import { IconUser } from "@tabler/icons-react";
import FilterPopover from "../../common/FilterPopover";
import { Flex, Loader } from "@mantine/core";
// import FilterSearchBar from "../../common/FilterSearchBar";
import { useEffect, useState } from "react";
import { ProjectListProps } from "../ProjectFilter";
import FilterButtonPanel from "../../common/FilterButtonPanel";
import CheckboxList from "../../common/CheckboxList";
import { AssignByFilterProps, AssignByFilterCheckboxItem } from "./types";
import Search from "../../common/Search";
import { getHotkeyHandler } from "@mantine/hooks";
import { useDebouncedValue } from "@mantine/hooks";
import useStyles from "./style";

const AssignByFilter = ({
  assignByUsers,
  StateHasValue,
  getAssignByFilters,
  checked,
  setChecked,
  handleSubmit,
  handleClear,
  selectedProjectIds,
  disabled,
  isLoading,
  handleSelectAll,
  checkedSelectAll,
  setCheckedSelectAll,
  allKeys,
}: AssignByFilterProps) => {
  const [visibleOptions, setVisibleOptions] = useState<ProjectListProps[]>([]);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString] = useDebouncedValue(searchString, 500);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const { classes } = useStyles();
  const [checkedFromFilter, setCheckedFromFilter] = useState<string[]>([]);

  useEffect(() => {
    const dataList = assignByUsers;

    const sortedDataList = [
      ...dataList.filter(({ value }) => value === "Assigned To Me "),
      ...dataList.filter(({ value }) => !(value === "Assigned To Me ")),
    ];

    const nodeList: AssignByFilterCheckboxItem[] = sortedDataList.map(
      (i, index) => {
        return {
          label: i.value,
          value: i.key,
          isSelected: i.isSelected,
          className:
            sortedDataList.length > 1 &&
            sortedDataList[0].value == "Assigned To Me "
              ? index == 0
                ? classes.assignedToMeNode
                : index == 1
                ? classes.secondNode
                : {}
              : {},
        };
      }
    );

    setVisibleOptions(nodeList);
  }, [assignByUsers]);

  useEffect(() => {
    if (!modalOpen) setIsActive(checked.length > 0);
    if (checked.length != checkedFromFilter.length) {
      setCheckedFromFilter(checked);
    }
  }, [checked]);

  useEffect(() => {
    getAssignByFilters({
      containerIds: selectedProjectIds,
      search: debouncedSearchString,
    });
  }, [debouncedSearchString]);

  useEffect(() => {
    var selectedValues = checkedFromFilter
      .map((item) => String(item))
      .filter((value, index, self) => self.indexOf(value) === index);
    setChecked(selectedValues);
  }, [checkedFromFilter]);

  const onHandleSearchChange = (e: any) => {
    setSearchString(e.target.value);
  };

  const handleSearchSubmit = () => {
    getAssignByFilters({
      containerIds: selectedProjectIds,
      search: searchString,
    });
  };

  const handleSearchClear = () => {
    setSearchString("");
    getAssignByFilters({
      containerIds: selectedProjectIds,
      search: searchString,
    });
  };

  return (
    <div>
      <FilterPopover
        children={
          <Flex direction="column">
            <Search
              value={searchString}
              onChange={(e) => onHandleSearchChange(e)}
              onKeyDown={getHotkeyHandler([
                [
                  "Enter",
                  () => {
                    handleSearchSubmit();
                  },
                ],
              ])}
              onClear={handleSearchClear}
            />
            {isLoading ? (
              <Flex direction="column" align="center" style={{ padding: 10 }}>
                <Loader variant="dots" />
              </Flex>
            ) : (
              <>
                <CheckboxList
                  nodes={visibleOptions}
                  checked={checked}
                  onCheck={(checkedValues, node) => {
                    if (node.checked) {
                      setCheckedFromFilter([
                        ...checked,
                        ...checkedFromFilter,
                        node.value,
                      ]);
                    } else {
                      const updatedList = checked.filter(
                        (e) => e != node.value
                      );
                      setCheckedFromFilter([...updatedList]);
                    }
                  }}
                  isMultilevel={false}
                  expanded={[]}
                />
                <FilterButtonPanel
                  onSubmit={() => {
                    setIsActive(checked.length > 0);
                    handleSubmit();
                    if (checkedSelectAll) {
                      handleSelectAll();
                    }
                    handleSearchClear();
                    setModalOpen(false);
                  }}
                  onClear={() => {
                    setIsActive(false);
                    handleSearchClear();
                    setCheckedFromFilter([]);
                    handleClear();
                    setModalOpen(false);
                  }}
                  checked={checked as [string]}
                  enableSelectAll={true}
                  checkedSelectAll={checkedSelectAll}
                  setCheckedSelectAll={setCheckedSelectAll}
                  allKeys={allKeys}
                  setSelected={setChecked}
                />
              </>
            )}
          </Flex>
        }
        title="Assigned By"
        isActive={isActive}
        icon={<IconUser size={18} />}
        opened={modalOpen}
        setOpened={setModalOpen}
        closeOnClickOutside={true}
        disabled={disabled}
      />
    </div>
  );
};

export default AssignByFilter;
