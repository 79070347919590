import { createStyles } from "@mantine/core";

const useSaveFilterStyles = createStyles((theme) => ({
  saveFilterDiv: {
    "& .mantine-Modal-title": {
      fontWeight: 600,
      fontSize: 20,
    },
    "& .mantine-InputWrapper-label": {
      fontSize: 14,
      fontWeight: 400,
      paddingTop: 8,
    },
    "& .mantine-Popover-dropdown": {
      padding: 0,
      paddingBottom: 4,
    },
    "& .react-checkbox-tree": {
      padding: 0,
    },
  },
  iconDiv:{
    display: "flex",
    justifyContent: "center",
  }
}));

export default useSaveFilterStyles;
