import React, { useReducer, useEffect, useContext } from "react";

export interface IRaidList {
  dashboard: string;
  raidList: string[];
}

interface RaidListState {
  raidItemList: IRaidList[];
}

type RaidListAction = { type: "setRaidList"; payload: IRaidList };

type RaidListDispatch = (action: RaidListAction) => void;

const RaidListStateContext = React.createContext<
  { state: RaidListState; dispatch: RaidListDispatch } | undefined
>(undefined);

function updateRaidItemList(
  state: RaidListState,
  payload: IRaidList
): RaidListState {
  const updatedState = state;
  const dashboardRaidList = updatedState.raidItemList.filter(
    (dashboardItem) => dashboardItem.dashboard === payload.dashboard
  );

  if (dashboardRaidList?.length > 0) {
    updatedState.raidItemList = updatedState.raidItemList.map(
      (dashboardItem) => {
        if (dashboardItem.dashboard == payload.dashboard) {
          return {
            dashboard: payload.dashboard,
            raidList: payload.raidList,
          };
        } else {
          return dashboardItem;
        }
      }
    );
  } else {
    updatedState.raidItemList.push(payload);
  }

  return updatedState;
}

function raidListReducer(state: RaidListState, action: RaidListAction) {
  switch (action.type) {
    case "setRaidList": {
      return updateRaidItemList(state, action.payload);
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

const initialState: RaidListState = {
  raidItemList: [],
};

function RaidListProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(raidListReducer, initialState);
  const value = { state, dispatch };
  return (
    <RaidListStateContext.Provider value={value}>
      {children}
    </RaidListStateContext.Provider>
  );
}

const useRaidListContext = () => {
  const context = useContext(RaidListStateContext);
  if (!context) {
    throw new Error("useRaidListContext must be used within a DataProvider");
  }
  return context;
};

export { useRaidListContext, RaidListProvider };
