import { ActionIcon, ActionIconProps } from "@mantine/core";
import UseIconButtonStyles from "./styles";
import { IconButtonProps } from "./type";

const IconButton = (props: ActionIconProps& IconButtonProps) => {
 
  const {onClick,color,children,...rest} = props
   const { classes } = UseIconButtonStyles({ color });
 
  return   <ActionIcon className={classes.iconButton}  onClick={onClick} {...rest} >
      {children}
    </ActionIcon>;
};

export default IconButton;
