import { useEffect, useState } from "react";
import { Button, Grid, Indicator, Input, Text } from "@mantine/core";
import Modal from "../common/Modal";
import { DatePickerModalProps } from "./types";
import Divider from "../common/Divider";
import { useForm } from "@mantine/form";
import { IMaskInput } from "react-imask";
import useDatePickerStyle from "./styles";
import moment from "moment";
import DatePicker from "../common/DatePicker";
import { PeriodEnum } from "../Filters/PeriodFilter";
import { IconX } from "@tabler/icons-react";

const DatePickerModal = ({
  isModalOpen,
  setIsModalOpen,
  setPeriod,
  setOpened,
}: DatePickerModalProps) => {
  const { classes } = useDatePickerStyle();
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const [isStartDateValid, setIsStartDateValid] = useState(true);
  const [isEndDateValid, setIsEndDateValid] = useState(true);
  const [isValidRange, setIsValidRange] = useState(false);
  const form = useForm({
    initialValues: {
      startDate: "",
      endDate: "",
    },
    validate: {
      startDate: (value) => (isValidDate(value) ? null : "Invalid start date"),
      endDate: (value) => (isValidDate(value) ? null : "Invalid end date"),
    },
  });

  useEffect(() => {
    form.setFieldValue("startDate", moment(value[0]).format("MM/DD/YYYY"));
    form.setFieldValue("endDate", moment(value[1]).format("MM/DD/YYYY"));
    if (value[0] && value[1] && value[0] > value[1]) {
      setIsValidRange(false);
    } else {
      setIsValidRange(true);
    }
  }, [value]);

  const isValidDate = (dateString: string): boolean => {
    const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
    const isValidDate = regex.test(dateString);
    return isValidDate;
  };

  const setStartDate = (dateString: string) => {
    const [month, day, year] = dateString.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    form.setFieldValue("startDate", dateString);
    setIsStartDateValid(isValidDate(dateString));
    setValue([date, value[1]]);
  };

  const setEndDate = (dateString: string) => {
    const [month, day, year] = dateString.split("/").map(Number);
    const date = new Date(year, month - 1, day);
    form.setFieldValue("endDate", dateString);
    setIsEndDateValid(isValidDate(dateString));
    setValue([value[0], date]);
  };

  const handleSubmit = (values: { startDate: string; endDate: string }) => {
    if (!isValidRange) {
      form.setFieldError("startDate", " ");
      form.setFieldError("endDate", " ");
    } else {
      setIsModalOpen(false);
      setPeriod(
        PeriodEnum[PeriodEnum.Custom],
        moment(values.startDate, "MM/DD/YYYY").format("DD MMM YYYY") +
          " - " +
          moment(values.endDate, "MM/DD/YYYY").format("DD MMM YYYY")
      );
      setOpened(false);
    }
  };

  return (
    <Modal
      size="auto"
      opened={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -3 }}>
        <button
          className={classes.actionButton}
          onClick={() => setIsModalOpen(false)}
        >
          <IconX size={16} data-testid="icon-x" />
        </button>
      </div>
      <DatePicker value={value} onChange={setValue} />
      <Divider className={classes.divider} />
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid className={classes.gridRoot} columns={11}>
          <Grid.Col span={5} className={classes.p0}>
            <Input.Wrapper
              error={
                !isStartDateValid
                  ? "Invalid start date"
                  : !isValidRange
                  ? "Invalid date range"
                  : ""
              }
            >
              <Input<any>
                component={IMaskInput}
                mask="00/00/0000"
                placeholder="mm/dd/yyyy"
                {...form.getInputProps("startDate")}
                onComplete={(event: any) => setStartDate(event)}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={1} className={classes.p0}>
            <Text className={classes.text}>-</Text>
          </Grid.Col>
          <Grid.Col span={5} className={classes.p0}>
            <Input.Wrapper error={isEndDateValid ? "" : "Invalid end date"}>
              <Input<any>
                component={IMaskInput}
                mask="00/00/0000"
                placeholder="mm/dd/yyyy"
                {...form.getInputProps("endDate")}
                onComplete={(event: any) => setEndDate(event)}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={11} className={classes.p0}>
            <Button className={classes.button} type="submit">
              Apply
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
};

export default DatePickerModal;
